import { useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import { $competitorsHooks } from '@entities';
import {
  CompetitorsHandbookForm,
  competitorsHandbookSchema,
  CompetitorsHandbookSchema,
  CRMTable,
} from '@features';
import { CRMTableColumns, CRMTableData, useTableColumnSort } from '@features/table';
import { $api, bodyRequestType, components } from '@shared/api';

import { COMPETITORS_TABLE_COLUMNS_WITH_FILTER } from '../consts';
import { ColumnsCompetitorsHandbookTable, CustomHandbookTableDefaultProps } from '../model';
import { CustomerHandbookDrawerLayout } from './CustomHandbookDrawerLayout';
import { CustomHandbookTableLayout } from './CustomHandbookTableLayout';
import { HandbookFormFooter } from './HandbookFormFooter';

type CompetitorsHandbookTableProps = CustomHandbookTableDefaultProps;

export function CompetitorsHandbookTable({
  creationOpen,
  onCloseCreation,
}: CompetitorsHandbookTableProps) {
  const { add } = useToaster();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const getHandbookCompetitorsInfiniteQuery = $competitorsHooks.useGetAll(
    {
      query: debouncedSearchValue,
    },
    true
  );

  const flattedPages = getHandbookCompetitorsInfiniteQuery.data?.pages.flat();

  const data: CRMTableData<ColumnsCompetitorsHandbookTable> = useMemo(
    () =>
      flattedPages?.map(competitor => ({
        'Полное наименование': competitor?.full_name || '',
        ИНН: competitor?.inn || '',
        Комментарий: competitor?.comment || '',
        Наименование: competitor?.name || '',
      })) ?? [],
    [getHandbookCompetitorsInfiniteQuery.data]
  );

  const { columnsSort, changeColumnSort, updateColumnsSortValuesBySettings } =
    useTableColumnSort(data);

  const columnWithHover = (name: ColumnsCompetitorsHandbookTable[number]) => {
    const sortValue = columnsSort[name];
    if (typeof sortValue === 'undefined') {
      return name;
    }

    return (
      <CRMTable.HoverColumnWrapper
        sort={sortValue}
        iconPosition="end"
        content={name}
        columnName={name}
        onSortTypeChange={(newSort, colName) => changeColumnSort(newSort, colName)}
      />
    );
  };

  const columns: CRMTableColumns<ColumnsCompetitorsHandbookTable> = [
    {
      id: 'Наименование',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Наименование'),
    },
    {
      id: 'Полное наименование',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Полное наименование'),
    },
    {
      id: 'ИНН',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('ИНН'),
    },
    {
      id: 'Комментарий',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      className: 'max-w-[250px] truncate',
      name: () => columnWithHover('Комментарий'),
      template: item => <span title={item['Комментарий']}>{item['Комментарий']}</span>,
    },
  ];

  const [editableHandbook, setEditableHandbook] = useState<
    components['schemas']['handbook.Competitor'] | null
  >(null);

  const postHandbookCompetitorsMutation = $api.useMutation('post', '/handbook/competitors');

  const createCompetitorsHandbookForm = useForm<CompetitorsHandbookSchema>({
    resolver: yupResolver(competitorsHandbookSchema),
    mode: 'all',
    defaultValues: {
      comment: '',
      full_name: '',
      inn: '',
      name: '',
    },
  });

  const onValidCreateHandbook = async (values: CompetitorsHandbookSchema) => {
    try {
      await postHandbookCompetitorsMutation.mutateAsync({
        body: values as bodyRequestType<'post', '/handbook/competitors'>, // TODO: убрать as, когда пофиксят схему
      });
      createCompetitorsHandbookForm.reset();
      getHandbookCompetitorsInfiniteQuery.refetch();
      add({
        name: 'competitors-create-success',
        theme: 'success',
        title: `Справочник успешно создан`,
      });
      onCloseCreation();
    } catch {
      add({
        name: 'competitors-create-failed',
        theme: 'danger',
        title: `Не удалось создать справочник`,
      });
    }
  };

  const putHandbookCompetitorsIdMutation = $api.useMutation('put', '/handbook/competitors/{id}');

  const editCompetitorsHandbookForm = useForm<CompetitorsHandbookSchema>({
    resolver: yupResolver(competitorsHandbookSchema),
    values: {
      ...editableHandbook,
      name: editableHandbook?.name || '',
      inn: editableHandbook?.inn || '',
    },
    mode: 'all',
  });

  const isEditCompetitorsHandbookFormDirty = editCompetitorsHandbookForm.formState.isDirty;

  const handleEditHandbook = () => {
    editCompetitorsHandbookForm.handleSubmit(async values => {
      if (editableHandbook && isEditCompetitorsHandbookFormDirty) {
        try {
          await putHandbookCompetitorsIdMutation.mutateAsync({
            body: values as bodyRequestType<'put', '/handbook/competitors'>, // TODO: убрать as, когда пофиксят схему
            params: { path: { id: editableHandbook.id || 0 } },
          });
          add({
            name: 'competitors-create-success',
            theme: 'success',
            title: `Справочник успешно изменен`,
          });
          getHandbookCompetitorsInfiniteQuery.refetch();
        } catch {
          add({
            name: 'competitors-create-failed',
            theme: 'danger',
            title: `Не удалось изменить справочник`,
          });
        }
      }
    })();
    setEditableHandbook(null);
  };

  const deleteHandbookCompetitorsIdMutation = $api.useMutation(
    'delete',
    '/handbook/competitors/{id}'
  );

  return (
    <>
      <CustomHandbookTableLayout
        columns={columns}
        data={data}
        tableColumnsWithFilter={COMPETITORS_TABLE_COLUMNS_WITH_FILTER}
        updateColumnsSortValuesBySettings={updateColumnsSortValuesBySettings}
        searchValue={searchValue}
        onSearchValueUpdate={setSearchValue}
        onClickDelete={async index => {
          const item = flattedPages?.[index];

          if (item) {
            try {
              await deleteHandbookCompetitorsIdMutation.mutateAsync({
                params: { path: { id: item.id || 0 } },
              });

              getHandbookCompetitorsInfiniteQuery.refetch();

              add({
                name: 'competitors-delete-success',
                theme: 'success',
                title: 'Атрибут успешно удален',
              });
            } catch {
              add({
                name: 'competitors-delete-failure',
                theme: 'danger',
                title: 'Не удалось удалить атрибут',
              });
            }
          }
        }}
        onRowClick={(_, index) => setEditableHandbook(flattedPages?.[index] ?? null)}
      />
      <CustomerHandbookDrawerLayout
        open={creationOpen}
        onClose={onCloseCreation}
        title="Создать справочник «Конкуренты»"
      >
        <CompetitorsHandbookForm
          form={createCompetitorsHandbookForm}
          onSubmit={createCompetitorsHandbookForm.handleSubmit(onValidCreateHandbook)}
          disabled={postHandbookCompetitorsMutation.isPending}
          footer={
            <HandbookFormFooter
              loading={postHandbookCompetitorsMutation.isPending}
              onClose={onCloseCreation}
            />
          }
        />
      </CustomerHandbookDrawerLayout>

      <CustomerHandbookDrawerLayout
        open={!!editableHandbook}
        onClose={handleEditHandbook}
        title={editableHandbook?.full_name}
      >
        <CompetitorsHandbookForm
          form={editCompetitorsHandbookForm}
          disabled={putHandbookCompetitorsIdMutation.isPending}
          initialEditable={false}
        />
      </CustomerHandbookDrawerLayout>
    </>
  );
}
