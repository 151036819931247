import { Xmark } from '@gravity-ui/icons';
import { Button, Checkbox, Icon, RadioGroup, Text, User } from '@gravity-ui/uikit';
import { useAtom } from 'jotai';
import { useState } from 'react';

import { jumpToDateAtom, JumpToDatePicker } from '@features';

import { STATUSES_MOCK, TYPES_MOCK, USERS_MOCK } from '../model/mock'; // TODO: убрать моки

interface ChatFilterProps {
  open?: boolean;
  onClose?: () => void;
}

export function ChatFilter({ open = false, onClose }: ChatFilterProps) {
  const [selectedDate, setSelectedDate] = useAtom(jumpToDateAtom);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');

  if (!open) return null;

  return (
    <div className="bg-base-generic border-l border-base-generic basis-[257px] shrink-0 p-4 flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Text
          variant="subheader-2"
          color="primary"
        >
          Фильтры
        </Text>
        <Button
          view="flat"
          size="l"
          onClick={onClose}
        >
          <Icon data={Xmark} />
        </Button>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Text
            variant="body-1"
            color="secondary"
          >
            Дата и время
          </Text>
          <JumpToDatePicker
            value={selectedDate}
            onUpdate={setSelectedDate}
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            variant="body-1"
            color="secondary"
          >
            Люди
          </Text>
          {USERS_MOCK.map(({ id, name }) => (
            <label
              key={id}
              htmlFor={`chat-filter-user-${id}`}
              className="flex items-center gap-[9px] cursor-pointer"
              aria-label={name}
            >
              <User
                className="grow"
                size="s"
                name={name}
                avatar={{
                  text: name,
                }}
              />
              <Checkbox
                id={`chat-filter-user-${id}`}
                checked={selectedUserIds.includes(id)}
                onUpdate={checked =>
                  setSelectedUserIds(prevSelectedUserIds =>
                    checked
                      ? [...prevSelectedUserIds, id]
                      : prevSelectedUserIds.filter(selectedUserId => selectedUserId !== id)
                  )
                }
              />
            </label>
          ))}
        </div>
        <div className="flex flex-col gap-3">
          <Text
            variant="body-1"
            color="secondary"
          >
            Тип
          </Text>
          <RadioGroup
            className="flex flex-col gap-3"
            options={TYPES_MOCK.map(({ name, value }) => ({
              value,
              content: name,
            }))}
            value={selectedType}
            onUpdate={setSelectedType}
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text
            variant="body-1"
            color="secondary"
          >
            Статус
          </Text>
          <RadioGroup
            className="flex flex-col gap-3"
            options={STATUSES_MOCK.map(({ name, value, disabled }) => ({
              value,
              disabled,
              content: name,
            }))}
            value={selectedStatus}
            onUpdate={setSelectedStatus}
          />
        </div>
      </div>
    </div>
  );
}
