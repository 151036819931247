import { components } from '@shared/api';
import { yupRu } from '@shared/config';
import { MAX_FILE_SIZE } from '@shared/consts';
import { formatBytes } from '@shared/lib';
import { FieldWithPreviewMultiFileItem } from '@shared/ui';

type CommercialOfferPurpose = NonNullable<components['schemas']['offer.OfferCreation']['purpose']>;
type CommercialOfferStatus = NonNullable<components['schemas']['offer.OfferCreation']['status']>;
type CommercialOfferBasisPurchase = NonNullable<
  components['schemas']['offer.OfferCreation']['basis_purchase']
>;
type CommercialOfferWinnerType = NonNullable<
  components['schemas']['offer.OfferCreation']['winner_type']
>;

const saveCommercialOfferSchema = {
  // Работа с заказчиком
  /** @description Наименование КП @gotags: validate:"required|min_len:3|max_len:255" */
  title: yupRu
    .string()
    .max(255)
    .required()
    .test({
      test: value => value.length >= 3,
      message: 'dawdawdawd',
    }),
  /** @description Ответственный @gotags: validate:"required" */
  responsible_id: yupRu.string().required(),
  /** @description Заказчик @gotags: validate:"required" */
  customer_id: yupRu.string().required(),
  // Начальные параметры
  /** @description Цель КП @gotags: validate:"required|min_len:3|max_len:255" */
  purpose: yupRu
    .string()
    .oneOf<CommercialOfferPurpose>(['Закупка ЕП', 'Обоснование НМЦК'])
    .required(),
  /** @description Статус КП @gotags: validate:"required|min_len:3|max_len:255" */
  status: yupRu
    .string()
    .required()
    .when('purpose', {
      is: (value: CommercialOfferPurpose) => value === 'Закупка ЕП',
      then: schema =>
        schema.oneOf<CommercialOfferStatus>([
          'Выигран',
          'Проигран',
          'Отказ',
          'Отмена',
          'Подача',
          'Ожидание',
          'Не подано',
        ]),
      otherwise: schema =>
        schema.oneOf<CommercialOfferStatus>([
          'Торги опубликованы',
          'Торги не отработаны',
          'Отказ',
          'Отмена',
          'Подача',
          'Ожидание',
          'Не подано',
        ]),
    }),
  // TODO: поле тендера нет на бэке
  tender_id: yupRu.string().when('status', {
    is: (status: string) => status === 'Торги опубликованы',
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  /** @description Дата перевода @gotags: swaggertype:"string" */
  transfer_at: yupRu.string(),
  /** @description Регион поставки @gotags: validate:"required|min_len:3|max_len:255" */
  delivery_region_id: yupRu.string().required(),
  /** @description Адрес поставки @gotags: validate:"required|min_len:3|max_len:255" */
  delivery_address: yupRu.string().required(),
  /** @description Срок поставки @gotags: validate:"required|min_len:3|max_len:255" */
  delivery_deadline: yupRu.string().min(3).max(255).required(),
  /** @description Основание закупки @gotags: validate:"required|min_len:3|max_len:255" */
  basis_purchase: yupRu.string().when(['purpose', 'status'], {
    is: (purpose: string, status: string) => purpose === 'Закупка ЕП' && status === 'Выигран',
    then: schema =>
      schema
        .oneOf<CommercialOfferBasisPurchase>([
          'Прямая закупка без договора',
          'Прямая закупка с договором',
        ])
        .required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  /** @description Окончание подачи @gotags: validate:"required" @gotags: swaggertype:"string" */
  submission_deadline: yupRu.string().required(),
  /** @description Срок проведения закупки по @gotags: swaggertype:"string" */
  producement_end_at: yupRu.string(),
  /** @description Срок проведения закупки с @gotags: swaggertype:"string" */
  producement_start_at: yupRu.string(),
  /** @description Ссылки на Документы */
  documents: yupRu
    .array(yupRu.mixed<FieldWithPreviewMultiFileItem>().required())
    .required()
    .test({
      name: 'file-sizes',
      test: function (value) {
        const totalSize = value.reduce((size, { file }) => size + (file?.size || 0), 0);

        if (totalSize > MAX_FILE_SIZE) {
          return this.createError({
            message: `Общий вес файлов (${formatBytes(totalSize)}) превыщает ${formatBytes(MAX_FILE_SIZE)}`,
          });
        }

        return true;
      },
    }),
  /** @description Объект закупки */
  purchase_object_id: yupRu.string(),
  /** @description Комментарий @gotags: validate:"min_len:3|max_len:255" */
  comment: yupRu
    .string()
    .max(255)
    .trim()
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    }),

  // Рабочие параметры
  /** @description Файл расчёта @gotags: validate:"required" */
  calculation_file: yupRu.mixed<File>().fileSizeLessThan(MAX_FILE_SIZE),
  /** @description Себестоимость @gotags: validate:"required" */
  cost_price: yupRu.number(),
  /** @description Цена предложения @gotags: validate:"required|min:0" */
  offer_price: yupRu.number().min(0),
  /** @description Маржинальность @gotags: validate:"required|min:0" */
  margin: yupRu.number(),
  /** @description Файл КП @gotags: validate:"required" */
  offer_file: yupRu.mixed<File>().fileSizeLessThan(MAX_FILE_SIZE),
  /** @description Поставщик */
  provider_id: yupRu.string(),
  /** @description Предлагаемая специалистом цена @gotags: validate:"required|min:0" */
  proposed_price: yupRu
    .number()
    .min(0)
    .when(['purpose', 'status'], {
      is: (purpose: string, status: string) => purpose === 'Закупка ЕП' && status === 'Подача',
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Обоснование предложения цены @gotags: validate:"min_len:3|max_len:255" */
  price_justification: yupRu
    .string()
    .trim()
    .when(['purpose', 'status'], {
      is: (purpose: string, status: string) => purpose === 'Закупка ЕП' && status === 'Подача',
      then: schema =>
        schema.max(255).test({
          test: value => !value || value.length >= 3,
          message: 'Должен содержать как минимум 3 символа',
        }),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Комментарий согласования @gotags: validate:"min_len:3|max_len:255" */
  approval_comment: yupRu
    .string()
    .trim()
    .when('status', {
      is: (status: string) => status === 'Подача',
      then: schema =>
        schema.max(255).test({
          test: value => !value || value.length >= 3,
          message: 'Должен содержать как минимум 3 символа',
        }),
      otherwise: schema => schema.transform(() => undefined),
    }),

  //  Итоговые  параметры
  /** @description Итоговая цена @gotags: validate:"min:0" */
  final_price: yupRu
    .number()
    .min(0)
    .when(['purpose', 'status'], {
      is: (purpose: string, status: string) =>
        purpose === 'Закупка ЕП' && (status === 'Выигран' || status === 'Проигран'),
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Победитель */
  winner_id: yupRu.string().when(['purpose', 'status'], {
    is: (purpose: string, status: string) =>
      purpose === 'Закупка ЕП' && (status === 'Выигран' || status === 'Проигран'),
    then: schema => schema.required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  /** @description Тип победителя */
  winner_type: yupRu.string().when(['purpose', 'status'], {
    is: (purpose: string, status: string) =>
      purpose === 'Закупка ЕП' && (status === 'Выигран' || status === 'Проигран'),
    then: schema =>
      schema.oneOf<CommercialOfferWinnerType>(['competitor', 'legal_entity']).required(),
    otherwise: schema => schema.transform(() => undefined),
  }),
  /** @description Согласование с руководителем @gotags: validate:"required" */
  manager_approved: yupRu.boolean().required(),
  /** @description Причина отказа @gotags: validate:"required|min_len:3|max_len:255" */
  reject_description: yupRu
    .string()
    .min(3)
    .max(255)
    .when('status', {
      is: (status: string) => status === 'Отказ',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Руководитель ответственного */
  responsible_manager_id: yupRu.string(),
};

export const commercialOfferSchema = yupRu.object(saveCommercialOfferSchema);

export const operatingParametersSchema = yupRu.object({
  /** @description Файл расчёта @gotags: validate:"required" */
  calculation_file: yupRu.mixed<File>().required().fileSizeLessThan(MAX_FILE_SIZE),
  /** @description Себестоимость @gotags: validate:"required" */
  cost_price: yupRu.number().min(0).required(),
  /** @description Цена предложения @gotags: validate:"required|min:0" */
  offer_price: yupRu.number().min(0).required(),
  /** @description Маржинальность @gotags: validate:"required|min:0" */
  margin: yupRu.number().required(),
  /** @description Файл КП @gotags: validate:"required" */
  offer_file: yupRu.mixed<File>().required().fileSizeLessThan(MAX_FILE_SIZE),
  /** @description Поставщик */
  provider_id: yupRu.string(),
  /** @description Предлагаемая специалистом цена @gotags: validate:"required|min:0" */
  proposed_price: yupRu
    .number()
    .min(0)
    .when(['purpose', 'status'], {
      is: (purpose: string, status: string) => purpose === 'Закупка ЕП' && status === 'Подача',
      then: schema => schema.required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Обоснование предложения цены @gotags: validate:"min_len:3|max_len:255" */
  price_justification: yupRu
    .string()
    .trim()
    .when(['purpose', 'status'], {
      is: (purpose: string, status: string) => purpose === 'Закупка ЕП' && status === 'Подача',
      then: schema => schema.min(3).max(255).required(),
      otherwise: schema => schema.transform(() => undefined),
    }),
  /** @description Комментарий согласования @gotags: validate:"min_len:3|max_len:255" */
  approval_comment: yupRu
    .string()
    .trim()
    .when('status', {
      is: (status: string) => status === 'Подача',
      then: schema =>
        schema.max(255).test({
          test: value => !value || value.length >= 3,
          message: 'Должен содержать как минимум 3 символа',
        }),
      otherwise: schema => schema.transform(() => undefined),
    }),
});

export type CommercialOfferSchema = yupRu.InferType<typeof commercialOfferSchema>;
export type OperatingParametersSchema = yupRu.InferType<typeof operatingParametersSchema>;
