import './index.sass';

import { Xmark } from '@gravity-ui/icons';
import { Button, Dialog, Icon, Text } from '@gravity-ui/uikit';
import { useCallback, useMemo } from 'react';

import { formatToPrice, objectKeysSafeToArray, pluralize } from '@shared/lib';

import { convertFormattingPriceToNumber } from '../../lib';

interface AmountDialogProps<T extends string[]> {
  open: boolean;
  onClose: () => void;
  rows: Record<T[number], string | number>[];
  currencyColumnsNames: T[number][];
  firstColumnName: T[number];
}

export function AmountDialog<ColumnNames extends string[]>({
  onClose,
  open,
  rows,
  currencyColumnsNames,
  firstColumnName,
}: AmountDialogProps<ColumnNames>) {
  const totalValues = useMemo(() => {
    return currencyColumnsNames.reduce<Record<ColumnNames[number], number>>(
      (acc, col) => {
        acc[col] = rows.reduce((sum, row) => {
          if (row[col]) {
            sum = sum + convertFormattingPriceToNumber(row[col].toString());
          }
          return sum;
        }, 0);
        return acc;
      },
      {} as Record<ColumnNames[number], number>
    );
  }, [currencyColumnsNames, rows]);

  const renderRows = useCallback(() => {
    return (
      <>
        {rows.map((row, index) => (
          <div
            key={index}
            className="flex py-[11px] border-line-generic border-b"
          >
            <Text
              variant="body-1"
              color="primary"
              className="min-w-[33.3%] max-w-[33.3%]"
            >
              {row[firstColumnName]}
            </Text>

            {currencyColumnsNames.map(name => (
              <Text
                key={name + index.toString()}
                variant="body-1"
                color="primary"
                className="text-right min-w-[33.3%] max-w-[33.3%]"
              >
                {row[name]}
              </Text>
            ))}
          </div>
        ))}
      </>
    );
  }, [currencyColumnsNames, rows, firstColumnName]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="amount-dialog"
      disableBodyScrollLock
      size="s"
      className="amount-dialog max-h-[852px]"
      hasCloseButton={false}
    >
      <Dialog.Header
        className="flex items-center justify-between"
        caption={`Сумма ${rows.length} ${pluralize(rows.length, 'строки', 'строк', 'строк')}`}
        insertAfter={
          <Button
            size="l"
            view="flat"
            onClick={onClose}
          >
            <Icon data={Xmark} />
          </Button>
        }
      />
      <Dialog.Body className="flex flex-col overflow-hidden">
        <div className="overflow-x-auto flex flex-col hide-scrollbar h-full">
          <div className="shrink-0 flex py-[11px] border-line-generic">
            <Text
              variant="subheader-1"
              color="primary"
              className="min-w-[33.3%] max-w-[33.3%]"
            >
              {firstColumnName}
            </Text>
            {objectKeysSafeToArray(totalValues).map((key, index) => (
              <Text
                key={key + firstColumnName + index.toString()}
                variant="subheader-1"
                color="primary"
                className="text-right min-w-[33.3%] max-w-[33.3%]"
              >
                {key}
              </Text>
            ))}
          </div>

          <div className="grow hide-scrollbar">{renderRows()}</div>

          <div className="shrink-0 flex py-[11px] border-line-generic">
            <Text
              variant="subheader-1"
              color="primary"
              className="min-w-[33.3%] max-w-[33.3%]"
            >
              Итого:
            </Text>
            {objectKeysSafeToArray(totalValues).map((key, index) => (
              <Text
                key={key + index.toString()}
                variant="subheader-1"
                color="primary"
                className="text-right min-w-[33.3%] max-w-[33.3%]"
              >
                {formatToPrice(totalValues[key], true)}
              </Text>
            ))}
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer
        onClickButtonCancel={onClose}
        textButtonCancel="Закрыть"
      />
    </Dialog>
  );
}
