import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { ChatBottomPanel, ChatFilter, ChatHeader, ChatMessages, ChatSettingsModal } from '@widgets';
import { DIALOGS_MOCK, MESSAGE_GROUPS_MOCK, MESSAGE_GROUPS_MOCK2 } from '@widgets/chat/model/mock'; // TODO: импорт должен быть из паблик апи

import { MessageGroups } from '@widgets/chat/model/types';
import { URLS } from '@shared/consts';

const atomInputMessage = atom('');
export function MessengerChatId() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // TODO: Если не существует такого айди, то редирект на WithoutDialog
  const chatId = useParams().id;
  const [prevChatId, setPrevChatId] = useState(chatId);
  const chosenDialog = DIALOGS_MOCK.find(dialog => dialog.id === chatId);

  const [changed, setChanged] = useState(false);
  const [chosenChatMessages, setChosenChatMessages] = useState(
    chosenDialog?.id.includes('-1') ? MESSAGE_GROUPS_MOCK : MESSAGE_GROUPS_MOCK2
  );
  const [lastMessageGroup, setLastMessageGroup] = useState(chosenChatMessages[0]!);

  useEffect(() => {
    if (chosenDialog && chatId !== prevChatId) {
      setPrevChatId(chatId);
      setLastMessageGroup(chosenChatMessages[0]!);
      setChosenChatMessages(
        chosenDialog.id.includes('-1') ? MESSAGE_GROUPS_MOCK : MESSAGE_GROUPS_MOCK2
      );
    }
  }, [chatId, chosenChatMessages, chosenDialog, prevChatId]);

  const [inputMessage, setInputMessage] = useAtom(atomInputMessage);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      setChosenChatMessages(prev => {
        const temp = prev.slice();
        temp.splice(0, 1, lastMessageGroup);
        return temp;
      });
    }
  }, [lastMessageGroup, changed]);

  return !chosenDialog ? (
    <Navigate
      to={URLS.messenger}
      replace
    />
  ) : (
    <>
      <ChatSettingsModal
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
      />
      <div className="flex flex-col grow overflow-hidden">
        <ChatHeader // TODO: Улучшить логику разделение на проект и личный чат
          type={chosenDialog.type}
          countOfMembers={chosenDialog.type === 'project' ? 999 : undefined} //TODO: не хватает числа в моках
          avatarImageURL={chosenDialog.avatarImageURL || ''}
          avatarName={chosenDialog.avatarName}
          name={
            chosenDialog.type === 'project' ? chosenDialog.projectName || '' : chosenDialog.name
          }
          onClickFilter={() => setIsFilterOpen(prevIsFilterOpen => !prevIsFilterOpen)}
          onClickSettings={() => setIsSettingsOpen(true)}
        />
        <div className="overflow-hidden flex grow">
          <div className="bg-base-generic overflow-hidden flex flex-col grow">
            <ChatMessages messagesGroups={chosenChatMessages} />
            <ChatBottomPanel
              textInputProps={{
                value: inputMessage,
                onUpdate: setInputMessage,
              }}
              sendButtonProps={{
                onClick: () => {
                  setLastMessageGroup(prev => ({
                    ...prev,
                    dateTimestamp: new Date().getTime(),
                    messages: [
                      ...prev.messages,
                      {
                        text: inputMessage,
                        id: '123',
                        name: 'Пользователь',
                        userId: '123',
                        avatarName: 'Пользователь',
                        sendedTimestamp: new Date().getTime(),
                      } as MessageGroups['messages'][number],
                    ],
                  }));
                  setChanged(true);
                  setInputMessage('');
                },
              }}
            />
          </div>
          <ChatFilter
            open={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          />
        </div>
      </div>
    </>
  );
}
