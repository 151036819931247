export function calculateOfferPrice({
  costPrice,
  margin,
}: {
  costPrice?: number;
  margin?: number;
}) {
  if (typeof margin === 'number' && typeof costPrice === 'number')
    return (100 * costPrice) / (100 - margin);
}
