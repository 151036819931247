import './styles/global.sass';

import { settings } from '@gravity-ui/date-utils';
import { configure } from '@gravity-ui/uikit';

import { Providers } from './providers';
import { AppRouter } from './routers';

settings.loadLocale('ru').then(() => {
  settings.setLocale('ru');
});

configure({
  lang: 'ru',
});

function App() {
  return (
    <Providers>
      <AppRouter />
    </Providers>
  );
}

export default App;
