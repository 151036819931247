import { Button, ButtonProps } from '@gravity-ui/uikit';
import { ComponentRef, forwardRef } from 'react';

export const AttachFileButton = forwardRef<ComponentRef<typeof Button>, ButtonProps>(
  (props, ref) => (
    <Button
      ref={ref}
      size="l"
      view="flat-secondary"
      {...props}
    />
  )
);
