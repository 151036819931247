import { dateTime } from '@gravity-ui/date-utils';
import { BellSlash, FileText, PersonPlus, Xmark } from '@gravity-ui/icons';
import {
  Button,
  Divider,
  Icon,
  LayerCloseReason,
  Modal,
  ModalProps,
  RadioButton,
  Text,
  User,
} from '@gravity-ui/uikit';
import { useState } from 'react';

import { cn } from '@shared/lib';

import { HISTORY_MOCK, OPTIONS_MOCK, SETTINGS_USERS_MOCK } from '../model/mock'; // TODO: убрать моки

interface ChatSettingsModalProps extends Omit<ModalProps, 'children' | 'onClose'> {
  onClose?: (
    event: MouseEvent | KeyboardEvent | React.MouseEvent,
    reason: LayerCloseReason | 'closeButtonClick'
  ) => void;
}

export function ChatSettingsModal({ contentClassName, onClose, ...props }: ChatSettingsModalProps) {
  const [activeTab, setActiveTab] = useState('members');

  return (
    <Modal
      onClose={onClose}
      contentClassName={cn('rounded-[16px]', contentClassName)}
      {...props}
    >
      <div className="px-6 pt-6 flex flex-col gap-4 w-[496px] h-[520px]">
        <div className="flex items-center gap-5 justify-between">
          <Text
            variant="subheader-3"
            color="primary"
          >
            КП Орион — ООО Димитров Иван Сергеевич
          </Text>
          <Button
            view="flat"
            size="l"
            onClick={event => onClose?.(event, 'closeButtonClick')}
          >
            <Icon data={Xmark} />
          </Button>
        </div>
        {activeTab === 'members' && (
          <div className="flex gap-1 justify-stretch">
            <Button
              view="flat-info"
              size="xl"
              selected
              className="flex flex-col justify-center px-3 py-4 basis-[0] grow"
              style={{ height: 'auto' }}
            >
              <div className="flex flex-col gap-1 items-center">
                <Icon data={PersonPlus} />
                <Text
                  variant="body-1"
                  className="text-wrap [word-spacing:999px;]"
                >
                  Добавить участника
                </Text>
              </div>
            </Button>
            <Button
              view="flat-info"
              size="xl"
              selected
              className="flex flex-col justify-center px-3 py-4 basis-[0] grow"
              style={{ height: 'auto' }}
            >
              <div className="flex flex-col gap-1 items-center">
                <Icon data={BellSlash} />
                <Text
                  variant="body-1"
                  className="text-wrap [word-spacing:999px;]"
                >
                  Выключить уведомления
                </Text>
              </div>
            </Button>
            <Button
              view="flat-info"
              size="xl"
              selected
              className="flex flex-col justify-center px-3 py-4 basis-[0] grow"
              style={{ height: 'auto' }}
            >
              <div className="flex flex-col gap-1 items-center">
                <Icon data={FileText} />
                <Text
                  variant="body-1"
                  className="text-wrap [word-spacing:999px;]"
                >
                  Страница проекта
                </Text>
              </div>
            </Button>
          </div>
        )}
        <RadioButton
          size="l"
          options={OPTIONS_MOCK}
          value={activeTab}
          onUpdate={setActiveTab}
        />
        <div className="overflow-y-auto hide-scrollbar">
          {activeTab === 'members' && (
            <div className="flex flex-col gap-3 pb-6">
              {SETTINGS_USERS_MOCK.map(({ name }, index) => (
                <User
                  key={index}
                  size="l"
                  name={name}
                  avatar={{
                    text: name,
                  }}
                />
              ))}
            </div>
          )}
          {activeTab === 'history' && (
            <div className="flex flex-col gap-4">
              {HISTORY_MOCK.map(({ timestamp, description, title, variant, author }, index) => {
                const date = dateTime({ input: timestamp }).format('DD.MM.YY');

                return (
                  <div
                    key={index}
                    className="flex flex-col gap-2"
                  >
                    {!index && <Divider className="my-1" />}
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center justify-between gap-1">
                        <Text
                          color={variant === 'danger' ? 'danger' : 'complementary'}
                          variant="subheader-1"
                          className="truncate"
                        >
                          {title}
                        </Text>
                        <Text
                          color={variant === 'danger' ? 'danger' : 'secondary'}
                          variant="caption-2"
                          className="shrink-0"
                        >
                          {date}
                          {!!author && `, ${author}`}
                        </Text>
                      </div>
                      <Text
                        color="complementary"
                        variant="body-1"
                      >
                        {description}
                      </Text>
                    </div>
                    <Divider className="my-1" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
