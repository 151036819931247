import { TenderSchema } from '@features';

import { CreateTender } from './CreateTender';
import { EditTender } from './EditTender';

type TenderDrawerProps = {
  open: boolean;
  onClose: () => void;
} & (
  | {
      type: 'create';
      id?: undefined;
      afterCreate?: (tender: TenderSchema) => void;
    }
  | {
      type: 'edit';
      id?: number;
      afterCreate?: undefined;
    }
);

export function TenderDrawer({ type, open, onClose, id, afterCreate }: TenderDrawerProps) {
  return (
    <>
      {type === 'create' ? (
        <CreateTender
          open={open}
          onClose={onClose}
          afterCreate={afterCreate}
        />
      ) : (
        <EditTender
          open={open}
          onClose={onClose}
          id={id}
        />
      )}
    </>
  );
}
