import { Avatar, Text } from '@gravity-ui/uikit';

interface GlobalSearchUserListItemProps {
  avatarImageURL: string;
  avatarName: string;
  name: string;
  role: string;
}

export function GlobalSearchUserListItem({
  avatarImageURL,
  avatarName,
  name,
  role,
}: GlobalSearchUserListItemProps) {
  return (
    <button className="flex items-center gap-2 py-2">
      <Avatar
        size="m"
        imgUrl={avatarImageURL}
        text={avatarName}
        loading="lazy"
        alt={avatarName}
        fallbackImgUrl={undefined}
      />
      <div className="grow flex flex-col text-start">
        <Text
          variant="body-2"
          color="primary"
        >
          {name}
        </Text>
        <Text
          variant="caption-2"
          color="secondary"
        >
          {role}
        </Text>
      </div>
    </button>
  );
}
