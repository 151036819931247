import { ArrowLeft, Xmark } from '@gravity-ui/icons';
import { Button, Dialog, Icon } from '@gravity-ui/uikit';
import { ReactNode } from 'react';

interface CreateHandbookLayoutProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children?: ReactNode;
}

export function CreateHandbookLayout({
  onClose,
  open,
  title,
  children,
}: CreateHandbookLayoutProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="w-[688px] commercial-offer-layout-dialog"
    >
      <Dialog.Body className="p-0">
        <Dialog.Header
          caption={title}
          className="h-[72px] px-8"
          insertBefore={
            <Button
              size="l"
              view="flat"
              className="mr-1"
            >
              <Icon data={ArrowLeft} />
            </Button>
          }
          insertAfter={
            <Button
              view="flat"
              size="l"
              className="ml-auto"
            >
              <Icon data={Xmark} />
            </Button>
          }
        />
        {children}
      </Dialog.Body>
    </Dialog>
  );
}
