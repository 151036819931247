import { createJSONStorage } from 'jotai/utils';

/**
 * функция для atomWithStorage, которая возвращает методы localStorage (storage), чтобы не пришлось конвертировать JSON
 */
export function customStorage<T>() {
  return createJSONStorage<T>(() => ({
    getItem: key => {
      try {
        const item = localStorage.getItem(key);
        if (typeof item === 'string' && item.length > 0) return JSON.parse(item);
        return null;
      } catch {
        localStorage.removeItem(key);
        return null;
      }
    },
    removeItem: key => localStorage.removeItem(key),
    setItem: (key, value) => {
      return localStorage.setItem(key, JSON.stringify(value));
    },
    // subcribe взят из исходников либы
    subscribe: (key, callback) => {
      const storageEventCallback = (e: StorageEvent) => {
        if (e.storageArea === localStorage && e.key === key) {
          callback(e.newValue);
        }
      };
      window.addEventListener('storage', storageEventCallback);
      return () => {
        window.removeEventListener('storage', storageEventCallback);
      };
    },
  }));
}
