export const URLS = {
  // навигация слева, <NavigationSidebar/>
  processes: {
    default: '/processes',
    commercialOffers: '/processes/commercial-offers',
    commercialOffersDynamicParams: {
      id: 'id',
    },
    tenders: '/processes/tenders',
    tendersDynamicParams: {
      id: 'id',
    },
    contracts: '/processes/contracts',
  },
  handling: {
    default: '/handling',
  },
  handbook: {
    default: '/handbook',
    system: '/handbook/system',
    custom: '/handbook/custom',
    dynamicParams: {
      handbookName: 'handbookName',
      attributeId: 'attributeId',
    },
  },
  bugReport: '/bug-report',
  // Поиск
  search: {
    default: '/search',
    dynamicParams: {
      processName: 'processName',
      processId: 'processId',
    },
  },
  // Авторизация
  auth: {
    default: '/auth',
    resetPassword: '/auth/reset-password',
    newPassword: '/auth/new-password',
    confirmPincode: '/auth/confirm-pincode',
  },
  // Мессенджер (Чаты)
  messenger: '/messenger',
  // Личный Кабинет
  profile: '/profile',
  // Календарь
  calendar: '/calendar',
};
