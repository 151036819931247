import { Button } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router-dom';

import Logo from '@shared/assets/icons/main-logo.svg?react';
import { URLS } from '@shared/consts';
import { cn, useNavigateTo } from '@shared/lib';
import { themeAtomLocalStorage } from '@shared/model';

export function AuthLayout() {
  const { navigateTo } = useNavigateTo();
  const theme = useAtomValue(themeAtomLocalStorage);

  return (
    <div className="bg-base-generic w-full h-svh flex flex-col items-center">
      <Button
        onClick={() => navigateTo(URLS.auth.default)}
        view="flat"
        className="align-self-start mt-10 flex items-center content-center"
      >
        <Logo
          className={cn({
            'b2g-logo_dark-theme': theme === 'dark',
          })}
        />
      </Button>
      <Outlet />
    </div>
  );
}
