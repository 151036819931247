import { Label, Text } from '@gravity-ui/uikit';

import {
  CommercialOfferStatuses,
  convertCommercialOfferStatusToLabelTheme,
} from '@entities/commercial-offers-entity/@x';
import { ContractStatuses, convertContractStatusToLabelTheme } from '@entities/contracts-entity/@x';
import { convertTenderStatusToLabelTheme, TenderStatuses } from '@entities/tenders-entity/@x';
import { capitalizeFirstLetter, objectKeysSafeToArray } from '@shared/lib';

import { CalendarCardEntityProps, CalendarCardProps } from '../model/types';

type Statuses = {
  КП: CommercialOfferStatuses;
  Торги: TenderStatuses;
  Контракты: ContractStatuses;
};

function calendarEntities(
  entities?: CalendarCardEntityProps[],
  selectedStatus?: CalendarCardProps['selectedStatus']
) {
  if (!entities) return;
  function openEntity(entityType: CalendarCardEntityProps['entityType']) {
    switch (entityType) {
      case 'КП':
        return alert('Открыть Карточку КП');
      case 'Торги':
        return alert('Открыть Карточку Торгов');
      case 'Контракты':
        return alert('Открыть Карточку Контрактов');
    }
  }

  function convertStatus<T extends keyof Statuses>(entityType: T, status: Statuses[T]) {
    switch (entityType) {
      case 'КП':
        return convertCommercialOfferStatusToLabelTheme(status as CommercialOfferStatuses);
      case 'Торги':
        return convertTenderStatusToLabelTheme(status as TenderStatuses);
      case 'Контракты':
        return convertContractStatusToLabelTheme(status as ContractStatuses);
    }
  }

  return entities
    .filter(entity => !selectedStatus || entity.status === selectedStatus)
    .map(entity => (
      <Label
        key={Math.random() + entity.title}
        className="mb-2"
        size="xs"
        theme={convertStatus(entity.entityType, entity.status)}
        onClick={() => openEntity(entity.entityType)}
      >
        {entity.title}
      </Label>
    ));
}

function calendarEntitiesGroups(
  entities: Partial<Record<CalendarCardEntityProps['entityType'], CalendarCardEntityProps[]>>,
  selectedOption: CalendarCardProps['entityType'],
  selectedStatus?: CalendarCardProps['selectedStatus']
) {
  switch (selectedOption) {
    case 'КП':
      return calendarEntities(entities['КП'], selectedStatus);
    case 'Торги':
      return calendarEntities(entities['Торги'], selectedStatus);
    case 'Контракты':
      return calendarEntities(entities['Контракты'], selectedStatus);
  }
  return objectKeysSafeToArray(entities).map(key =>
    entities[key] ? (
      <div
        className="flex flex-col mb-2"
        key={Math.random() + key}
      >
        <Text
          variant="body-1"
          color="secondary"
          className="mb-2"
        >
          {key}
        </Text>
        {calendarEntities(entities[key], selectedStatus)}
      </div>
    ) : undefined
  );
}
export function CalendarCard({ date, entities, entityType, selectedStatus }: CalendarCardProps) {
  // const date = parseISOString(dateISO);
  const month = date
    .toLocaleString('ru', { month: 'long', day: 'numeric', weekday: 'short' })
    .split(' ') as [string, string, string];
  const dayName = capitalizeFirstLetter(month[0].substring(0, 2));
  const day = month[1];
  const monthName = month[2];

  function renderEntities() {
    if (!entities) return;
    else if (Array.isArray(entities)) return calendarEntities(entities, selectedStatus);
    else return calendarEntitiesGroups(entities, entityType, selectedStatus);
  }

  return (
    <div className="w-[256px] min-w-[256px] flex flex-col">
      <div className="flex flex-row gap-1 items-end py-1 px-2 w-full">
        <Text variant="header-2">{day}</Text>
        <Text
          variant="body-2"
          className="w-full"
        >
          {monthName}
        </Text>
        <Text
          variant="body-2"
          color="hint"
        >
          {dayName}
        </Text>
      </div>
      <div className="flex flex-col p-2 border border-solid border-base-generic-ultralight border-b-0 border-l-0 size-full">
        {renderEntities()}
      </div>
    </div>
  );
}
