import { dateTime } from '@gravity-ui/date-utils';
import { Button, ButtonProps, Divider, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';

import {
  calculateMargin,
  getIsSubmissionDeadlineExpired,
} from '@features/commercial-offer-form/@x/tender-form';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { DEFAULT_VALUES } from '../consts';
import { generateTitle, getRequiredOperatingParametersErrors } from '../lib';
import {
  ApplicationStatus,
  OPTIONS_MOCK,
  ProducementStatus,
  tenderFormAtom,
  tenderSchema,
  TenderSchema,
} from '../model';

export interface TenderFormProps {
  defaultValues?: UseFormProps<TenderSchema>['defaultValues'];
  values?: UseFormProps<TenderSchema>['values'];
  onValid?: SubmitHandler<TenderSchema>;
  onInvalid?: SubmitErrorHandler<TenderSchema>;
  initialEditable?: boolean;
  hide?: boolean;
  disabled?: boolean;
  hideBottomPanel?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  onClickCancelButton?: () => void;
  onValuesChange?: (values: TenderSchema) => void;
  approvalButtonProps?: Pick<ButtonProps, 'onClick' | 'disabled'>;
  editableSettings?: Partial<
    Record<
      keyof TenderSchema,
      Parameters<ReturnType<typeof useFieldsWithPreview>['isFieldEditable']>['1']
    >
  >;
  allowedApplicationStatuses: (
    values: TenderSchema
  ) => (
    | 'Подача'
    | 'Ожидание'
    | 'Выигран'
    | 'Проигран'
    | 'Отклонение'
    | 'Не подано'
    | 'Отказ'
    | 'Ожидание'
  )[];
  allowedProcurementStatuses: (
    values: TenderSchema
  ) => ('В процессе' | 'Состоялась' | 'Не состоялась' | 'Отменена' | 'Не известно')[];
}

export function TenderForm({
  values: externalValues,
  defaultValues,
  initialEditable = false,
  disabled = false,
  hide = false,
  editableSettings,
  hideBottomPanel = false,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  onValuesChange,
  onClickCancelButton,
  onInvalid,
  onValid = () => {},
  approvalButtonProps,
  allowedApplicationStatuses: getAllowedApplicationStatuses,
  allowedProcurementStatuses: getAllowedProcurementStatuses,
}: TenderFormProps) {
  const { add } = useToaster();

  const tenderForm = useForm<TenderSchema>({
    resolver: yupResolver(tenderSchema),
    mode: 'all',
    values: externalValues,
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
    resetOptions: {
      keepDefaultValues: false,
    },
  });

  const setTenderForm = useSetAtom(tenderFormAtom);

  const { createFieldRef, isFieldEditable, resetSelectedField, selectField, selectedFieldPath } =
    useFieldsWithPreview({
      form: tenderForm,
      initialEditable,
      disabled,
    });

  const values = tenderForm.watch();

  const allowedApplicationStatuses = getAllowedApplicationStatuses(values);
  const allowedProcurementStatuses = getAllowedProcurementStatuses(values);

  const applicationStatusOptions = OPTIONS_MOCK['Статус заявки'].filter(option =>
    allowedApplicationStatuses.includes(option.value)
  );

  const procurementStatusOptions = OPTIONS_MOCK['Статус закупки'].filter(option =>
    allowedProcurementStatuses.includes(option.value)
  );

  const requiredOperatingParametersErrors = getRequiredOperatingParametersErrors(values);

  const isApprovalButtonDisabled =
    disabled ||
    values['Согласовано'] ||
    !tenderForm.formState.isValid ||
    !!requiredOperatingParametersErrors?.length;

  useEffect(() => {
    setTenderForm(tenderForm);

    return () => {
      setTenderForm(null);
    };
  }, [tenderForm]);

  useEffect(() => {
    onValuesChange?.(values);
  }, [values]);

  useEffect(() => {
    const customers = OPTIONS_MOCK['Заказчики']
      .filter(customer => values['Заказчики']?.includes(customer.value))
      .map(customer => customer.content);

    const supplyObject = OPTIONS_MOCK['Объект торгов'].find(
      supplyObject => supplyObject.value === (values['Объект торгов'] as unknown as string)
    )?.content;

    const title = generateTitle({
      customers,
      supplyObject,
      uniqueNumber: values['Номер извещения'],
    });

    tenderForm.setValue('Наименование', title ?? '');
  }, [values['Заказчики'], values['Номер извещения'], values['Объект торгов']]);

  useEffect(() => {
    if (!allowedApplicationStatuses.includes(values['Статус заявки'])) {
      tenderForm.resetField('Статус заявки', { defaultValue: '' as ApplicationStatus });
    }

    if (!allowedProcurementStatuses.includes(values['Статус закупки'])) {
      tenderForm.resetField('Статус закупки', { defaultValue: '' as ProducementStatus });
    }
  }, [values['Статус заявки'], values['Статус закупки']]);

  useEffect(() => {
    if (values['Статус заявки'] === 'Отказ') {
      const isSubmissionDeadlineExpired = getIsSubmissionDeadlineExpired(
        values['Окончание подачи']
      );

      if (isSubmissionDeadlineExpired) {
        tenderForm.resetField('Статус заявки');
        return add({
          name: 'tender-cannot-reject',
          theme: 'danger',
          title:
            'Невозможно отказаться после 16:00 (МСК) предшествующего дня окончания подачи заявки',
        });
      }
    }
  }, [values['Статус заявки'], values['Окончание подачи']]);

  useEffect(() => {
    const submissionDate = values['Окончание подачи']
      ? dateTime({ input: values['Окончание подачи'] })
      : null;

    const procedureExecutionDate = values['Проведение процедуры']
      ? dateTime({ input: values['Проведение процедуры'] })
      : null;

    const summarizingDate = values['Подведение итогов']
      ? dateTime({ input: values['Подведение итогов'] })
      : null;

    if (submissionDate && procedureExecutionDate?.isBefore(submissionDate)) {
      tenderForm.resetField('Проведение процедуры', { defaultValue: null as unknown as undefined });
    }

    if (submissionDate && summarizingDate?.isBefore(submissionDate)) {
      tenderForm.resetField('Подведение итогов', { defaultValue: null as unknown as undefined });
    }

    if (procedureExecutionDate && summarizingDate?.isBefore(procedureExecutionDate)) {
      tenderForm.resetField('Подведение итогов', { defaultValue: null as unknown as undefined });
    }
  }, [values['Окончание подачи'], values['Проведение процедуры'], values['Подведение итогов']]);

  if (hide) return null;

  return (
    <form
      className="flex flex-col overflow-hidden"
      onSubmit={tenderForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        <FieldWithPreview.Select
          ref={createFieldRef('Ответственный')}
          control={tenderForm.control}
          path="Ответственный"
          edit={isFieldEditable('Ответственный', {
            whenDirty: false,
            ...editableSettings?.['Ответственный'],
          })}
          onFieldSelect={() => selectField('Ответственный')}
          name="Ответственный"
          options={OPTIONS_MOCK['Ответственный']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('Наименование')}
          control={tenderForm.control}
          path="Наименование"
          edit={false}
          onFieldSelect={() => selectField('Наименование')}
          name="Наименование"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Статус заявки')}
          control={tenderForm.control}
          path="Статус заявки"
          edit={isFieldEditable('Статус заявки', editableSettings?.['Статус заявки'])}
          onFieldSelect={() => selectField('Статус заявки')}
          name="Статус заявки"
          options={applicationStatusOptions}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          show={values['Статус заявки'] === 'Отклонение'}
          ref={createFieldRef('Отклонение')}
          control={tenderForm.control}
          path="Отклонение"
          edit={isFieldEditable('Отклонение', editableSettings?.['Отклонение'])}
          onFieldSelect={() => selectField('Отклонение')}
          name="Отклонение"
          options={OPTIONS_MOCK['Отклонение']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={values['Статус заявки'] === 'Отказ'}
          ref={createFieldRef('Причина отказа')}
          control={tenderForm.control}
          path="Причина отказа"
          edit={isFieldEditable('Причина отказа', editableSettings?.['Причина отказа'])}
          onFieldSelect={() => selectField('Причина отказа')}
          name="Причина отказа"
          options={OPTIONS_MOCK['Причина отказа']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Статус закупки')}
          control={tenderForm.control}
          path="Статус закупки"
          edit={isFieldEditable('Статус закупки', editableSettings?.['Статус закупки'])}
          onFieldSelect={() => selectField('Статус закупки')}
          name="Статус закупки"
          options={procurementStatusOptions}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          show={values['Статус закупки'] === 'Не состоялась'}
          ref={createFieldRef('Причина почему не состоялась?')}
          control={tenderForm.control}
          path="Причина почему не состоялась?"
          edit={isFieldEditable(
            'Причина почему не состоялась?',
            editableSettings?.['Причина почему не состоялась?']
          )}
          onFieldSelect={() => selectField('Причина почему не состоялась?')}
          name="Причина почему не состоялась?"
          options={OPTIONS_MOCK['Причина почему не состоялась?']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.TextArea
          show={values['Статус заявки'] === 'Отклонение'}
          ref={createFieldRef('Основание и причина отклонения')}
          control={tenderForm.control}
          path="Основание и причина отклонения"
          edit={isFieldEditable(
            'Основание и причина отклонения',
            editableSettings?.['Основание и причина отклонения']
          )}
          onFieldSelect={() => selectField('Основание и причина отклонения')}
          name="Основание и причина отклонения"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('Номер извещения')}
          control={tenderForm.control}
          path="Номер извещения"
          edit={isFieldEditable('Номер извещения', editableSettings?.['Номер извещения'])}
          onFieldSelect={() => selectField('Номер извещения')}
          name="Номер извещения"
          textInputProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('Ссылка на процедуру')}
          control={tenderForm.control}
          path="Ссылка на процедуру"
          edit={isFieldEditable('Ссылка на процедуру', editableSettings?.['Ссылка на процедуру'])}
          onFieldSelect={() => selectField('Ссылка на процедуру')}
          name="Ссылка на процедуру"
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Электронная торговая площадка')}
          control={tenderForm.control}
          path="Электронная торговая площадка"
          edit={isFieldEditable(
            'Электронная торговая площадка',
            editableSettings?.['Электронная торговая площадка']
          )}
          onFieldSelect={() => selectField('Электронная торговая площадка')}
          name="Электронная торговая площадка"
          options={OPTIONS_MOCK['Электронная торговая площадка']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={values['Электронная торговая площадка'] === 'АСТ ГОЗ'}
          ref={createFieldRef('В рамках ГОЗ по 275-ФЗ')}
          control={tenderForm.control}
          path="В рамках ГОЗ по 275-ФЗ"
          edit={isFieldEditable(
            'В рамках ГОЗ по 275-ФЗ',
            editableSettings?.['В рамках ГОЗ по 275-ФЗ']
          )}
          onFieldSelect={() => selectField('В рамках ГОЗ по 275-ФЗ')}
          name="В рамках ГОЗ по 275-ФЗ"
          options={OPTIONS_MOCK['В рамках ГОЗ по 275-ФЗ']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('ФЗ')}
          control={tenderForm.control}
          path="ФЗ"
          edit={isFieldEditable('ФЗ', editableSettings?.['ФЗ'])}
          onFieldSelect={() => selectField('ФЗ')}
          name="ФЗ"
          options={OPTIONS_MOCK['ФЗ']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('Закупка централизованная или совместная?')}
          control={tenderForm.control}
          path="Закупка централизованная или совместная?"
          edit={isFieldEditable(
            'Закупка централизованная или совместная?',
            editableSettings?.['Закупка централизованная или совместная?']
          )}
          onFieldSelect={() => selectField('Закупка централизованная или совместная?')}
          name="Закупка централизованная или совместная?"
          options={OPTIONS_MOCK['Закупка централизованная или совместная?']}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.MultiSelect
          ref={createFieldRef('Заказчики')}
          control={tenderForm.control}
          path="Заказчики"
          edit={isFieldEditable('Заказчики', editableSettings?.['Заказчики'])}
          onFieldSelect={() => selectField('Заказчики')}
          name="Заказчики"
          options={OPTIONS_MOCK['Заказчики']}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Radio
          ref={createFieldRef('Приоритет заказчика')}
          control={tenderForm.control}
          path="Приоритет заказчика"
          edit={isFieldEditable('Приоритет заказчика', editableSettings?.['Приоритет заказчика'])}
          onFieldSelect={() => selectField('Приоритет заказчика')}
          name="Приоритет заказчика"
          options={OPTIONS_MOCK['Приоритет заказчика']}
          radioButtonProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={values['Способ проведения'] === 'Электронный аукцион'}
          ref={createFieldRef('Организатор')}
          control={tenderForm.control}
          path="Организатор"
          edit={isFieldEditable('Организатор', editableSettings?.['Организатор'])}
          onFieldSelect={() => selectField('Организатор')}
          name="Организатор"
          options={OPTIONS_MOCK['Организатор']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Объект торгов')}
          control={tenderForm.control}
          path="Объект торгов"
          edit={isFieldEditable('Объект торгов', editableSettings?.['Объект торгов'])}
          onFieldSelect={() => selectField('Объект торгов')}
          name="Объект торгов"
          options={OPTIONS_MOCK['Объект торгов']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('Начальная цена')}
          control={tenderForm.control}
          path="Начальная цена"
          edit={isFieldEditable('Начальная цена', editableSettings?.['Начальная цена'])}
          onFieldSelect={() => selectField('Начальная цена')}
          name="Начальная цена"
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const reductionPercentage = calculateMargin({
                costPrice: values['Итоговая цена'],
                offerPrice: floatValue,
              });

              const startMargin = calculateMargin({
                costPrice: values['Себестоимость'],
                offerPrice: floatValue,
              });

              if (typeof startMargin === 'number')
                tenderForm.setValue('Маржа начальная', startMargin);

              if (typeof reductionPercentage === 'number')
                tenderForm.setValue('Процент снижения', reductionPercentage);
            },
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Участвуем от')}
          control={tenderForm.control}
          path="Участвуем от"
          edit={isFieldEditable('Участвуем от', editableSettings?.['Участвуем от'])}
          onFieldSelect={() => selectField('Участвуем от')}
          name="Участвуем от"
          options={OPTIONS_MOCK['Участвуем от']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('Комментарий')}
          control={tenderForm.control}
          path="Комментарий"
          edit={isFieldEditable('Комментарий', editableSettings?.['Комментарий'])}
          onFieldSelect={() => selectField('Комментарий')}
          name="Комментарий"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Способ проведения')}
          control={tenderForm.control}
          path="Способ проведения"
          edit={isFieldEditable('Способ проведения', editableSettings?.['Способ проведения'])}
          onFieldSelect={() => selectField('Способ проведения')}
          name="Способ проведения"
          options={OPTIONS_MOCK['Способ проведения']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Ограничения, преимущества и доп требования')}
          control={tenderForm.control}
          path="Ограничения, преимущества и доп требования"
          edit={isFieldEditable(
            'Ограничения, преимущества и доп требования',
            editableSettings?.['Ограничения, преимущества и доп требования']
          )}
          onFieldSelect={() => selectField('Ограничения, преимущества и доп требования')}
          name="Ограничения, преимущества и доп требования"
          options={OPTIONS_MOCK['Ограничения, преимущества и доп требования']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('Обеспечение заявки')}
          control={tenderForm.control}
          path="Обеспечение заявки"
          edit={isFieldEditable('Обеспечение заявки', editableSettings?.['Обеспечение заявки'])}
          onFieldSelect={() => selectField('Обеспечение заявки')}
          name="Обеспечение заявки"
          numberFormatProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Date
          ref={createFieldRef('Окончание подачи')}
          control={tenderForm.control}
          path="Окончание подачи"
          edit={isFieldEditable('Окончание подачи', editableSettings?.['Окончание подачи'])}
          onFieldSelect={() => selectField('Окончание подачи')}
          name="Окончание подачи"
          format={DATE_FORMATS.fullDateWithTimeRounded}
          formatPreview={DATE_FORMATS.fullDateWithTimeRounded}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Date
          ref={createFieldRef('Проведение процедуры')}
          control={tenderForm.control}
          path="Проведение процедуры"
          edit={isFieldEditable('Проведение процедуры', editableSettings?.['Проведение процедуры'])}
          onFieldSelect={() => selectField('Проведение процедуры')}
          name="Проведение процедуры"
          format={DATE_FORMATS.fullDateWithTimeRounded}
          formatPreview={DATE_FORMATS.fullDateWithTimeRounded}
          datePickerProps={{
            placeholder: 'Дата и время',
            disabled,
            minValue: values['Окончание подачи']
              ? dateTime({ input: values['Окончание подачи'] })
              : undefined,
          }}
          required
        />
        <FieldWithPreview.Date
          ref={createFieldRef('Подведение итогов')}
          control={tenderForm.control}
          path="Подведение итогов"
          edit={isFieldEditable('Подведение итогов', editableSettings?.['Подведение итогов'])}
          onFieldSelect={() => selectField('Подведение итогов')}
          name="Подведение итогов"
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            placeholder: 'Дата',
            disabled,
            minValue: values['Проведение процедуры']
              ? dateTime({ input: values['Проведение процедуры'] })
              : undefined,
          }}
          required
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="Документация"
          name="Документация"
          edit={isFieldEditable('Документация', editableSettings?.['Документация'])}
          onFieldSelect={() => selectField('Документация')}
          ref={createFieldRef('Документация')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="Разъяснения"
          name="Разъяснения"
          edit={isFieldEditable('Разъяснения', editableSettings?.['Разъяснения'])}
          onFieldSelect={() => selectField('Разъяснения')}
          ref={createFieldRef('Разъяснения')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
        />
        <FieldWithPreview.MultiFile
          control={tenderForm.control}
          path="Протоколы"
          name="Протоколы"
          edit={isFieldEditable('Протоколы', editableSettings?.['Протоколы'])}
          onFieldSelect={() => selectField('Протоколы')}
          ref={createFieldRef('Протоколы')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('Регион поставки')}
          control={tenderForm.control}
          path="Регион поставки"
          edit={isFieldEditable('Регион поставки', editableSettings?.['Регион поставки'])}
          onFieldSelect={() => selectField('Регион поставки')}
          name="Регион поставки"
          options={OPTIONS_MOCK['Регион поставки']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('Адрес поставки')}
          control={tenderForm.control}
          path="Адрес поставки"
          edit={isFieldEditable('Адрес поставки', editableSettings?.['Адрес поставки'])}
          onFieldSelect={() => selectField('Адрес поставки')}
          name="Адрес поставки"
          textInputProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('Срок поставки')}
          control={tenderForm.control}
          path="Срок поставки"
          edit={isFieldEditable('Срок поставки', editableSettings?.['Срок поставки'])}
          onFieldSelect={() => selectField('Срок поставки')}
          name="Срок поставки"
          textInputProps={{
            disabled,
          }}
          required
        />
        <Divider />
        {/* Рабочие параметры */}
        <FieldWithPreview.Currency
          ref={createFieldRef('Себестоимость')}
          control={tenderForm.control}
          path="Себестоимость"
          edit={isFieldEditable('Себестоимость', editableSettings?.['Себестоимость'])}
          onFieldSelect={() => selectField('Себестоимость')}
          name="Себестоимость"
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const startMargin = calculateMargin({
                costPrice: floatValue,
                offerPrice: values['Начальная цена'],
              });
              const minimalMargin = calculateMargin({
                costPrice: floatValue,
                offerPrice: values['Минимальная цена'],
              });
              const finalMargin = calculateMargin({
                costPrice: floatValue,
                offerPrice: values['Итоговая цена'],
              });

              if (typeof startMargin === 'number')
                tenderForm.setValue('Маржа начальная', startMargin);
              if (typeof minimalMargin === 'number')
                tenderForm.setValue('Маржа минимальная', minimalMargin);
              if (typeof finalMargin === 'number')
                tenderForm.setValue('Маржа итоговая', finalMargin);
            },
          }}
          required
        />
        <FieldWithPreview.Percentage
          ref={createFieldRef('Маржа начальная')}
          control={tenderForm.control}
          path="Маржа начальная"
          edit={false}
          onFieldSelect={() => selectField('Маржа начальная')}
          name="Маржа начальная"
          numberFormatProps={{
            disabled,
          }}
        />
        <FieldWithPreview.File
          control={tenderForm.control}
          path="Файл расчета процедуры"
          name="Файл расчета процедуры"
          edit={isFieldEditable(
            'Файл расчета процедуры',
            editableSettings?.['Файл расчета процедуры']
          )}
          ref={createFieldRef('Файл расчета процедуры')}
          onFieldSelect={() => selectField('Файл расчета процедуры')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          required
        />
        <FieldWithPreview.File
          control={tenderForm.control}
          path="Файл заявки"
          name="Файл заявки"
          edit={isFieldEditable('Файл заявки', editableSettings?.['Файл заявки'])}
          ref={createFieldRef('Файл заявки')}
          onFieldSelect={() => selectField('Файл заявки')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          required
        />
        <FieldWithPreview.Wrapper
          show={values['Статус заявки'] === 'Подача' || values['Статус заявки'] === 'Отказ'}
          name="Согласовать с руководителем"
          required
        >
          <Button
            disabled={isApprovalButtonDisabled}
            {...approvalButtonProps}
          >
            Согласовать
          </Button>
        </FieldWithPreview.Wrapper>
        <FieldWithPreview.Currency
          ref={createFieldRef('Предлагаемая специалистом минимальная цена')}
          control={tenderForm.control}
          path="Предлагаемая специалистом минимальная цена"
          edit={isFieldEditable(
            'Предлагаемая специалистом минимальная цена',
            editableSettings?.['Предлагаемая специалистом минимальная цена']
          )}
          onFieldSelect={() => selectField('Предлагаемая специалистом минимальная цена')}
          name="Предлагаемая специалистом минимальная цена"
          numberFormatProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('Обоснование предложения минимальной цены')}
          control={tenderForm.control}
          path="Обоснование предложения минимальной цены"
          edit={isFieldEditable(
            'Обоснование предложения минимальной цены',
            editableSettings?.['Обоснование предложения минимальной цены']
          )}
          onFieldSelect={() => selectField('Обоснование предложения минимальной цены')}
          name="Обоснование предложения минимальной цены"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('Комментарий согласования')}
          control={tenderForm.control}
          path="Комментарий согласования"
          edit={isFieldEditable(
            'Комментарий согласования',
            editableSettings?.['Комментарий согласования']
          )}
          onFieldSelect={() => selectField('Комментарий согласования')}
          name="Комментарий согласования"
          wrapperProps={{
            textAlign: 'start',
          }}
          textAreaProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Currency
          ref={createFieldRef('Минимальная цена')}
          control={tenderForm.control}
          path="Минимальная цена"
          edit={isFieldEditable('Минимальная цена', editableSettings?.['Минимальная цена'])}
          onFieldSelect={() => selectField('Минимальная цена')}
          name="Минимальная цена"
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const minimalMargin = calculateMargin({
                costPrice: values['Себестоимость'],
                offerPrice: floatValue,
              });

              if (typeof minimalMargin === 'number')
                tenderForm.setValue('Маржа минимальная', minimalMargin);
            },
          }}
          required
        />
        <FieldWithPreview.Percentage
          ref={createFieldRef('Маржа минимальная')}
          control={tenderForm.control}
          path="Маржа минимальная"
          edit={false}
          onFieldSelect={() => selectField('Маржа минимальная')}
          name="Маржа минимальная"
          numberFormatProps={{
            disabled,
          }}
        />
        <Divider />
        {/* Итоговые параметры */}
        <FieldWithPreview.Currency
          show={
            values['Статус закупки'] === 'Состоялась' ||
            values['Статус заявки'] === 'Выигран' ||
            values['Статус заявки'] === 'Проигран'
          }
          ref={createFieldRef('Итоговая цена')}
          control={tenderForm.control}
          path="Итоговая цена"
          edit={isFieldEditable('Итоговая цена', editableSettings?.['Итоговая цена'])}
          onFieldSelect={() => selectField('Итоговая цена')}
          name="Итоговая цена"
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const reductionPercentage = calculateMargin({
                costPrice: floatValue,
                offerPrice: values['Начальная цена'],
              });

              const finalMargin = calculateMargin({
                costPrice: values['Себестоимость'],
                offerPrice: floatValue,
              });

              if (typeof finalMargin === 'number')
                tenderForm.setValue('Маржа итоговая', finalMargin);

              if (typeof reductionPercentage === 'number')
                tenderForm.setValue('Процент снижения', reductionPercentage);
            },
          }}
          required
        />
        <FieldWithPreview.Percentage
          ref={createFieldRef('Процент снижения')}
          control={tenderForm.control}
          path="Процент снижения"
          edit={false}
          onFieldSelect={() => selectField('Процент снижения')}
          name="Процент снижения"
          numberFormatProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Percentage
          ref={createFieldRef('Маржа итоговая')}
          control={tenderForm.control}
          path="Маржа итоговая"
          edit={false}
          onFieldSelect={() => selectField('Маржа итоговая')}
          name="Маржа итоговая"
          numberFormatProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          show={
            values['Статус закупки'] === 'Состоялась' ||
            values['Статус заявки'] === 'Выигран' ||
            values['Статус заявки'] === 'Проигран'
          }
          ref={createFieldRef('Победитель')}
          control={tenderForm.control}
          path="Победитель"
          edit={isFieldEditable('Победитель', editableSettings?.['Победитель'])}
          onFieldSelect={() => selectField('Победитель')}
          name="Победитель"
          options={OPTIONS_MOCK['Победитель']}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
          required
        />
        <FieldWithPreview.MultiSelect
          show={
            values['Статус закупки'] === 'Состоялась' ||
            values['Статус заявки'] === 'Выигран' ||
            values['Статус заявки'] === 'Проигран'
          }
          ref={createFieldRef('Участники (если имеются)')}
          control={tenderForm.control}
          path="Участники (если имеются)"
          edit={isFieldEditable(
            'Участники (если имеются)',
            editableSettings?.['Участники (если имеются)']
          )}
          onFieldSelect={() => selectField('Участники (если имеются)')}
          name="Участники (если имеются)"
          options={OPTIONS_MOCK['Участники (если имеются)']}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={tenderForm.handleSubmit(onValid, onInvalid)}
            disabled={disabled}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
}
