import { Text } from '@gravity-ui/uikit';

export function MessengerWithoutDialog() {
  return (
    <div className="bg-base-generic grow flex items-center justify-center">
      <Text
        variant="body-3"
        color="secondary"
      >
        Выберите диалог
      </Text>
    </div>
  );
}
