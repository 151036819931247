import { Text } from '@gravity-ui/uikit';
import { ComponentProps, ReactNode } from 'react';

import { cn } from '@shared/lib';

interface ChatSeparatorProps extends ComponentProps<'div'> {
  children: ReactNode;
}

export function ChatSeparator({ className, children, ...props }: ChatSeparatorProps) {
  return (
    <div
      className={cn('flex items-center gap-5', className)}
      {...props}
    >
      <div className="h-[1px] bg-base-generic-medium-hover grow" />
      <Text
        variant="body-short"
        color="complementary"
      >
        {children}
      </Text>
      <div className="h-[1px] bg-base-generic-medium-hover grow" />
    </div>
  );
}
