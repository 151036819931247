import { ChevronDown, Xmark } from '@gravity-ui/icons';
import { Button, Icon, Select, SelectProps, TextInput, useSelectOptions } from '@gravity-ui/uikit';
import { useEffect, useState } from 'react';

import './index.sass';

interface SelectWithSearchProps
  extends Omit<
    SelectProps,
    | 'renderFilter'
    | 'hasClear'
    | 'filterable'
    | 'options'
    | 'value'
    | 'onUpdate'
    | 'renderCounter'
    | 'renderPopup'
    | 'renderControl'
    | 'open'
    | 'onOpenChange'
  > {
  textInputClassname?: string;
  textInputWrapperClassname?: string;
  items: NonNullable<SelectProps['options']>;
  initialValue?: string[];
  onValueChange?: (value: string[]) => void;
  onConfirm?: (value: string[]) => void;
  onReset?: () => void;
}

const isEqualSimpleArrays = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) if (arr1[i] !== arr2[i]) return false;
  return true;
};

export function SelectWithSearch({
  textInputClassname,
  textInputWrapperClassname,
  items,
  initialValue,
  onValueChange,
  onConfirm,
  onReset,
  onBlur,
  ...selectProps
}: SelectWithSearchProps) {
  const [value, setValue] = useState<string[]>(initialValue || []);
  const [filter, setFilter] = useState<string>(selectProps.filter || '');
  const [open, setOpen] = useState(false);

  const onUpdate: SelectProps['onUpdate'] = value => {
    if (onValueChange) onValueChange(value);
    setValue(value);
  };

  const options = useSelectOptions({
    options: items,
    filter: selectProps.filter,
    filterable: true,
  });

  const blurHandle = () => {
    if (open) {
      if (!initialValue?.length) {
        setValue([]);
        return;
      }
      const hasChanges = !isEqualSimpleArrays(value, initialValue);

      if (hasChanges) {
        setValue(initialValue);
      }
    }
  };

  const renderFilter: SelectProps['renderFilter'] = ({
    value: filterValue,
    ref,
    onChange,
    onKeyDown,
  }) => {
    return (
      <div className={textInputWrapperClassname}>
        <TextInput
          controlRef={ref}
          controlProps={{ size: 1 }}
          value={filterValue}
          onUpdate={onChange}
          onKeyDown={onKeyDown}
          className={textInputClassname}
          placeholder={selectProps.filterPlaceholder}
        />
      </div>
    );
  };

  const renderPopup: SelectProps['renderPopup'] = ({ renderFilter, renderList }) => (
    <>
      {renderFilter()}
      {renderList()}
      <div className="w-full flex flex-row items-center gap-2 p-2 border-line-generic border-t border-solid">
        <Button
          view="normal"
          size="l"
          width="max"
          onClick={() => {
            onReset?.();
            setValue([]);
          }}
        >
          Сбросить
        </Button>
        <Button
          view="action"
          size="l"
          width="max"
          onClick={() => {
            onConfirm?.(value);
            setOpen(false);
          }}
        >
          Сохранить
        </Button>
      </div>
    </>
  );

  const renderControl: SelectProps['renderControl'] = ({ onKeyDown }) => (
    <div className="g-select-control g-select-control_size_l g-select-control_pin_round-round g-select-control_has-clear g-select-control_has-value">
      <button
        onClick={() => {
          blurHandle();
          setOpen(prev => !prev);
        }}
        onKeyDown={onKeyDown}
        className="g-select-control__button g-select-control__button_size_l g-select-control__button_view_normal g-select-control__button_pin_round-round"
      >
        {value.length > 0 ? (
          <span className="g-select-control__option-text">{value.join(', ')}</span>
        ) : (
          <span className="text-text-hint g-select-control__placeholder">Выберите</span>
        )}
      </button>
      {value.length > 0 && (
        <button
          onClick={() => {
            onReset?.();
            setValue([]);
          }}
          className="g-select-clear g-select-clear_size_l select-clear-button"
        >
          <Icon
            data={Xmark}
            className="text-text-secondary select-clear-icon"
          />
        </button>
      )}
      <Icon
        data={ChevronDown}
        className="text-text-secondary shrink-0"
        size={18}
      />
    </div>
  );

  useEffect(() => {
    setValue(initialValue || []);
  }, [initialValue]);

  return (
    <>
      <Select
        hasClear={true}
        renderFilter={renderFilter}
        filterable={true}
        filter={filter}
        onFilterChange={setFilter}
        options={options}
        value={value}
        onUpdate={onUpdate}
        renderPopup={renderPopup}
        renderControl={renderControl}
        open={open}
        onOpenChange={setOpen}
        onBlur={e => {
          onBlur?.(e);
          blurHandle();
        }}
        {...selectProps}
      />
    </>
  );
}
