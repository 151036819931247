import { DateTime } from '@gravity-ui/date-utils';

export function maxDateTime(first: DateTime, ...other: DateTime[]): DateTime {
  const dateTimeArray = [first, ...other];

  let result = first;

  for (let i = 0; i < dateTimeArray.length; i++) {
    const currentDateTime = dateTimeArray[i];

    if (currentDateTime && result.isBefore(currentDateTime)) {
      result = currentDateTime;
    }
  }

  return result;
}
