import {
  AuthLayout,
  ConfirmPincode,
  emailAuthAtom,
  hasAuthPinCode,
  Login,
  NewPassword,
  ResetPassword,
} from '@pages';
import { useAtomValue } from 'jotai';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { URLS } from '@shared/consts';

export function NonAuthRouter() {
  const emailValue = useAtomValue(emailAuthAtom);
  const hasPinCode = useAtomValue(hasAuthPinCode);

  const routes = (
    <>
      <Route
        path={URLS.auth.default}
        element={<AuthLayout />}
      >
        <Route
          index
          element={<Login />}
        />
        <Route
          path={URLS.auth.resetPassword}
          element={<ResetPassword />}
        />
        <Route
          path={URLS.auth.confirmPincode}
          element={
            emailValue ? (
              <ConfirmPincode />
            ) : (
              <Navigate
                to={URLS.auth.default}
                replace
              />
            )
          }
        />
        <Route
          path={URLS.auth.newPassword}
          element={
            emailValue && hasPinCode ? (
              <NewPassword />
            ) : (
              <Navigate
                to={URLS.auth.default}
                replace
              />
            )
          }
        />
      </Route>

      {/* Редирект при неизвестном пути */}
      <Route
        path="*"
        element={
          <Navigate
            to={URLS.auth.default}
            replace
          />
        }
      />
    </>
  );

  return <RouterProvider router={createBrowserRouter(createRoutesFromElements(routes))} />;
}
