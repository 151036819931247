import './index.sass';

import {
  ArrowUpArrowDown,
  BarsDescendingAlignLeftArrowDown,
  BarsDescendingAlignLeftArrowUp,
} from '@gravity-ui/icons';
import { Button, Icon, IconProps, Text } from '@gravity-ui/uikit';
import { ReactNode, useState } from 'react';

import { cn } from '@shared/lib';

export type SortType = 'DESC' | 'ASC' | '';

interface TableHoverColumnWrapper<ColumnName extends string> {
  sort: SortType;
  iconPosition: 'start' | 'end';
  onSortTypeChange: (sort: SortType, columnName: ColumnName | '') => void;
  content: string | ReactNode;
  columnName: ColumnName;
  currentColumnName: ColumnName | '';
}

const sortIcons: Record<SortType, IconProps['data']> = {
  DESC: BarsDescendingAlignLeftArrowDown,
  ASC: BarsDescendingAlignLeftArrowUp,
  '': ArrowUpArrowDown,
};

export function TableHoverColumnWrapper<ColumnName extends string>({
  sort,
  iconPosition = 'end',
  onSortTypeChange,
  content,
  columnName,
  currentColumnName,
}: TableHoverColumnWrapper<ColumnName>) {
  const [isFocus, setIsFocus] = useState(false);

  const renderContent = () => {
    if (typeof content === 'string') {
      return <Text variant="subheader-1">{content}</Text>;
    }
    return content;
  };

  const getNewSortValue = (): SortType => {
    if (columnName !== currentColumnName) return 'ASC';
    switch (sort) {
      case '':
        return 'ASC';
      case 'ASC':
        return 'DESC';
      case 'DESC':
        return '';
    }
  };

  return (
    <div
      className="flex flex-row items-center justify-between h-[40px] px-[10px] py-[11px] gap-3"
      onMouseEnter={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
    >
      {iconPosition === 'end' && renderContent()}

      <Button
        view="flat"
        onClick={() => onSortTypeChange(getNewSortValue(), columnName)}
        className={cn('custom-sort-icon-button', {
          'opacity-100': isFocus || (columnName === currentColumnName && sort !== ''),
          'opacity-0': !isFocus || columnName !== currentColumnName,
        })}
      >
        <Icon data={currentColumnName !== columnName ? ArrowUpArrowDown : sortIcons[sort]} />
      </Button>

      {iconPosition === 'start' && renderContent()}
    </div>
  );
}
