import { Check } from '@gravity-ui/icons';
import { Avatar, Icon, Text } from '@gravity-ui/uikit';

import { cn } from '@shared/lib';

export interface MessageItemProps {
  avatarImageURL: string;
  avatarName: string;
  name: string;
  sendedAt: string;
  related?: boolean;
  outcoming: boolean;
  status: 'delivered' | 'readed';
  variant?: 'danger' | 'normal' | 'alert' | 'good';
  children?: React.ReactNode;
}

export function MessageItem({
  avatarName,
  avatarImageURL,
  name,
  sendedAt,
  related = false,
  outcoming = false,
  status,
  variant,
  children,
}: MessageItemProps) {
  return (
    <div className="flex gap-3 items-center">
      <div className="min-w-[42px] self-start">
        {!related && (
          <Avatar
            size="l"
            className="shrink-0"
            text={avatarName}
            alt={avatarName}
            loading="lazy"
            imgUrl={avatarImageURL}
          />
        )}
      </div>
      <div
        className={cn('flex flex-col grow justify-center', {
          'outline outline-8 rounded-[8px]': !!variant,
          'bg-base-background outline-base-background': variant === 'normal',
          'bg-base-positive-light outline-base-positive-light': variant === 'good',
          'bg-base-danger-light outline-base-danger-light': variant === 'danger',
          'bg-base-utility-light outline-base-utility-light': variant === 'alert',
        })}
      >
        <div className="flex min-h-3 items-center">
          {!related && (
            <>
              <Text
                variant="subheader-1"
                color={outcoming ? 'info' : 'primary'}
                className="grow"
              >
                {name}
              </Text>
              <Text
                variant="caption-1"
                color="secondary"
              >
                {sendedAt}
              </Text>
            </>
          )}
        </div>
        <div className="flex gap-1">
          <div className="grow">{children}</div>
          {outcoming && status === 'delivered' && (
            <Icon
              data={Check}
              className={cn('text-text-info shrink-0', {
                'self-end': related,
                'self-start': !related,
              })}
            />
          )}
          {outcoming && status === 'readed' && (
            <Icon
              data={Check}
              className={cn('text-text-info shrink-0', {
                'self-end': related,
                'self-start': !related,
              })}
            />
          )}
        </div>
        <div className="flex justify-between"></div>
      </div>
    </div>
  );
}
