import { useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import { $okpd2sHooks } from '@entities';
import {
  CRMTable,
  CRMTableColumns,
  CRMTableData,
  Okpd2HandbookForm,
  Okpd2HandbookSchema,
  okpd2HandbookSchema,
  useTableColumnSort,
} from '@features';
import { $api, components } from '@shared/api';

import { OKPD2S_TABLE_COLUMNS_WITH_FILTER } from '../consts';
import { ColumnsOkpd2sHandbookTable, CustomHandbookTableDefaultProps } from '../model';
import { CustomerHandbookDrawerLayout } from './CustomHandbookDrawerLayout';
import { CustomHandbookTableLayout } from './CustomHandbookTableLayout';
import { HandbookFormFooter } from './HandbookFormFooter';

type Okpd2HandbookTableProps = CustomHandbookTableDefaultProps;

export function Okpd2HandbookTable({ creationOpen, onCloseCreation }: Okpd2HandbookTableProps) {
  const { add } = useToaster();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const getHandbookOkpd2InfiniteQuery = $okpd2sHooks.useGetAll(
    {
      query: debouncedSearchValue,
    },
    true
  );

  const flattedPages = getHandbookOkpd2InfiniteQuery.data?.pages.flat();

  const data: CRMTableData<ColumnsOkpd2sHandbookTable> = useMemo(
    () =>
      flattedPages?.map(okpd2 => ({
        Значение: okpd2?.value || '',
      })) ?? [],
    [getHandbookOkpd2InfiniteQuery.data]
  );

  const { columnsSort, changeColumnSort, updateColumnsSortValuesBySettings } =
    useTableColumnSort(data);

  const columnWithHover = (name: ColumnsOkpd2sHandbookTable[number]) => {
    const sortValue = columnsSort[name];
    if (typeof sortValue === 'undefined') {
      return name;
    }

    return (
      <CRMTable.HoverColumnWrapper
        sort={sortValue}
        iconPosition="end"
        content={name}
        columnName={name}
        onSortTypeChange={(newSort, colName) => changeColumnSort(newSort, colName)}
      />
    );
  };

  const columns: CRMTableColumns<ColumnsOkpd2sHandbookTable> = [
    {
      id: 'Значение',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Значение'),
    },
  ];

  const [editableHandbook, setEditableHandbook] = useState<
    components['schemas']['handbook.OKPD2'] | undefined
  >(undefined);

  const postHandbookOkpd2Mutation = $api.useMutation('post', '/handbook/okpd2s');

  const createOkpd2HandbookForm = useForm<Okpd2HandbookSchema>({
    resolver: yupResolver(okpd2HandbookSchema),
    mode: 'all',
    defaultValues: { value: '' },
  });

  const onValidCreateHandbook = async (values: Okpd2HandbookSchema) => {
    try {
      await postHandbookOkpd2Mutation.mutateAsync({ body: values });
      getHandbookOkpd2InfiniteQuery.refetch();
      createOkpd2HandbookForm.reset();
      add({
        name: 'okpd2-create-success',
        theme: 'success',
        title: `Справочник успешно создан`,
      });
      onCloseCreation();
    } catch {
      add({
        name: 'okpd2-create-failed',
        theme: 'danger',
        title: `Не удалось создать справочник`,
      });
    }
  };

  const putHandbookOkpd2IdMutation = $api.useMutation('put', '/handbook/okpd2s/{value}');

  const editOkpd2HandbookForm = useForm<Okpd2HandbookSchema>({
    resolver: yupResolver(okpd2HandbookSchema),
    values: editableHandbook?.value ? { value: editableHandbook.value } : undefined,
    mode: 'all',
  });

  const isOkpd2HandbookFormDirty = editOkpd2HandbookForm.formState.isDirty;

  const handleEditHandbook = () => {
    editOkpd2HandbookForm.handleSubmit(async values => {
      if (editableHandbook && isOkpd2HandbookFormDirty) {
        try {
          await putHandbookOkpd2IdMutation.mutateAsync({
            body: values,
            params: { path: { value: editableHandbook.value || '' } },
          });
          add({
            name: 'okpd2-create-success',
            theme: 'success',
            title: `Справочник успешно изменен`,
          });
          getHandbookOkpd2InfiniteQuery.refetch();
        } catch {
          add({
            name: 'okpd2-create-failed',
            theme: 'danger',
            title: `Не удалось изменить справочник`,
          });
        }
      }
    })();
    setEditableHandbook(undefined);
  };

  const deleteHandbookOkpd2IdMutation = $api.useMutation('delete', '/handbook/okpd2s/{value}');

  return (
    <>
      <CustomHandbookTableLayout
        columns={columns}
        data={data}
        tableColumnsWithFilter={OKPD2S_TABLE_COLUMNS_WITH_FILTER}
        searchValue={searchValue}
        onSearchValueUpdate={setSearchValue}
        updateColumnsSortValuesBySettings={updateColumnsSortValuesBySettings}
        onRowClick={(_, index) => setEditableHandbook(flattedPages?.[index] ?? undefined)}
        onIntersecting={(() => {
          let retry = false;

          return () => {
            if (
              getHandbookOkpd2InfiniteQuery.isLoading ||
              getHandbookOkpd2InfiniteQuery.isFetchingNextPage ||
              getHandbookOkpd2InfiniteQuery.fetchStatus === 'fetching' ||
              !getHandbookOkpd2InfiniteQuery.hasNextPage ||
              retry
            )
              return;

            getHandbookOkpd2InfiniteQuery.fetchNextPage();
            retry = true;
          };
        })()}
        onClickDelete={async index => {
          const item = flattedPages?.[index];

          if (item) {
            try {
              await deleteHandbookOkpd2IdMutation.mutateAsync({
                params: { path: { value: item.value || '' } },
              });

              getHandbookOkpd2InfiniteQuery.refetch();

              add({
                name: 'okpd2-delete-success',
                theme: 'success',
                title: 'Атрибут успешно удален',
              });
            } catch {
              add({
                name: 'okpd2-delete-failure',
                theme: 'danger',
                title: 'Не удалось удалить атрибут',
              });
            }
          }
        }}
      />
      <CustomerHandbookDrawerLayout
        open={creationOpen}
        onClose={onCloseCreation}
        title="Создать справочник «ОКПД-2»"
      >
        <Okpd2HandbookForm
          form={createOkpd2HandbookForm}
          onSubmit={createOkpd2HandbookForm.handleSubmit(onValidCreateHandbook)}
          disabled={postHandbookOkpd2Mutation.isPending}
          footer={
            <HandbookFormFooter
              loading={postHandbookOkpd2Mutation.isPending}
              onClose={onCloseCreation}
            />
          }
        />
      </CustomerHandbookDrawerLayout>

      <CustomerHandbookDrawerLayout
        open={!!editableHandbook}
        onClose={handleEditHandbook}
        title={editableHandbook?.value}
      >
        <Okpd2HandbookForm
          form={editOkpd2HandbookForm}
          disabled={putHandbookOkpd2IdMutation.isPending}
          initialEditable={false}
        />
      </CustomerHandbookDrawerLayout>
    </>
  );
}
