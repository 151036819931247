import { FormEventHandler, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { CompetitorsSchema } from '../model';

interface CompetitorsFormProps {
  form: UseFormReturn<CompetitorsSchema>;
  footer?: ReactNode;
  initialEditable?: boolean;
  disabled?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

export function CompetitorsForm({
  form,
  footer,
  initialEditable = true,
  disabled = false,
  onSubmit,
}: CompetitorsFormProps) {
  const { createFieldRef, isFieldEditable, selectField } = useFieldsWithPreview({
    form,
    initialEditable,
  });

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={onSubmit}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={form.control}
          path="name"
          name="Наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={form.control}
          path="full_name"
          name="Полное наименование"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={form.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
