/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookAgenciesInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/agencies'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/agencies', query],
    queryFn: async ({ pageParam }) => {
      // const { data } = await fetchClient.GET('/handbook/agencies', {
      const { data } = await mockClient.GET('/handbook/agencies', {
        params: { query: { ...query, page: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}

const createHandbookAgenciesMutation = () => $mock.useMutation('post', '/handbook/agencies');
// const createHandbookAgencies = (body: bodyRequestType<'post', '/handbook/agencies'>) =>
//   createHandbookAgenciesMutation.mutateAsync({ body: body });

const deleteByIdHandbookAgencies = (
  id: ParametersPathType<'delete', '/handbook/agencies/{id}'>['id']
) =>
  $mock
    .useMutation('delete', '/handbook/agencies/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookAgencies = (
  id: ParametersPathType<'get', '/handbook/agencies/{id}'>['id']
) => $mock.useQuery('get', '/handbook/agencies/{id}', { params: { path: { id: id } } });

const updateHandbookAgenciesMutation = () => $mock.useMutation('put', '/handbook/agencies/{id}');
// const updateHandbookAgencies = (
//   id: ParametersPathType<'put', '/handbook/agencies/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/agencies/{id}'>
// ) => updateHandbookAgenciesMutation.mutateAsync({ body: body, params: { path: { id: id } } });

export const $agenciesHooks = {
  useGetAll: useGetHandbookAgenciesInfiniteQuery,
  useGetById: useGetByIdHandbookAgencies,
  createMutation: createHandbookAgenciesMutation,
  updateByIdMutation: updateHandbookAgenciesMutation,
  deleteById: deleteByIdHandbookAgencies,
};
