import './index.sass';

import {
  DescriptorType,
  Loader,
  Table,
  withTableSelection,
  withTableSettings,
} from '@gravity-ui/uikit';
import { useEffect, useState } from 'react';

import { cn } from '@shared/lib';

import type { CRMTableColumns, CRMTableData, CRMTableSettings } from '../../model/types';

const TableColumnNames = (<ColumnNames extends string[]>() =>
  withTableSelection(
    withTableSettings<CRMTableData<ColumnNames>[number]>({ sortable: true })(
      Table<CRMTableData<ColumnNames>[number]>
    )
  ))();
type CRMTableProps<T extends string[]> = Omit<
  React.ComponentProps<typeof TableColumnNames>,
  | 'columns'
  | 'data'
  | 'defaultSettings'
  | 'settings'
  | 'showResetButton'
  | 'updateSettings'
  | 'onRowClick'
  | 'getRowDescriptor'
> & {
  onRowClick?: (item: CRMTableData<T>[number], index: number) => void;
  getRowDescriptor?: (item: CRMTableData<T>[number], index: number) => DescriptorType | undefined;
  columns: CRMTableColumns<T>;
  data: CRMTableData<T>;
  settings: CRMTableSettings<T>;
  hideScrollbar?: boolean;
  onIntersecting?: () => void;
  isLoading?: boolean;
  updateSettings: (data: CRMTableSettings<T>) => void;
} & (
    | {
        defaultSettings: CRMTableSettings<T>;
        showResetButton: boolean;
      }
    | {
        defaultSettings?: never;
        showResetButton?: boolean;
      }
  );

function getRowsElements() {
  return document.querySelectorAll('.g-table__row.g-table__row_interactive');
}
export function CustomTable<ColumnNames extends string[]>({
  className,
  data,
  hideScrollbar,
  onIntersecting,
  isLoading,
  ...restProps
}: CRMTableProps<ColumnNames>) {
  const [rows, setRows] = useState<NodeListOf<Element>>(getRowsElements());
  const [lastRow, setLastRow] = useState<(typeof rows)[number] | undefined>(rows[rows.length - 1]);

  // после маунта компонента получаем все строки
  // useEffect(() => {
  //   const newRows = getRowsElements();
  //   setRows(() => newRows);
  //   setLastRow(() => newRows[newRows.length - 1]);
  // }, []);

  function handleObserver(entries: IntersectionObserverEntry[]) {
    if (onIntersecting)
      entries.forEach(entry => {
        if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
          onIntersecting();
          const newRows = getRowsElements();
          setRows(() => newRows);
          setLastRow(() => newRows[newRows.length - 1]);
        }
      });
  }

  useEffect(() => {
    if (data) {
      const newRows = getRowsElements();
      setRows(() => newRows);
      setLastRow(() => newRows[newRows.length - 1]);
    }
  }, [data, data.length, data[0]]);

  useEffect(() => {
    if (lastRow) {
      const observerTwo = new IntersectionObserver(handleObserver);
      observerTwo.observe(lastRow);

      return () => {
        observerTwo.disconnect();
      };
    }
  }, [lastRow]);

  return (
    <>
      {isLoading && (
        <Loader
          className="absolute bottom-0 left-[58%]"
          size="l"
        />
      )}
      <TableColumnNames
        className={cn('table-layout', className, {
          'hide-scrollbar': hideScrollbar,
          'mb-[50px]': isLoading,
        })}
        data={data}
        {...restProps}
      />
    </>
  );
}
