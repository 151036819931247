import { yupRu } from '@shared/config';

export const filterNumSchema = yupRu.object({
  from: yupRu
    .number()
    .transform((_, originalValue) => {
      if (originalValue === '') {
        return undefined; // Преобразование пустой строки в undefined
      }
      const parsed = parseFloat(originalValue);
      return isNaN(parsed) ? undefined : parsed;
    })
    .positive()
    .test({
      test: function (from) {
        const { to } = this.parent;
        if (typeof from !== 'number') return typeof to === 'number';
        return true;
      },
    }),
  to: yupRu
    .number()
    .transform((_, originalValue) => {
      if (originalValue === '') {
        return undefined; // Преобразование пустой строки в undefined
      }
      const parsed = parseFloat(originalValue);
      return isNaN(parsed) ? undefined : parsed;
    })
    .positive()
    .when('from', {
      is: (from: number) => typeof from === 'number',
      then: schema => schema.min(yupRu.ref('from')),
    })
    .test({
      test: function (to) {
        const { from } = this.parent;
        if (typeof to !== 'number') return typeof from === 'number';
        return true;
      },
    }),
});
