export const pluralize = (n: number, one: string, four: string, five: string) => {
  if (n % 10 === 1 && (n + 100) % 100 !== 11) {
    return one;
  }

  if (n % 10 !== 1 && n % 10 <= 4 && n % 10 !== 0 && !(n % 100 >= 10 && n % 100 < 20)) {
    return four;
  }

  return five;
};
