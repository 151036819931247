/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookCustomersInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/customers'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/customers', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/customers', {
        // const { data } = await mockClient.GET('/handbook/customers', {
        params: { query: { ...query, page: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}

const createHandbookCustomersMutation = () => $mock.useMutation('post', '/handbook/customers');
// const createHandbookCustomers = (body: bodyRequestType<'post', '/handbook/customers'>) =>
//   createHandbookCustomersMutation.mutateAsync({ body: body });

const deleteByIdHandbookCustomers = (
  id: ParametersPathType<'delete', '/handbook/customers/{id}'>['id']
) =>
  $mock
    .useMutation('delete', '/handbook/customers/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookCustomers = (
  id: ParametersPathType<'get', '/handbook/customers/{id}'>['id']
) => $mock.useQuery('get', '/handbook/customers/{id}', { params: { path: { id: id } } });

const updateHandbookCustomersMutation = () => $mock.useMutation('put', '/handbook/customers/{id}');
// const updateHandbookCustomers = (
//   id: ParametersPathType<'put', '/handbook/customers/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/customers/{id}'>
// ) => updateHandbookCustomersMutation.mutateAsync({ body: body, params: { path: { id: id } } });

export const $customersHooks = {
  useGetAll: useGetHandbookCustomersInfiniteQuery,
  useGetById: useGetByIdHandbookCustomers,
  createMutation: createHandbookCustomersMutation,
  updateByIdMutation: updateHandbookCustomersMutation,
  deleteById: deleteByIdHandbookCustomers,
};
