import { ReactNode } from 'react';

import { TenderForm, TenderFormProps } from '@features';

interface TenderLayoutProps {
  renderForm: (formComponent: ReactNode) => ReactNode;
  formProps: TenderFormProps;
}

export function TenderLayout({ renderForm, formProps }: TenderLayoutProps) {
  return <>{renderForm(<TenderForm {...formProps} />)}</>;
}
