import { components } from '@shared/api';
import { yupRu } from '@shared/config';

type CustomerInfo = components['schemas']['handbook.CustomerInfo'];

type ClientStatus = NonNullable<CustomerInfo['client_status']>;
type CustomerLevel = NonNullable<CustomerInfo['customer_level']>;
type CustomerType = NonNullable<CustomerInfo['customer_type']>;

export const customerSchema = yupRu.object({
  address: yupRu.string(),
  client_status: yupRu
    .string()
    .oneOf<ClientStatus>(['Ключевой', 'Новый', 'Разовый', 'Стандартный']),
  comment: yupRu.string().min(3).max(255).required(),
  contact_ids: yupRu.array(yupRu.number().required()),
  customer_level: yupRu
    .string()
    // @ts-expect-error TODO: Ошибка орфографическая в схеме на бэке
    .oneOf<CustomerLevel>(['Коммерческий', 'Муниципальный', 'Региональный', 'Федеральный']),
  customer_type: yupRu.string().oneOf<CustomerType>(['Клиент', 'Лид']),
  full_name: yupRu.string().min(3).max(255).required(),
  head_responsible_id: yupRu.number(),
  higher_agency_id: yupRu.number(),
  inn: yupRu
    .string()
    .min(10)
    .max(12)
    .test({
      test: value => {
        if (value === undefined) return false;
        try {
          return !!BigInt(value);
        } catch {
          return false;
        }
      },
      message: 'Должен состоять из цифр',
    })
    .test({
      test: value => typeof value === 'string' && (value.length === 10 || value.length === 12),
      message: 'ИНН состоит из 10 или 12 арабских цифр',
    })
    .required(),
  kpp: yupRu.string().min(9).max(9).required(),
  name: yupRu
    .string()
    .required()
    .max(255)
    .test({
      test: value => !value || value.length >= 2,
      message: 'Должен содержать как минимум 2 символа',
    }),
  payment_detail_id: yupRu.string(),
  priority: yupRu.number().min(0).max(5),
  responsible_id: yupRu.string(),
  unique_number: yupRu.number().max(4).required(),
});

export type CustomerSchema = yupRu.InferType<typeof customerSchema>;
