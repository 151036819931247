import { useParams } from 'react-router-dom';

import { CommercialOfferDrawer } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';

export function SearchRightSidebar() {
  const { navigateTo } = useNavigateTo();
  const { processName, processId } = useParams();

  const processIdNumber = isNaN(Number(processId)) ? undefined : Number(processId);

  return (
    <CommercialOfferDrawer
      type="edit"
      id={processIdNumber}
      open={processName === 'commercial-offer' && typeof processIdNumber === 'number'}
      onClose={() => navigateTo(URLS.search)}
    />
  );
}
