import { Text } from '@gravity-ui/uikit';

interface GlobalSearchListItemProps {
  title: string;
  description: string;
  date: string;
  location: string;
  onClick?: () => void;
}

export function GlobalSearchListItem({
  date,
  description,
  location,
  title,
  onClick,
}: GlobalSearchListItemProps) {
  return (
    <button
      className="grid grid-cols-2 py-2"
      onClick={onClick}
    >
      <div className="flex flex-col items-start">
        <Text
          variant="body-2"
          color="primary"
          className="truncate max-w-full"
          title={title}
        >
          {title}
        </Text>
        <Text
          variant="caption-2"
          color="secondary"
        >
          {description}
        </Text>
      </div>
      <div className="grow flex flex-col items-end shrink-0">
        <Text
          variant="caption-2"
          color="secondary"
        >
          {date}
        </Text>
        <Text
          variant="caption-2"
          color="secondary"
        >
          {location}
        </Text>
      </div>
    </button>
  );
}
