import { yupRu } from '@shared/config';

export const eTradingPlatformsSchema = yupRu.object({
  comment: yupRu
    .string()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    })
    .transform(value => (!value ? undefined : value)),
  link: yupRu
    .string()
    .url()
    .max(255)
    .test({
      test: value => !value || value.length >= 3,
      message: 'Должен содержать как минимум 3 символа',
    })
    .transform(value => (!value ? undefined : value)),
  name: yupRu.string().min(3).max(255).required(),
});

export type ETradingPlatformsSchema = yupRu.InferType<typeof eTradingPlatformsSchema>;
