import { useToaster } from '@gravity-ui/uikit';
import { useAtom } from 'jotai';

import { AuthForm, AuthFormProps } from '@features/auth-form';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';

import { usePostAuthSendResetMutation } from '../api';
import { emailAuthAtom, resetPasswordSchema } from '../model';

export const ResetPassword = () => {
  const { navigateTo } = useNavigateTo();
  const [emailValue, setEmailValue] = useAtom(emailAuthAtom);
  const postAuthSendResetMutation = usePostAuthSendResetMutation();
  const { add } = useToaster();

  const resetPasswordProps: AuthFormProps<typeof resetPasswordSchema> = {
    schema: resetPasswordSchema,
    defaultValues: {
      email: emailValue || '',
    },
    async onValid({ email }) {
      try {
        await postAuthSendResetMutation.mutateAsync({ body: { email } });
        setEmailValue(email);
        navigateTo(URLS.auth.confirmPincode);
      } catch {
        add({
          name: 'invalid-new-password',
          title: 'Ошибка при запросе на смену пароля',
          theme: 'danger',
        });
      }
    },
    title: 'Восстановление пароля',
    inputs: {
      email: {
        type: 'text',
        props: {
          placeholder: 'Почта',
          autoComplete: 'email',
        },
      },
    },
    buttons: {
      primaryButtonProps: {
        children: 'Сбросить пароль',
        loading: postAuthSendResetMutation.isPending,
      },
      secondButtonProps: {
        children: 'Вернуться',
        onClick: () => {
          navigateTo(URLS.auth.default);
        },
      },
    },
  };

  return <AuthForm {...resetPasswordProps} />;
};
