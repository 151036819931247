import { $api, $mock } from '@shared/api';

// tabs

export const useCommercialOfferGetTabsQuery = () => {
  return $api.useQuery('get', '/offer/tabs');
};
export const useCommercialOfferDeleteTabMutation = () => {
  return $api.useMutation('delete', '/offer/tabs/{name}');
};

export const useCommercialOfferCreateTabMutation = () => {
  return $api.useMutation('post', '/offer/tabs');
};

export const useCommercialOfferUpdateTabMutation = () => {
  return $api.useMutation('put', '/offer/tabs/{name}');
};

// table data

export const useCommercialOfferGetTableDataMutation = () => {
  return $mock.useMutation('post', '/offer/all');
};
