import { ColumnsFilters } from '@features';

import { ColumnsAgenciesHandbookTable } from '../model';

export const AGENCIES_TABLE_COLUMNS_WITH_FILTER: Record<
  ColumnsAgenciesHandbookTable[number],
  ColumnsFilters
> = {
  'Краткое наименование': 'search',
  ИНН: 'search',
  Комментарий: 'search',
};
