/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookEmployeesInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/employees'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/employees', query],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.GET('/handbook/employees', {
        // const { data } = await mockClient.GET('/handbook/employees', {
        params: { query: { ...query, page: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}

const deleteByIdHandbookEmployees = (
  email: ParametersPathType<'delete', '/handbook/employees/{email}'>['email']
) =>
  $mock
    .useMutation('delete', '/handbook/employees/{email}')
    .mutateAsync({ params: { path: { email: email } } });

const useGetByEmailHandbookEmployees = (
  email: ParametersPathType<'get', '/handbook/employees/{email}'>['email']
) => $mock.useQuery('get', '/handbook/employees/{email}', { params: { path: { email: email } } });

const updateHandbookEmployeesMutation = () =>
  $mock.useMutation('put', '/handbook/employees/{email}');
// const updateHandbookEmployees = (
//   email: ParametersPathType<'put', '/handbook/employees/{email}'>['email'],
//   body: bodyRequestType<'put', '/handbook/employees/{email}'>
// ) =>
//   updateHandbookEmployeesMutation.mutateAsync({ body: body, params: { path: { email: email } } });

export const $employeesHooks = {
  useGetAll: useGetHandbookEmployeesInfiniteQuery,
  useGetById: useGetByEmailHandbookEmployees,
  updateByIdMutation: updateHandbookEmployeesMutation,
  deleteById: deleteByIdHandbookEmployees,
};
