import { useQuery } from '@tanstack/react-query';

import { fetchClient } from '@shared/api';
import { FieldWithPreviewProps } from '@shared/ui';

export function useGetOrganizationsQuery(enabled: boolean = false) {
  return useQuery({
    queryKey: ['contacts-organizations'],
    queryFn: async () => {
      const [customers, suppliers, legalEntities] = await Promise.all([
        fetchClient.GET('/handbook/customers'),
        fetchClient.GET('/handbook/suppliers'),
        fetchClient.GET('/handbook/legal-entities'),
      ]);

      const options: FieldWithPreviewProps['Select']['options'] = [
        ...(customers.data?.list?.map(customer => ({
          value: `customer/${(customer.id as number).toString()}`, // TODO: убрать as, когда пофиксят схему
          content: customer.name,
        })) ?? []),
        ...(suppliers.data?.list?.map(supplier => ({
          value: `supplier/${(supplier.id as number).toString()}`, // TODO: убрать as, когда пофиксят схему
          content: supplier.name,
        })) ?? []),
        ...(legalEntities.data?.list?.map(legalEntities => ({
          value: `legalEntities/${(legalEntities.id as number).toString()}`, // TODO: убрать as, когда пофиксят схему
          content: legalEntities.name,
        })) ?? []),
      ];

      return options;
    },
    enabled,
  });
}
