import { useInfiniteQuery } from '@tanstack/react-query';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { bodyRequestType, fetchClient, mockClient } from '@shared/api';

export function useGetTendersInfiniteQuery(
  body: bodyRequestType<'post', '/tender/all'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/tender/all', body],
    queryFn: async ({ pageParam }) => {
      const { data } = await fetchClient.POST('/tender/all', {
        // const { data } = await mockClient.POST('/tender/all', {
        body: { ...body, offset: pageParam },
      });

      return data ?? [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage || lastPage.length === 0) {
        return;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}
