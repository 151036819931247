import { ColumnsFilters } from '@features';

import { ColumnsCompetitorsHandbookTable } from '../model';

export const COMPETITORS_TABLE_COLUMNS_WITH_FILTER: Record<
  ColumnsCompetitorsHandbookTable[number],
  ColumnsFilters
> = {
  Наименование: 'search',
  'Полное наименование': 'search',
  ИНН: 'search',
  Комментарий: 'search',
};
