import { Text } from '@gravity-ui/uikit';
import { Link } from 'react-router-dom';

export interface ProcessItemProps {
  link: string;
  dateAndNumber: string;
  name: string;
}

export function ProcessItem({ link, dateAndNumber, name }: ProcessItemProps) {
  return (
    <div className="grid grid-cols-[149px_auto] px-2 py-[11px]">
      <Link to={link}>
        <Text
          variant="body-1"
          color="warning"
        >
          {dateAndNumber}
        </Text>
      </Link>
      <Text
        variant="body-1"
        color="primary"
        className="truncate"
      >
        {name}
      </Text>
    </div>
  );
}
