import { Button } from '@gravity-ui/uikit';

import { RightSidebar } from '@shared/ui';

interface HandbookFormFooterProps {
  onClose?: () => void;
  loading?: boolean;
}

export function HandbookFormFooter({ loading, onClose }: HandbookFormFooterProps) {
  return (
    <RightSidebar.BottomPanel>
      <Button
        size="xl"
        view="normal"
        width="max"
        onClick={onClose}
        disabled={loading}
      >
        Отмена
      </Button>
      <Button
        size="xl"
        view="action"
        type="submit"
        width="max"
        loading={loading}
      >
        Создать
      </Button>
    </RightSidebar.BottomPanel>
  );
}
