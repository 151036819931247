import { DatePicker, DatePickerProps } from '@gravity-ui/date-components';

export function JumpToDatePicker(props: DatePickerProps) {
  return (
    <DatePicker
      size="l"
      hasClear
      format="DD/MM/YYYY HH:mm:ss"
      {...props}
    />
  );
}
