import {
  NotificationProps,
  Notifications,
  NotificationsPopupWrapper,
} from '@gravity-ui/components';
import { ArrowRightFromSquare, BellDot, CommentDot, Magnifier, Moon } from '@gravity-ui/icons';
import { ActionBar } from '@gravity-ui/navigation';
import { Box, Button, Icon, Loader, Overlay, Popup, Text, User } from '@gravity-ui/uikit';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

import { useQueryGetUser, userAtom } from '@entities';
import { GlobalSearchDialog } from '@features';
import { URLS } from '@shared/consts';
import { cn, useNavigateTo } from '@shared/lib';
import { changeTheme, tokenAtom } from '@shared/model';

import './Header.css';

export const Header = () => {
  const { navigateTo } = useNavigateTo();
  const setTheme = useSetAtom(changeTheme);
  const setToken = useSetAtom(tokenAtom);
  const setUser = useSetAtom(userAtom);

  const getUser = useQueryGetUser();

  useEffect(() => {
    if (getUser.data) setUser(getUser.data);
  }, [getUser.data]);

  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const [isActiveNotifications] = useState(false);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  const [isOpenMessages] = useState(false);

  const ref = useRef(null);

  const notifications: NotificationProps[] = [];

  async function Logout() {
    setToken(null);
    navigateTo('/auth');
  }

  return (
    <header>
      <ActionBar
        aria-label="Header"
        className="py-1 !h-auto"
      >
        <ActionBar.Section type="primary">
          <ActionBar.Group pull="right">
            {/* Поиск */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                onClick={() => setIsOpenSearch(!isOpenSearch)}
                ref={ref}
                view="flat"
                size="xl"
              >
                <Icon
                  data={Magnifier}
                  className="text-text-misc"
                  size="18"
                />
                <Text variant="caption-2">Поиск</Text>
              </Button>
              <GlobalSearchDialog
                open={isOpenSearch}
                onClose={() => setIsOpenSearch(false)}
              />
            </ActionBar.Item>

            <ActionBar.Separator />

            {/* Уведомления */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                onClick={() => setIsOpenNotifications(!isOpenNotifications)}
                ref={ref}
                size="xl"
                view="flat"
              >
                <Icon
                  data={BellDot}
                  className={cn('text-text-misc notification-dot', {
                    active: isActiveNotifications,
                  })}
                  size="18"
                />
              </Button>
              <Popup
                open={isOpenNotifications}
                anchorRef={ref}
              >
                <NotificationsPopupWrapper>
                  <Notifications
                    notifications={notifications}
                    emptyTitle={'Нет новых уведомлений'}
                  />
                </NotificationsPopupWrapper>
              </Popup>
            </ActionBar.Item>

            {/* Перейти на страницу мессенджера */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                onClick={() => navigateTo(URLS.messenger)}
                ref={ref}
                size="xl"
                view="flat"
              >
                <Icon
                  data={CommentDot}
                  className="text-text-misc"
                  size="18"
                />
              </Button>
              <Popup
                open={isOpenMessages}
                anchorRef={ref}
              >
                Чаты
              </Popup>
            </ActionBar.Item>

            <ActionBar.Separator />

            {/* Аватар / Профиль */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                title="Профиль"
                view="flat"
                className="h-full flex items-center justify-center px-0"
                onClick={() => navigateTo(URLS.profile)}
              >
                <Box
                  position="relative"
                  className="pl-2 pr-4"
                >
                  {getUser.data ? (
                    <User
                      avatar={{
                        title: getUser.data.name_initials,
                        alt: getUser.data.name_initials,
                        fallbackImgUrl: undefined,
                        loading: 'lazy',
                        imgUrl: getUser.data.photo || '',
                      }}
                      name={getUser.data.name_initials}
                      description={getUser.data.position}
                      size="s"
                    />
                  ) : (
                    <User
                      avatar={{
                        title: 'Пользователь',
                        alt: 'Пользователь',
                        fallbackImgUrl: undefined,
                        loading: 'lazy',
                      }}
                      name={'Пользователь'}
                      description={'Битуджи Тех'}
                      size="s"
                    />
                  )}
                  <Overlay
                    visible={getUser.isLoading || getUser.isError}
                    className="size-full"
                  >
                    <Loader />
                  </Overlay>
                </Box>
              </Button>
            </ActionBar.Item>

            <ActionBar.Separator />

            {/* Смена темы */}
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                title="Сменить тему"
                size="xl"
                view="flat"
                onClick={setTheme}
              >
                <Icon
                  data={Moon}
                  className="text-text-misc"
                  size="18"
                />
              </Button>
            </ActionBar.Item>
            <ActionBar.Separator />
            <ActionBar.Item className="h-full flex items-center justify-center">
              <Button
                title="Выход"
                onClick={() => Logout()}
                size="xl"
                view="flat"
              >
                <Icon
                  data={ArrowRightFromSquare}
                  className="text-text-misc"
                  size="18"
                />
              </Button>
            </ActionBar.Item>
          </ActionBar.Group>
        </ActionBar.Section>
      </ActionBar>
    </header>
  );
};
