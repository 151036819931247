import { ReactNode } from 'react';

import { AmountDialog } from './AmountDialog';
import { CustomTable } from './Table';
import { TableBottomPanel } from './TableBottomPanel';
import { TableFiltersDisplayList } from './TableFiltersDisplayList';
import { TableFiltersSidebar } from './TableFiltersSidebar';
import { TableHoverColumnWrapper } from './TableHoverColumnWrapper';
import { TableSearchAndActionsPanel } from './TableSearchAndActionsPanel';
import { TableSettingsSideBar } from './TableSettingsSidebar';
import { TableTabs } from './TableTabs';

interface CompoundChildrenCRMTable {
  children: ReactNode;
  // children: [
  //   ReactElement<typeof TableFiltersSidebar>,
  //   ReactElement<typeof TableSettingsSideBar>,
  //   ReactElement<typeof TableTabs>,
  //   ReactElement<typeof TableSearchAndActionsPanel>,
  //   ReactElement<typeof TableHoverColumnWrapper>,
  //   ReactElement<typeof CustomTable>,
  //   ReactElement<typeof TableBottomPanel>,
  // ];
  // BottomPanel?: typeof TableBottomPanel;
  // FiltersSidebar?: typeof TableFiltersSidebar;
  // SearchAndActionsPanel?: typeof TableSearchAndActionsPanel;
  // SettingsSideBar?: typeof TableSettingsSideBar;
  // Tabs?: typeof TableTabs;
  // Table: typeof CustomTable;
  // HoverColumnWrapper?: typeof TableHoverColumnWrapper;
}

function CRMTable({ children }: CompoundChildrenCRMTable) {
  return <>{children}</>;
}

CRMTable.Table = CustomTable;
CRMTable.BottomPanel = TableBottomPanel;
CRMTable.FiltersSidebar = TableFiltersSidebar;
CRMTable.SearchAndActionsPanel = TableSearchAndActionsPanel;
CRMTable.SettingsSideBar = TableSettingsSideBar;
CRMTable.Tabs = TableTabs;
CRMTable.HoverColumnWrapper = TableHoverColumnWrapper;
CRMTable.AmountDialog = AmountDialog;
CRMTable.TableFiltersDisplayList = TableFiltersDisplayList;

export { CRMTable };
