import { dateTime } from '@gravity-ui/date-utils';

import {
  CRMTableFilters,
  DateFilterValueType,
  FilterBooleanType,
  FilterValueType,
  NumericFilterValueType,
} from '@features';
import { bodyRequestType, bodyResponseType } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';

import { ColumnsCommercialOffersTable, frontKeyBackendValueFilters } from '../model';

type BooleanValue = FilterBooleanType['value'];

const checkNumeric = (min?: number, max?: number): NumericFilterValueType => {
  if (typeof min !== 'number' && typeof max !== 'number') {
    return;
  }
  //@ts-expect-error Неверная компиляция для условия
  return { from: min, to: max };
};

const checkDate = (min?: string, max?: string, isFull?: boolean): DateFilterValueType => {
  if (typeof min !== 'string' && typeof max !== 'string') {
    return;
  }
  const format = isFull ? DATE_FORMATS.fullDateWithTimeRounded : DATE_FORMATS.fullDate;
  if (min !== undefined) min = dateTime({ input: min }).format(format);
  if (max !== undefined) max = dateTime({ input: max }).format(format);

  //@ts-expect-error Неверная компиляция для условия
  return { from: min, to: max };
};

const checkBoolean = (bool?: string): BooleanValue => {
  if (bool) return bool as BooleanValue;
  return;
};

const displayBooleanFilterToBackendFilter = (filter: FilterValueType) => {
  if (filter.type !== 'boolean' || !filter.value) {
    return '';
  }
  return filter.value;
};

const displayDateToISOSString = (date?: string, withTime?: boolean) => {
  if (!date) return;
  if (!withTime) {
    const [day, month, year] = date.split('.');
    if (!day || !month || !year) {
      return;
    }
    return new Date(Number(year), Number(month) - 1, Number(day)).toISOString();
  }

  // const [datePart, timePart] = date.split(', ');
  // if (!datePart) {
  //   return;
  // }
  // const [day, month, year] = datePart.split('.');
  // const formattedDateStr = `${year}-${month}-${day}T${timePart}:00.000Z`;

  return new Date(date).toISOString();
};

export const parseFiltersFromBackend = (
  backendFilters: bodyResponseType<'get', '/offer/tabs/{name}'>['filters']
): CRMTableFilters<ColumnsCommercialOffersTable> | undefined => {
  if (!backendFilters) return;
  return {
    'Дата, номер': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Дата, номер']['from']],
        backendFilters[frontKeyBackendValueFilters['Дата, номер']['to']]
      ),
    },
    Наименование: {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Наименование']],
    },
    Ответственный: {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Ответственный']],
    },
    Заказчик: { type: 'select', value: backendFilters[frontKeyBackendValueFilters['Заказчик']] },
    'Цель КП': { type: 'select', value: backendFilters[frontKeyBackendValueFilters['Цель КП']] },
    'Статус КП': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Статус КП']],
    },
    'Дата перевода': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Дата перевода']['from']],
        backendFilters[frontKeyBackendValueFilters['Дата перевода']['to']]
      ),
    },
    'Регион поставки': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Регион поставки']],
    },
    'Адрес поставки': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Адрес поставки']],
    },
    'Срок поставки': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Срок поставки']],
    },
    Основание: { type: 'select', value: backendFilters[frontKeyBackendValueFilters['Основание']] },
    'Как получен запрос': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Как получен запрос']],
    },
    'Ссылка в ЕИС': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Ссылка в ЕИС']]),
    },
    'Окончание подачи': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Окончание подачи']['from']],
        backendFilters[frontKeyBackendValueFilters['Окончание подачи']['to']],
        true
      ),
    },
    'Срок проведения закупки с': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Срок проведения закупки с']['from']],
        backendFilters[frontKeyBackendValueFilters['Срок проведения закупки с']['to']]
      ),
    },
    'Срок проведения закупки по': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Срок проведения закупки по']['from']],
        backendFilters[frontKeyBackendValueFilters['Срок проведения закупки по']['to']]
      ),
    },
    'Документы запроса': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Документы запроса']]),
    },
    'Объект закупки': {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Объект закупки']],
    },
    Комментарий: {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Комментарий']],
    },
    'Файл расчета': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Файл расчета']]),
    },
    Себестоимость: {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Себестоимость']['from']],
        backendFilters[frontKeyBackendValueFilters['Себестоимость']['to']]
      ),
    },
    'Цена предложения': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Цена предложения']['from']],
        backendFilters[frontKeyBackendValueFilters['Цена предложения']['to']]
      ),
    },
    'Маржа, %': {
      type: 'margin',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Маржа, %']['from']],
        backendFilters[frontKeyBackendValueFilters['Маржа, %']['to']]
      ),
    },
    'Файл КП': {
      type: 'boolean',
      value: checkBoolean(backendFilters[frontKeyBackendValueFilters['Файл КП']]),
    },
    'Дата КП': {
      type: 'date',
      value: checkDate(
        backendFilters[frontKeyBackendValueFilters['Дата КП']['from']],
        backendFilters[frontKeyBackendValueFilters['Дата КП']['to']]
      ),
    },
    Поставщик: { type: 'select', value: backendFilters.provider_value },
    'Согласовать цену с руководителем': {
      type: 'boolean',
      value: checkBoolean(
        backendFilters[frontKeyBackendValueFilters['Согласовать цену с руководителем']]
      ),
    },
    'Предлагаемая специалистом цена': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Предлагаемая специалистом цена']['from']],
        backendFilters[frontKeyBackendValueFilters['Предлагаемая специалистом цена']['to']]
      ),
    },
    'Обоснование предложения цены': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Обоснование предложения цены']],
    },
    'Комментарий согласований': {
      type: 'search',
      value: backendFilters[frontKeyBackendValueFilters['Комментарий согласований']],
    },
    'Итоговая цена': {
      type: 'currency',
      value: checkNumeric(
        backendFilters[frontKeyBackendValueFilters['Итоговая цена']['from']],
        backendFilters[frontKeyBackendValueFilters['Итоговая цена']['to']]
      ),
    },
    Победитель: {
      type: 'select',
      value: backendFilters[frontKeyBackendValueFilters['Победитель']],
    },
  };
};

export const parseFiltersToBackend = (
  filters: CRMTableFilters<ColumnsCommercialOffersTable>
): bodyRequestType<'put', '/offer/tabs/{name}'>['filters'] => {
  return {
    approval_comment: filters['Комментарий согласований'].value as string | undefined,
    /** @description Основание закупки */
    basis_purchase: filters['Основание'].value as string[] | undefined,
    /** @description Ссылка на файл расчёта */
    calculation_file: displayBooleanFilterToBackendFilter(filters['Файл расчета']),
    /** @description Комментарий */
    comment: filters['Комментарий'].value as string | undefined,
    /** @description Заказчик */
    customer_value: filters['Заказчик'].value as string[] | undefined,
    /** @description Адрес поставки */
    delivery_address: filters['Адрес поставки'].value as string | undefined,
    /** @description Регион поставки */
    delivery_region: filters['Регион поставки'].value as string[] | undefined,
    /** @description Ссылки на Документы */
    documents: displayBooleanFilterToBackendFilter(filters['Документы запроса']),
    /** @description Ссылка в ЕИС */
    eis_link: displayBooleanFilterToBackendFilter(filters['Ссылка в ЕИС']),
    /** @description Согласование с руководителем */
    manager_approved: displayBooleanFilterToBackendFilter(
      filters['Согласовать цену с руководителем']
    ),
    max_cost_price: (filters['Себестоимость'].value as NumericFilterValueType)?.to,
    /** @description Дата создания КП MAX @gotags: swaggertype:"string" */
    max_created_at: displayDateToISOSString((filters['Дата КП'].value as DateFilterValueType)?.to),
    /** @description Срок поставки MAX @gotags: swaggertype:"string" */
    delivery_deadline: filters['Срок поставки'].value as string | undefined,
    max_document_date: displayDateToISOSString(
      (filters['Дата КП'].value as DateFilterValueType)?.to,
      true
    ),
    max_final_price: (filters['Итоговая цена'].value as NumericFilterValueType)?.to,
    max_margin: (filters['Маржа, %'].value as NumericFilterValueType)?.to,
    max_offer_price: (filters['Цена предложения'].value as NumericFilterValueType)?.to,
    /** @description Срок проведения закупки по MAX @gotags: swaggertype:"string" */
    max_producement_end_at: displayDateToISOSString(
      (filters['Срок проведения закупки по'].value as DateFilterValueType)?.to
    ),
    /** @description Срок проведения закупки с MAX @gotags: swaggertype:"string" */
    max_producement_start_at: displayDateToISOSString(
      (filters['Срок проведения закупки с'].value as DateFilterValueType | null)?.to
    ),
    max_proposed_price: (filters['Предлагаемая специалистом цена'].value as NumericFilterValueType)
      ?.to,
    /** @description Окончание подачи MAX @gotags: swaggertype:"string" */
    max_submission_deadline: displayDateToISOSString(
      (filters['Окончание подачи'].value as DateFilterValueType)?.to,
      true
    ),
    /** @description Дата перевода MAX @gotags: swaggertype:"string" */
    max_transfer_at: displayDateToISOSString(
      (filters['Дата перевода'].value as DateFilterValueType)?.to
    ),
    /** @description Себестоимость */
    min_cost_price: (filters['Себестоимость'].value as NumericFilterValueType)?.to,
    /** @description Дата создания КП MIN @gotags: swaggertype:"string" */
    min_created_at: displayDateToISOSString(
      (filters['Дата КП'].value as DateFilterValueType)?.from
    ),
    min_document_date: displayDateToISOSString(
      (filters['Дата КП'].value as DateFilterValueType)?.from,
      true
    ),
    /** @description Итоговая цена */
    min_final_price: (filters['Итоговая цена'].value as NumericFilterValueType)?.from,
    /** @description Маржинальность */
    min_margin: (filters['Маржа, %'].value as NumericFilterValueType)?.from,
    /** @description Цена предложения */
    min_offer_price: (filters['Цена предложения'].value as NumericFilterValueType)?.from,
    /** @description Срок проведения закупки по MIN @gotags: swaggertype:"string" */
    min_producement_end_at: displayDateToISOSString(
      (filters['Срок проведения закупки по'].value as DateFilterValueType | null)?.from
    ),
    /** @description Срок проведения закупки с MIN @gotags: swaggertype:"string" */
    min_producement_start_at: displayDateToISOSString(
      (filters['Срок проведения закупки с'].value as DateFilterValueType | null)?.from
    ),
    /** @description Предлагаемая специалистом цена */
    min_proposed_price: (filters['Предлагаемая специалистом цена'].value as NumericFilterValueType)
      ?.from,
    /** @description Окончание подачи MIN @gotags: swaggertype:"string" */
    min_submission_deadline: displayDateToISOSString(
      (filters['Окончание подачи'].value as DateFilterValueType)?.from,
      true
    ),
    /** @description Дата перевода MIN @gotags: swaggertype:"string" */
    min_transfer_at: displayDateToISOSString(
      (filters['Дата перевода'].value as DateFilterValueType)?.from
    ),
    /** @description Ссылка на файл КП */
    offer_file: displayBooleanFilterToBackendFilter(filters['Файл КП']),
    /** @description Обоснование предложения цены */
    price_justification: filters['Обоснование предложения цены'].value as string | undefined,
    /** @description Поставщик */
    provider_value: filters['Поставщик'].value as string[] | undefined,
    /** @description Объект закупки */
    purchase_object_value: filters['Объект закупки'].value as string[] | undefined,
    /** @description Цель КП */
    purpose: filters['Цель КП'].value as string[] | undefined,
    /** @description Ответственный */
    responsible_value: filters['Ответственный'].value as string[] | undefined,
    request_sources: filters['Как получен запрос'].value as string[] | undefined,
    /** @description Статус КП */
    status: filters['Статус КП'].value as string[] | undefined,
    /** @description Название КП */
    title: filters['Наименование'].value as string | undefined,
    /** @description Победитель */
    winners: filters['Победитель'].value as string[] | undefined,
  };
};
