import { EllipsisVertical } from '@gravity-ui/icons';
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit';

type SettingsButtonProps = Omit<ButtonProps, 'size' | 'view' | 'children'>;

export function SettingsButton(props: SettingsButtonProps) {
  return (
    <Button
      {...props}
      size="l"
      view="flat"
    >
      <Icon data={EllipsisVertical} />
    </Button>
  );
}
