import { Avatar, Text } from '@gravity-ui/uikit';

import { cn } from '@shared/lib';

export type DialogItemProps = {
  selected?: boolean;
  online?: boolean;
  name: string;
  avatarName: string;
  lastMessageText: string;
  lastMessageSendedAt: string;
} & (
  | {
      type?: 'person';
      projectName?: undefined;
      online?: boolean;
      avatarImageURL: string;
    }
  | {
      type: 'project';
      projectName: string;
      online?: undefined;
      avatarImageURL?: undefined;
    }
);

export function DialogItem({
  type = 'person',
  projectName,
  avatarName,
  avatarImageURL,
  selected = false,
  online = false,
  lastMessageSendedAt,
  lastMessageText,
  name,
}: DialogItemProps) {
  return (
    <div
      className={cn('flex gap-3', {
        'bg-base-info-light outline rounded-[1px] outline-8 outline-base-info-light': selected,
      })}
    >
      <Avatar
        size="l"
        className="shrink-0"
        view={type === 'project' ? 'outlined' : 'filled'}
        text={avatarName}
        alt={avatarName}
        loading="lazy"
        imgUrl={avatarImageURL}
      />
      <div className="flex flex-col grow justify-center overflow-hidden">
        <div className="flex gap-2 items-center justify-between">
          <Text
            color="primary"
            variant="subheader-1"
            className="truncate relative pr-2.5"
          >
            {type === 'person' && name}
            {type === 'project' && projectName}
            {online && (
              <div className="absolute w-1.5 h-1.5 top-0 right-0 rounded-full bg-text-danger" />
            )}
          </Text>
          <Text
            color="secondary"
            variant="caption-1"
            className="shrink-0"
          >
            {lastMessageSendedAt}
          </Text>
        </div>
        {type === 'project' && (
          <Text
            color="primary"
            variant="body-short"
            className="truncate"
          >
            {name}
          </Text>
        )}
        <Text
          color="secondary"
          variant="body-short"
          className="truncate"
        >
          {lastMessageText}
        </Text>
      </div>
    </div>
  );
}
