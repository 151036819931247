import { User } from '@entities';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const OPTIONS_MOCK = {
  customer_type: [
    { value: '1', content: 'Лид' },
    { value: '2', content: 'Клиент' },
  ],
  customer_level: [
    { value: '1', content: 'Федеральный' },
    { value: '2', content: 'Региональный' },
    { value: '3', content: 'Муниципальный' },
    { value: '4', content: 'Коммерческий' },
  ],
  higher_agency_id: [
    { value: '1', content: 'Ведомство 1' },
    { value: '2', content: 'Ведомство 2' },
  ],
  responsible_id: [
    {
      value: customStorage<User>().getItem(LOCAL_STORAGE.user, null)?.id,
      content: customStorage<User>().getItem(LOCAL_STORAGE.user, null)?.full_name,
    },
  ],
  head_responsible_id: [{ value: '1', content: 'Руководилов О.В.' }],
};
