import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { commercialOfferFormAtom } from '@features';
import { RightSidebar } from '@shared/ui';

import { CommercialOfferLayout } from './CommercialOfferLayout';

type CreateCommercialOfferProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateCommercialOffer({ open, onClose }: CreateCommercialOfferProps) {
  const [underApproval, setUnderApproval] = useState(false);

  const commercialOfferForm = useAtomValue(commercialOfferFormAtom);

  useEffect(() => {
    if (!open) setUnderApproval(false);
  }, [open]);

  return (
    <CommercialOfferLayout
      formProps={{
        disabled: underApproval,
        allowedStatuses: values => {
          if (values.purpose === 'Обоснование НМЦК' || values.purpose === 'Закупка ЕП') {
            return ['Подача'];
          }

          return [];
        },
        initialEditable: true,
        approvalButtonProps: {
          onClick: () => {
            commercialOfferForm?.handleSubmit(
              () => {
                // TODO: делаем запрос на создание нового КП
                // после создания редиректим в чат
                alert('КП создано и отправлено на согласование');
                setUnderApproval(true);
              },
              e => console.log(e)
            )();
          },
        },
        onValid: values => {
          console.log('@valid', values);
          onClose();
        },
        onInvalid: errors => console.log('@invalid', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-commercial-offer"
          open={open}
          onClose={onClose}
        >
          <RightSidebar.Header
            title="Создание коммерческого предложения"
            onClose={onClose}
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
