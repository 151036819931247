import { MouseEvent, useEffect, useRef, useState } from 'react';

import { CalendarCard, CalendarCardProps } from '@entities';
import { InfiniteScroll } from '@shared/ui';

interface InfiniteCalendarProps {
  entityType: CalendarCardProps['entityType'];
  selectedStatus?: CalendarCardProps['selectedStatus'];
}

export function InfiniteCalendar({ entityType, selectedStatus }: InfiniteCalendarProps) {
  const entities: CalendarCardProps['entities'] = {
    КП: new Array(20).fill({
      title: '№234 | ВЧ №55056 (ФГКУ)',
      entityType: 'КП',
      status: 'Выигран',
    }),
  };

  const ourRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slider = ourRef.current;
    if (slider) {
      slider.scrollLeft = slider.scrollWidth / 2 - 550;
      slider.scrollTop = 0;
    }
  }, [ourRef.current]);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const handleDragStart = (e: MouseEvent) => {
    if (!ourRef.current) return;
    const slider = ourRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = 'grabbing';
    slider.style.userSelect = 'none';
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    const slider = ourRef.current;
    if (!slider) return;
    document.body.style.cursor = 'default';
    slider.style.userSelect = 'auto';
  };
  const handleDrag = (e: MouseEvent) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    const newScrollLeft = mouseCoords.current.scrollLeft - walkX;
    const newScrollTop = mouseCoords.current.scrollTop - walkY;
    if (newScrollLeft >= 0) {
      slider.scrollLeft = newScrollLeft;
    }
    slider.scrollTop = newScrollTop;
  };

  const handleLeave = () => {
    if (isMouseDown) {
      handleDragEnd();
      return;
    }
  };

  const newInfItem = { title: 'Child', index: 0 };

  const [infItems, setInfItems] = useState([newInfItem]);
  const [loading, setLoading] = useState(false);

  async function timeoutLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }

  return (
    <div
      role="presentation"
      // className="bg-custom-text-warning size-full overflow-auto border-4 border-solid border-text-danger flex flex-row gap-10"
      className="flex flex-row size-full overflow-auto"
      ref={ourRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      onMouseLeave={handleLeave}
    >
      <InfiniteScroll
        horizontal
        rootRef={ourRef}
        loadAfter={() => {
          if (!loading) {
            timeoutLoading();
            const lastIndex = infItems[infItems.length - 1]!.index;
            setInfItems(prev => [
              ...prev,
              ...(new Array(7).fill(newInfItem) as (typeof newInfItem)[]).map((el, i) => ({
                title: el.title,
                index: lastIndex + i + 1,
              })),
            ]);
          }
        }}
        loadBefore={() => {
          if (!loading) {
            timeoutLoading();
            const firstIndex = infItems[0]!.index;
            setInfItems(prev => [
              ...(new Array(7).fill(newInfItem) as (typeof newInfItem)[]).map((el, i) => ({
                title: el.title,
                index: firstIndex - 7 + i,
              })),
              ...prev,
            ]);
          }
        }}
        hasAfter={true}
        hasBefore={true}
        rootMarginBegin="0px 400px"
        rootMarginEnd="0px 400px"
      >
        {infItems.map(item => {
          const itemDate = new Date();
          itemDate.setDate(itemDate.getDate() + item.index);
          return (
            <CalendarCard
              key={Math.random() + item.index}
              entities={entities}
              date={itemDate}
              entityType={entityType}
              selectedStatus={selectedStatus}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}
