import { ThemeProvider, ToasterComponent, ToasterProvider } from '@gravity-ui/uikit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAtomValue } from 'jotai';
import { FC } from 'react';

import { themeAtomLocalStorage } from '@shared/model';

const queryClient = new QueryClient();

interface IProviders {
  /** Content that will be wrapped by providers. */
  readonly children: JSX.Element;
}

export const Providers: FC<IProviders> = ({ children }) => {
  const theme = useAtomValue(themeAtomLocalStorage);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ToasterProvider>
          {children}
          <ToasterComponent />
        </ToasterProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
