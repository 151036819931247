import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '@app/App';

import './index.sass';
import '@gravity-ui/uikit/styles/fonts.css';
import '@gravity-ui/uikit/styles/styles.css';

async function enableMocking() {
  // if (!import.meta.env.DEV) {
  //   return;
  // }
  const { worker } = await import('../mock/browser.js');
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() =>
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
);
