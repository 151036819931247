import { ColumnsFilters } from '@features';

import { ColumnsETradingPlatformsHandbookTable } from '../model';

export const E_TRADING_PLATFORMS_TABLE_COLUMNS_WITH_FILTER: Record<
  ColumnsETradingPlatformsHandbookTable[number],
  ColumnsFilters
> = {
  Наименование: 'search',
  Ссылка: 'boolean',
  Комментарий: 'search',
};
