import { Funnel } from '@gravity-ui/icons';
import { Button, ButtonProps, Icon } from '@gravity-ui/uikit';

type FilterDialogsButtonProps = Omit<ButtonProps, 'size' | 'view' | 'children'>;

export function FilterDialogsButton(props: FilterDialogsButtonProps) {
  return (
    <Button
      {...props}
      size="l"
      view="normal"
    >
      <Icon data={Funnel} />
    </Button>
  );
}
