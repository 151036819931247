import { yupRu } from '@shared/config';

export const filterDateSchema = yupRu.object({
  from: yupRu.number().test({
    test: function (from) {
      const { to } = this.parent;
      if (typeof from !== 'number') return typeof to === 'number';
      return true;
    },
  }),
  to: yupRu
    .number()
    .when('from', {
      is: (from: number) => typeof from === 'number',
      then: schema => schema.min(yupRu.ref('from')),
    })
    .test({
      test: function (to) {
        const { from } = this.parent;
        if (typeof to !== 'number') return typeof from === 'number';
        return true;
      },
    }),
});
