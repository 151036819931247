import { dateTime } from '@gravity-ui/date-utils';
import { Divider, Text } from '@gravity-ui/uikit';
import { Fragment, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { DialogItem } from '@entities';
import { FilterDialogsButton, SearchDialogs, TabsWithScroll } from '@features';
import { URLS } from '@shared/consts';

import { DIALOGS_MOCK, TABS_MOCK } from '../model/mock'; // TODO: убрать моки

export function ChatSidebar() {
  const selectedChatId = useParams().id;

  const [activeTab, setActiveTab] = useState('all');
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const searchDialogs = useMemo(() => {
    return DIALOGS_MOCK.filter(
      ({ name, projectName }) =>
        name.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase()) ||
        projectName?.toLocaleLowerCase().includes(debouncedSearchValue.toLocaleLowerCase())
    );
  }, [debouncedSearchValue]);

  return (
    <div className="pt-8 w-[376px] flex flex-col gap-6 border-r border-base-generic">
      <Text
        variant="display-1"
        className="px-5"
      >
        Мессенджер
      </Text>
      <div className="flex flex-col gap-3">
        <TabsWithScroll
          activeTab={activeTab}
          onSelectTab={setActiveTab}
          items={TABS_MOCK}
          className="px-5"
        />
        <div className="flex px-5 gap-2">
          <SearchDialogs
            value={searchValue}
            onUpdate={setSearchValue}
          />
          <FilterDialogsButton />
        </div>
      </div>
      <div className="px-5 overflow-y-auto hide-scrollbar">
        <div className="flex flex-col gap-2 pt-2 pb-6">
          {searchDialogs.map(
            (
              {
                id,
                lastMessageTimestamp,
                avatarImageURL,
                avatarName,
                lastMessageText,
                name,
                online,
                projectName,
                type = 'person',
              },
              index
            ) => {
              const lastMessageDate = dateTime({ input: lastMessageTimestamp });
              const lastMessageSendedAt = lastMessageDate.isSame(Date.now(), 'day')
                ? lastMessageDate.format('HH:mm')
                : lastMessageDate.format('DD.MM.YY');

              return (
                <Fragment key={id}>
                  {!!index && (
                    <div className="py-1">
                      <Divider />
                    </div>
                  )}
                  <Link to={`${URLS.messenger}/${id}`}>
                    {type === 'person' && (
                      <DialogItem
                        avatarImageURL={avatarImageURL || ''}
                        avatarName={avatarName}
                        name={name}
                        lastMessageText={lastMessageText}
                        lastMessageSendedAt={lastMessageSendedAt}
                        selected={id === selectedChatId}
                        online={online}
                        type={type}
                      />
                    )}
                    {type === 'project' && (
                      <DialogItem
                        avatarName={avatarName}
                        name={name}
                        lastMessageText={lastMessageText}
                        lastMessageSendedAt={lastMessageSendedAt}
                        selected={id === selectedChatId}
                        type={type}
                        projectName={projectName!}
                      />
                    )}
                  </Link>
                </Fragment>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}
