import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { FieldWithPreview } from '@shared/ui';

import { DEFAULT_VALUES } from '../constants';
import { regionsSchema, RegionsSchema } from '../model';

interface RegionsFormProps {
  onValid?: SubmitHandler<RegionsSchema>;
  onInvalid?: SubmitErrorHandler<RegionsSchema>;
  footer?: ReactNode;
}

export function RegionsForm({ onValid = () => {}, onInvalid, footer }: RegionsFormProps) {
  const regionsForm = useForm<RegionsSchema>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(regionsSchema),
    mode: 'all',
  });

  // TODO: сделать, когда будет схема/дизайн
  // const {} = useFieldsWithPreview({ form: regionsForm, initialEditable: true });

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={regionsForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview></FieldWithPreview>
      {footer}
    </form>
  );
}
