import { objectKeysSafeToArray } from '@shared/lib';

import { ColumnsFilters, CRMTableFilters } from '../../model';

export const setInitFilters = <ColumnNames extends string[]>(
  columnsWithFilter: Record<ColumnNames[number], ColumnsFilters>
): CRMTableFilters<ColumnNames> => {
  return objectKeysSafeToArray(columnsWithFilter).reduce((acc, key) => {
    const type = columnsWithFilter[key];
    if (type === 'select') {
      acc[key] = { type, value: [] };
    } else {
      acc[key] = { type, value: undefined };
    }
    return acc;
  }, {} as CRMTableFilters<ColumnNames>);
};
