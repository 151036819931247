import { UseFormProps } from 'react-hook-form';

import { bodyResponseType } from '@shared/api';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

import { CommercialOfferSchema } from '../model';

const user = customStorage<null | bodyResponseType<'get', '/user/'>>().getItem(
  LOCAL_STORAGE.user,
  null
);
export const DEFAULT_VALUES: UseFormProps<CommercialOfferSchema>['defaultValues'] = {
  responsible_id: user?.id,
  documents: [],
  manager_approved: false,
};
