import * as yup from 'yup';

import { formatBytes } from '@shared/lib';

import { ru } from './ru-locale';

yup.setLocale(ru);

yup.addMethod<yup.MixedSchema<File | undefined>>(
  yup.mixed,
  'fileSizeLessThan',
  function (fileSizeLessThan: number) {
    return this.test({
      name: 'file-size',
      test: function (file) {
        if (file && file.size > fileSizeLessThan) {
          return this.createError({
            message: `Слишком большой вес файла «${file.name}», уменьшите его до ${formatBytes(fileSizeLessThan)}`,
          });
        }

        return true;
      },
    });
  }
);

export { yup as yupRu };
