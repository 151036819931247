import { Comments } from '@gravity-ui/icons';
import { Button, Icon, RadioButton, Text, useToaster } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

import {
  commercialOfferFormAtom,
  CommercialOfferSchema,
  getIsProducementEndAtExpired,
  getIsSubmissionDeadlineExpired,
} from '@features';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { CommercialOfferLayout } from './CommercialOfferLayout';
import { CommercialOfferRelations } from './CommercialOfferRelations';

const FIRST_OFFER_MOCK: CommercialOfferSchema & { under_approval?: boolean } = {
  title: '28.08.2024 / Минобороны России',
  responsible_id: '3',
  customer_id: '1',
  purpose: 'Обоснование НМЦК',
  status: 'Подача',
  submission_deadline: '2024-08-29T00:00:00.000Z',
  documents: [],
  delivery_region_id: '1',
  delivery_deadline: '242',
  delivery_address: '1',
  comment: '',
  calculation_file: new File(['2421'], 'Файл расчёта', { type: 'txt' }),
  cost_price: 22,
  offer_price: 22.448979591836736,
  margin: 2,
  offer_file: new File(['2421'], 'Файл КП', { type: 'txt' }),
  approval_comment: '222',
  manager_approved: false,
  under_approval: false,
};

type EditCommercialOfferProps = {
  open: boolean;
  id: string;
  onClose: () => void;
};

export function EditCommercialOffer({ id, open, onClose }: EditCommercialOfferProps) {
  console.log('@commercial-offer-id', id);

  const { navigateTo } = useNavigateTo();
  const { add } = useToaster();

  const [offer, setOffer] = useState(() => structuredClone(FIRST_OFFER_MOCK));

  const [title, setTitle] = useState<string | undefined>();

  const [activeTab, setActiveTab] = useState<'project' | 'relations'>('project');

  const commercialOfferForm = useAtomValue(commercialOfferFormAtom);

  const handleClose = () => {
    onClose();
    if (commercialOfferForm?.formState.isDirty) {
      commercialOfferForm?.handleSubmit(
        values => console.log('@onClose@valid', values),
        errors => console.log('@onClose@invalid', errors)
      )();
    }
  };

  return (
    <CommercialOfferLayout
      formProps={{
        hide: activeTab !== 'project',
        disabled:
          !!offer.under_approval ||
          (offer.status === 'Отказ' && offer.manager_approved) ||
          offer.status === 'Не подано' ||
          offer.status === 'Отмена' ||
          offer.status === 'Торги опубликованы' ||
          offer.status === 'Выигран' ||
          offer.status === 'Проигран' ||
          offer.status === 'Торги не отработаны',
        defaultValues: offer,
        values: offer,
        allowedStatuses: values => {
          if (!offer.status) {
            return ['Подача'];
          }

          if (offer.status === 'Подача') {
            const statuses: ('Подача' | 'Ожидание')[] = ['Подача'];

            if (offer.manager_approved) {
              statuses.push('Ожидание');
            }

            return statuses;
          }

          if (offer.status === 'Ожидание') {
            const statuses: (
              | 'Отказ'
              | 'Отмена'
              | 'Ожидание'
              | 'Не подано'
              | 'Торги не отработаны'
            )[] = ['Отказ', 'Отмена', 'Ожидание'];

            const isProducementEndAtExpired = getIsProducementEndAtExpired(
              values.producement_end_at
            );

            const isSubmissionDeadlineExpired = getIsSubmissionDeadlineExpired(
              values.submission_deadline
            );
            if (isSubmissionDeadlineExpired) statuses.push('Не подано');

            if (values.purpose === 'Закупка ЕП') {
              return ['Выигран', 'Проигран', ...statuses];
            }

            if (values.purpose === 'Обоснование НМЦК') {
              if (isProducementEndAtExpired) statuses.push('Торги не отработаны');
              return ['Торги опубликованы', ...statuses];
            }
          }

          if (values.purpose === 'Обоснование НМЦК') {
            return [
              'Торги опубликованы',
              'Торги не отработаны',
              'Отказ',
              'Отмена',
              'Подача',
              'Ожидание',
              'Не подано',
            ];
          }

          if (values.purpose === 'Закупка ЕП') {
            return ['Выигран', 'Проигран', 'Отказ', 'Отмена', 'Подача', 'Ожидание', 'Не подано'];
          }

          return [];
        },
        approvalButtonProps: {
          onClick: () =>
            commercialOfferForm?.handleSubmit(values => {
              // TODO: делаем запрос на создание нового КП
              // после создания редиректим в чат

              // делаем вид что мы сделал запрос и нам вернулся ответ
              setOffer({ ...values, under_approval: true });
              add({
                theme: 'success',
                name: 'offer-under-approval',
                title: 'КП отправлено на согласование',
              });

              setTimeout(() => {
                // делаем вид что с бэка пришел новый offer
                setOffer({ ...values, under_approval: false, manager_approved: true });
                add({
                  theme: 'success',
                  name: 'offer-approved',
                  title: 'Согласовано',
                });
              }, 3000);
            })(),
        },
        hideTitleField: true,
        onTileChange: setTitle,
        onValid: values => {
          console.log('@valid', values);
          if (values.status === 'Ожидание') {
            return setOffer({ ...values, manager_approved: false, under_approval: false });
          }

          if (values.status === 'Не подано') {
            add({
              name: 'send-commercial-offer-not-filed',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
            return setOffer({
              ...values,
              transfer_at: new Date().toISOString(),
              manager_approved: false,
              under_approval: false,
            });
          }

          if (values.status === 'Торги не отработаны') {
            add({
              name: 'send-commercial-offer-trades-not-processed',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
            return setOffer({
              ...values,
              transfer_at: new Date().toISOString(),
              manager_approved: false,
              under_approval: false,
            });
          }

          if (values.status === 'Отмена') {
            add({
              name: 'send-commercial-offer-canceled',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
            return setOffer({
              ...values,
              manager_approved: false,
              under_approval: false,
            });
          }

          if (values.status === 'Торги опубликованы') {
            add({
              name: 'commercial-offer-closed',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
            return setOffer({
              ...values,
              manager_approved: false,
              under_approval: false,
            });
          }

          if (values.status === 'Выигран') {
            add({
              name: 'commercial-offer-win',
              theme: 'success',
              title: 'Создана карточка Контракт',
            });
            return setOffer({
              ...values,
              manager_approved: false,
              under_approval: false,
            });
          }

          setOffer(values);
        },
        onInvalid: errors => console.log('@invalid', errors),
      }}
      renderForm={formComponent => (
        <RightSidebar
          id="sidebar-commercial-offer"
          onClose={handleClose}
          open={open}
        >
          <RightSidebar.Header
            title={title}
            onClose={handleClose}
          />
          <div className="px-8 pb-8 flex justify-between">
            <RadioButton
              size="l"
              onUpdate={setActiveTab}
              options={[
                { value: 'project', content: 'О проекте' },
                {
                  value: 'relations',
                  content: (
                    <span>
                      Связи
                      <Text
                        variant="body-1"
                        color="hint"
                        className="ml-1"
                      >
                        4
                      </Text>
                    </span>
                  ),
                },
              ]}
            />
            <Button
              size="l"
              onClick={() => navigateTo(URLS.messenger)}
            >
              <Icon data={Comments} />
              Чат
            </Button>
          </div>
          {formComponent}
          {activeTab === 'relations' && <CommercialOfferRelations />}
        </RightSidebar>
      )}
    />
  );
}
