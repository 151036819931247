import { FormEventHandler, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { useGetOrganizationsQuery } from '../api';
import { STATUS_OPTIONS, TYPE_OPTIONS } from '../consts';
import { ContactsSchema } from '../model';

interface ContactsFormProps {
  form: UseFormReturn<ContactsSchema>;
  footer?: ReactNode;
  initialEditable?: boolean;
  disabled?: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

export function ContactsForm({
  form,
  footer,
  initialEditable = true,
  disabled = false,
  onSubmit,
}: ContactsFormProps) {
  const { createFieldRef, isFieldEditable, selectField, resetSelectedField, selectedFieldPath } =
    useFieldsWithPreview({
      form,
      initialEditable,
    });

  const getOrganizationsQuery = useGetOrganizationsQuery(
    isFieldEditable('organization_id')(form.getFieldState('organization_id'))
  );

  return (
    <form
      className="flex flex-col overflow-hidden grow"
      onSubmit={onSubmit}
    >
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={form.control}
          path="full_name"
          name="ФИО"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          textInputProps={{ disabled }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('type')}
          control={form.control}
          path="type"
          name="Тип контактного лица"
          edit={isFieldEditable('type')}
          options={TYPE_OPTIONS}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('type')}
          selectProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('status')}
          control={form.control}
          path="status"
          name="Статус контактного лица"
          edit={isFieldEditable('status')}
          options={STATUS_OPTIONS}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('status')}
          selectProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('position')}
          control={form.control}
          path="position"
          name="Должность"
          edit={isFieldEditable('position')}
          onFieldSelect={() => selectField('position')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Phone
          ref={createFieldRef('phone')}
          control={form.control}
          path="phone"
          name="Телефон"
          edit={isFieldEditable('phone')}
          onFieldSelect={() => selectField('phone')}
          patternFormatProps={{ disabled }}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('email')}
          control={form.control}
          path="email"
          name="Эл. адрес"
          edit={isFieldEditable('email')}
          onFieldSelect={() => selectField('email')}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={form.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          textAreaProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('organization_id')}
          control={form.control}
          path="organization_id"
          name="Тип организации"
          edit={isFieldEditable('organization_id')}
          options={getOrganizationsQuery.data ?? []}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('organization_id')}
          selectProps={{
            disabled,
            onUpdate: ([value]) => {
              if (value) {
                const [type, id] = value.split('/');

                const idNumber = Number(id);

                if (!isNaN(idNumber) && type) {
                  form.setValue('organization_id', idNumber);
                  form.setValue('organization_type', type);
                }
              }
            },
          }}
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
