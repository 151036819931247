import { Button, RadioButton, RadioButtonOption, Text, TextInput } from '@gravity-ui/uikit';
import { useEffect, useMemo, useState } from 'react';

import { objectKeysSafeToArray } from '@shared/lib';
import { ExpandList, MyFoldableListItem, RightSidebar, SelectWithSearch } from '@shared/ui';

import { FilterBooleanType, FilterSearchType, FilterSelectType } from '../model';

type SidebarFilter = FilterBooleanType | FilterSelectType | FilterSearchType;

type CommonItemProps = Pick<
  MyFoldableListItem,
  | 'arrowPosition'
  | 'arrowSize'
  | 'buttonProps'
  | 'textProps'
  | 'arrowClassname'
  | 'className'
  | 'keepMounted'
>;

const conditionalOptions: RadioButtonOption[] = [
  { value: 'Да', content: 'Да' },
  { value: 'Нет', content: 'Нет' },
  { value: 'Не выбрано', content: 'Не выбрано' },
];

const commonItemProps: CommonItemProps = {
  arrowPosition: 'start',
  arrowSize: 16,
  buttonProps: {
    className: 'justify-start pl-2 w-full text-left',
    size: 'xl',
    pin: 'brick-brick',
  },
  textProps: {
    className: '!ml-0 ',
    variant: 'subheader-1',
  },
  arrowClassname: 'text-text-primary',
  className: 'shadow-outline-bottom',
  keepMounted: true,
};

interface TableFiltersSidebarProps<T extends string[]> {
  filters: Record<T[number], SidebarFilter>;
  onConfirm: (newFilterState: Record<T[number], SidebarFilter>) => void;
  open: boolean;
  onClose: () => void;
}

export function TableFiltersSidebar<T extends string[]>({
  filters,
  onConfirm,
  onClose,
  open,
}: TableFiltersSidebarProps<T>) {
  const [filtersState, setFiltersStates] = useState<Record<T[number], SidebarFilter>>(filters);

  const selectItems = useMemo<MyFoldableListItem[]>(() => {
    return objectKeysSafeToArray(filtersState).map(key => {
      const filter = filtersState[key];

      if (filter.type === 'select') {
        return {
          listItem: (
            <SelectWithSearch
              className="w-[286px] my-[6px] mr-auto ml-8 static"
              items={['content', 'content1', 'content2', 'content3'].map(o => ({
                value: o,
                content: o,
              }))}
              initialValue={filter.value}
              filterPlaceholder="Поиск"
              placeholder="Выберите"
              multiple
              size="l"
              popupClassName=" w-[286px]"
              textInputWrapperClassname="p-2 flex flex-col gap-1"
              onConfirm={value => {
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value } }));
              }}
              onReset={() => {
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value: [] } }));
              }}
            />
          ),
          ...commonItemProps,
          title: key,
          rightLabel: {
            children: filter.value?.length || undefined,
            className: 'absolute right-2 top-[12px]',
          },
        };
      }

      if (filter.type === 'search') {
        return {
          listItem: (
            <TextInput
              className="w-[286px] my-[6px] mr-auto ml-8"
              size="l"
              placeholder={key}
              value={filter.value}
              onUpdate={text =>
                setFiltersStates(prev => ({ ...prev, [key]: { type: filter.type, value: text } }))
              }
            />
          ),
          ...commonItemProps,
          title: key,
        };
      }

      let radioValue = null;
      switch (filter.value) {
        case 'false':
          radioValue = 'Нет';
          break;
        case 'true':
          radioValue = 'Да';
          break;
        default:
          radioValue = 'Не выбрано';
          break;
      }
      return {
        listItem: (
          <RadioButton
            className="w-[286px] my-[6px] mr-auto ml-8"
            value={radioValue}
            onUpdate={newValue => {
              let convertVal: FilterBooleanType['value'] = undefined;
              switch (newValue) {
                case 'Да':
                  convertVal = 'true';
                  break;
                case 'Нет':
                  convertVal = 'false';
                  break;
              }
              setFiltersStates(prev => ({
                ...prev,
                [key]: {
                  type: filter.type,
                  value: convertVal,
                },
              }));
            }}
            options={conditionalOptions}
          />
        ),
        ...commonItemProps,
        title: key,
      };
    });
  }, [filtersState]);

  const resetValues = () => {
    setFiltersStates(prev =>
      objectKeysSafeToArray(prev).reduce(
        (acc, fil) => {
          switch (prev[fil].type) {
            case 'select':
              acc[fil] = { type: 'select', value: [] };
              break;
            case 'search':
              acc[fil] = { type: 'search', value: '' };
              break;
            case 'boolean':
              acc[fil] = { type: 'boolean', value: undefined };
              break;
          }
          return acc;
        },
        {} as Record<T[number], FilterSelectType | FilterBooleanType | FilterSearchType>
      )
    );
  };

  useEffect(() => {
    setFiltersStates(filters);
  }, [filters]);

  return (
    <RightSidebar
      id="table-layout-filters"
      className="!w-[338px]"
      open={open}
      onClose={onClose}
    >
      <RightSidebar.Header
        className="pt-[26px] pb-[10px] pl-[32px] pr-[20px] items-center"
        title="Фильтры"
        titleProps={{
          variant: 'subheader-3',
        }}
        onClose={onClose}
      />

      <ExpandList
        items={selectItems}
        className="overflow-y-scroll w-full"
      />

      <RightSidebar.BottomPanel
        className="pt-[15px] px-[32px] pb-[28px] justify-end border-none"
        withoutShadow
      >
        <Button
          view="flat-secondary"
          size="l"
          onClick={() => resetValues()}
        >
          <Text>Сбросить</Text>
        </Button>
        <Button
          view="action"
          size="l"
          onClick={() => onConfirm(filtersState)}
        >
          <Text>Применить</Text>
        </Button>
      </RightSidebar.BottomPanel>
    </RightSidebar>
  );
}
