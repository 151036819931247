const hasOwn = {}.hasOwnProperty;

export function cn(...inputs: unknown[]) {
  let classes = '';

  for (let i = 0; i < inputs.length; i++) {
    const value = inputs[i];
    if (value) {
      classes &&= classes + ' ' + parseValue(value);
      classes ||= parseValue(value);
    }
  }

  return classes;
}

function parseValue(arg: unknown) {
  if (typeof arg === 'string') {
    return arg;
  }

  if (typeof arg !== 'object') {
    return '';
  }

  if (Array.isArray(arg)) {
    return cn(...arg);
  }

  if (
    arg?.toString !== Object.prototype.toString &&
    !arg?.toString.toString().includes('[native code]')
  ) {
    return arg!.toString();
  }

  let classes = '';

  for (const key in arg) {
    if (hasOwn.call(arg, key) && arg[key as keyof typeof arg]) {
      classes = appendClass(classes, key);
    }
  }

  return classes;
}

function appendClass(value: string, newClass: string) {
  if (!newClass) {
    return value;
  }

  return value ? value + ' ' + newClass : newClass;
}
