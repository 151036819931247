import { FieldPath } from 'react-hook-form';
import { ValidationError } from 'yup';

import {
  RequiredOperatingParametersSchema,
  requiredOperatingParametersSchema,
  TenderSchema,
} from '../model';

export function getRequiredOperatingParametersErrors(values: TenderSchema) {
  try {
    requiredOperatingParametersSchema.validateSync(values, {
      abortEarly: false,
    });
  } catch (errors) {
    if (errors instanceof ValidationError) {
      return errors.inner.map(error => ({
        path: error.path as FieldPath<RequiredOperatingParametersSchema>,
        type: error.type ?? 'validation',
        message: error.message,
      }));
    }
  }
}
