/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookRegionsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/regions'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/regions', query],
    queryFn: async ({ pageParam }) => {
      // const { data } = await fetchClient.GET('/handbook/regions', {
      const { data } = await mockClient.GET('/handbook/regions', {
        params: { query: { ...query, page: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}

const createHandbookRegionsMutation = () => $mock.useMutation('post', '/handbook/regions');
// const createHandbookRegions = (body: bodyRequestType<'post', '/handbook/regions'>) =>
//   createHandbookRegionsMutation.mutateAsync({ body: body });

const deleteByIdHandbookRegions = (
  id: ParametersPathType<'delete', '/handbook/regions/{id}'>['id']
) =>
  $mock
    .useMutation('delete', '/handbook/regions/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookRegions = (id: ParametersPathType<'get', '/handbook/regions/{id}'>['id']) =>
  $mock.useQuery('get', '/handbook/regions/{id}', { params: { path: { id: id } } });

const updateHandbookRegionsMutation = () => $mock.useMutation('put', '/handbook/regions/{id}');
// const updateHandbookRegions = (
//   id: ParametersPathType<'put', '/handbook/regions/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/regions/{id}'>
// ) => updateHandbookRegionsMutation.mutateAsync({ body: body, params: { path: { id: id } } });

export const $regionsHooks = {
  useGetAll: useGetHandbookRegionsInfiniteQuery,
  useGetById: useGetByIdHandbookRegions,
  createMutation: createHandbookRegionsMutation,
  updateByIdMutation: updateHandbookRegionsMutation,
  deleteById: deleteByIdHandbookRegions,
};
