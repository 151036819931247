export const OPTIONS_MOCK = {
  supplier_id: [
    { value: '1', content: 'Поставщик 1' },
    { value: '2', content: 'Поставщик 2' },
  ],
  okpd_2_value: [
    { value: '1', content: 'Контент 1' },
    { value: '2', content: 'Контент 2' },
  ],
};
