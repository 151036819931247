import { Middleware } from 'openapi-fetch';

import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const authorizationMiddleware: Middleware = {
  async onRequest({ request }) {
    const accessToken = customStorage<string | null>().getItem(LOCAL_STORAGE.token, null);
    if (!accessToken) {
      return request;
    }
    request.headers.append('Authorization', `Bearer ${accessToken}`);

    return request;
  },
};
