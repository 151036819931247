import { UseFormProps } from 'react-hook-form';

import { PurchaseObjectsSchema } from '../model';

export const DEFAULT_VALUES: UseFormProps<PurchaseObjectsSchema>['defaultValues'] = {
  comment: undefined,
  object: undefined,
  okpd_2_value: undefined,
  supplier_id: undefined,
};
