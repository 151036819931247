export interface FileMock {
  name: string;
  extension: string;
  size: number;
  date: string;
  location: string;
}

export interface ProjectMock {
  name: string;
  entity: string;
  date: string;
  location: string;
}

export interface PeopleMock {
  name: string;
  role: string;
  avatarImageURL: string;
  avatarName: string;
}

export const SEARCH_MOCK = {
  keywords: ['КП Орион 24 февраля', 'КП Госснаб №2401'],
  files: [
    {
      name: 'КП для Димитрова И.С.',
      extension: 'PDF, 24 мб',
      size: 25165824.01,
      date: '22.02.24',
      location: 'Процессы / КП / Орион / Гос поставки / ...',
    },
    {
      name: 'Процесс разработки КП',
      extension: 'Jpeg',
      size: 3355443.2,
      date: '22.02.24',
      location: 'Процессы / КП / Орион / Гос поставки / ...',
    },
  ],
  projects: [
    {
      name: 'ДЮСШ Московской области',
      entity: 'Коммерческое предложение',
      date: '22.02.24',
      location: 'Процессы / КП / Орион / ...',
    },
    {
      name: 'Срок поставки КП для школ Московской области гос',
      entity: 'Справочник',
      date: '22.02.24',
      location: 'Справочники / ...',
    },
    {
      name: 'ДЮСШ Московской области',
      entity: 'Коммерческое предложение',
      date: '22.02.24',
      location: 'Процессы / КП / Орион / ...',
    },
    {
      name: 'Срок поставки КП для школ Московской области гос',
      entity: 'Справочник',
      date: '22.02.24',
      location: 'Справочники / ...',
    },
  ],
  peoples: [
    {
      name: 'Кипелов О.В.',
      role: 'Старший менеджер',
      avatarImageURL: '/src/shared/assets/images/avatar-mock-image.png',
      avatarName: 'Кипелов Олег',
    },
    {
      name: 'Хорошева И.Д.',
      role: 'Старший менеджер',
      avatarImageURL: '',
      avatarName: 'Хорошева Ирина',
    },
    {
      name: 'Кипелов О.В.',
      role: 'Старший менеджер',
      avatarImageURL: '/src/shared/assets/images/avatar-mock-image.png',
      avatarName: 'Кипелов Олег',
    },
    {
      name: 'Хорошева И.Д.',
      role: 'Старший менеджер',
      avatarImageURL: '',
      avatarName: 'Хорошева Ирина',
    },
    {
      name: 'Кипелов О.В.',
      role: 'Старший менеджер',
      avatarImageURL: '/src/shared/assets/images/avatar-mock-image.png',
      avatarName: 'Кипелов Олег',
    },
    {
      name: 'Хорошева И.Д.',
      role: 'Старший менеджер',
      avatarImageURL: '',
      avatarName: 'Хорошева Ирина',
    },
  ],
};
