import {
  CommercialOffers,
  CRMCalendar,
  CustomerHandbook,
  Handbooks,
  MessengerChatId,
  MessengerLayout,
  MessengerWithoutDialog,
  Search,
  SystemHandbook,
  Tenders,
} from '@pages';
import { useAtomValue } from 'jotai';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { Layout } from '@app/layout';
import { LOCAL_STORAGE, URLS } from '@shared/consts';
import { checkIsTokenExpired, customStorage } from '@shared/lib';
import { tokenAtom } from '@shared/model/store';

import { NonAuthRouter } from './NonAuthRouter';

// jwtDecode возвращает ошибку, если строка не является токеном
function validateAndDecodeToken(token: string | null) {
  if (!token) return null;
  try {
    return jwtDecode(token);
  } catch {
    customStorage<string | null>().removeItem(LOCAL_STORAGE.token); // ресетим невалидный токен
    return null;
  }
}

export const AppRouter = () => {
  // Возвращаем роутинг для неавторизированного пользователя, тем самым реализовав user guard
  const token = useAtomValue(tokenAtom);
  const [decodedToken, setDecodedToken] = useState<JwtPayload | null>(
    validateAndDecodeToken(token)
  );
  const [isNonAuth, setIsNonAuth] = useState(checkIsTokenExpired(decodedToken));

  // Сценарии обновления токена
  useEffect(() => {
    if (token && decodedToken) {
      setIsNonAuth(checkIsTokenExpired(decodedToken));
    }
    if (token && !decodedToken) {
      setDecodedToken(() => validateAndDecodeToken(token));
    }
    if (!token) {
      setDecodedToken(() => null);
      setIsNonAuth(true);
    }
  }, [token, decodedToken]);

  // Проверяем, просрочен ли токен, каждую минуту
  useEffect(() => {
    const interval = setInterval(() => {
      if (decodedToken && decodedToken?.exp) {
        const isExpired = checkIsTokenExpired(decodedToken);
        if (isExpired !== isNonAuth) {
          setIsNonAuth(isExpired);
        }
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [isNonAuth, decodedToken]);

  if (isNonAuth) return <NonAuthRouter />;

  const routes = (
    <Route
      element={<Layout />}
      path="/"
    >
      <Route path={URLS.processes.default}>
        <Route path={URLS.processes.commercialOffers}>
          <Route
            path={URLS.processes.commercialOffers}
            element={<CommercialOffers />}
          />
          <Route
            path={`${URLS.processes.commercialOffers}/:${URLS.processes.commercialOffersDynamicParams.id}`}
            element={<CommercialOffers />}
          />
        </Route>

        <Route path={URLS.processes.tenders}>
          <Route
            index
            element={<Tenders />}
          />
          <Route
            path={`${URLS.processes.tenders}/:${URLS.processes.tendersDynamicParams.id}`}
            element={<Tenders />}
          />
        </Route>

        <Route
          index
          element={
            <Navigate
              to={URLS.processes.commercialOffers}
              replace
            />
          }
        />
      </Route>

      <Route
        path={URLS.handbook.default}
        element={<Handbooks />}
      />

      <Route path={URLS.handbook.default}>
        <Route
          index
          element={<Handbooks />}
        />
        <Route
          path={`${URLS.handbook.system}/:${URLS.handbook.dynamicParams.handbookName}`}
          element={<SystemHandbook />}
        />
        <Route
          path={`${URLS.handbook.custom}/:${URLS.handbook.dynamicParams.handbookName}`}
          element={<CustomerHandbook />}
        />
        <Route
          path={`${URLS.handbook.custom}/:${URLS.handbook.dynamicParams.handbookName}/:${URLS.handbook.dynamicParams.attributeId}`}
          element={<CustomerHandbook />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={URLS.handbook.default}
              replace
            />
          }
        />
      </Route>

      <Route
        path={URLS.search.default}
        element={<Search />}
      />
      <Route
        path={`${URLS.search.default}/:${URLS.search.dynamicParams.processName}/:${URLS.search.dynamicParams.processId}`}
        element={<Search />}
      />

      <Route
        path={URLS.calendar}
        element={<CRMCalendar />}
      />

      <Route
        path={URLS.messenger}
        element={<MessengerLayout />}
      >
        <Route
          index
          element={<MessengerWithoutDialog />}
        />
        <Route
          path={`${URLS.messenger}/:id`}
          element={<MessengerChatId />}
        />

        <Route
          path="*"
          element={
            <Navigate
              to={URLS.messenger}
              replace
            />
          }
        />
      </Route>

      {/* Редирект при неизвестном пути */}
      <Route
        path="*"
        element={
          <Navigate
            to={URLS.processes.commercialOffers}
            replace
          />
        }
      />
      {/* Редирект с домашней страницы / */}
      <Route
        index
        element={
          <Navigate
            to={URLS.processes.commercialOffers}
            replace
          />
        }
      />
    </Route>
  );

  return <RouterProvider router={createBrowserRouter(createRoutesFromElements(routes))} />;
};
