import { Label } from '@gravity-ui/uikit';
import { useMemo, useState } from 'react';

import { CRMTable } from '@features';
import { CRMTableColumns, CRMTableData, useTableColumnSort } from '@features/table';

import { ColumnsHandbooksTable } from '../model';

type HandbooksTableProps = {
  data: CRMTableData<ColumnsHandbooksTable>;
  onRowClick: (item: CRMTableData<ColumnsHandbooksTable>[number], index: number) => void;
};

export const HandbooksTable = ({ data, onRowClick }: HandbooksTableProps) => {
  const columns: CRMTableColumns<ColumnsHandbooksTable> = [
    {
      id: 'Название',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      name: () => columnWithHover('Название'),
    },
    {
      id: 'Тип',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      template: item => (
        <Label
          size="xs"
          theme={item['Тип'] === 'Пользовательский' ? 'success' : 'normal'}
        >
          {item['Тип']}
        </Label>
      ),
      className: 'w-[144px]',
      name: () => columnWithHover('Тип'),
    },
    {
      id: 'Дата изменения',
      meta: { filter: true, selectedByDefault: true },
      placeholder: '',
      className: 'w-[144px]',
      name: () => columnWithHover('Дата изменения'),
    },
  ];

  // КОНЕЦ столбцы таблицы

  const { sortOrder, sortColumn, changeSortOrder } = useTableColumnSort(
    columns.map(column => ({ id: column.id, isSelected: true }))
  );

  const columnWithHover = (name: ColumnsHandbooksTable[number]) => {
    return (
      <CRMTable.HoverColumnWrapper
        sort={sortOrder}
        iconPosition="end"
        content={name}
        columnName={name}
        currentColumnName={sortColumn}
        onSortTypeChange={(newSort, colName) => {
          changeSortOrder(newSort, colName);
          // setIsUpdateConfig(true);
        }}
      />
    );
  };

  const [searchAttributesValue, setSearchAttributesValue] = useState('');

  const filteredData = useMemo(
    () =>
      searchAttributesValue
        ? data.filter(row => {
            const bool = Object.values(row).some(cell => {
              return cell.toLocaleLowerCase().includes(searchAttributesValue.toLowerCase());
            });
            return bool;
          })
        : data,
    [searchAttributesValue, data]
  );

  function searchAttributes(value: string) {
    setSearchAttributesValue(value);
  }

  return (
    <CRMTable>
      <CRMTable.SearchAndActionsPanel
        searchValue={searchAttributesValue}
        onUpdate={searchAttributes}
        placeholder="Поиск по названию"
      />

      <CRMTable.Table<ColumnsHandbooksTable>
        hideScrollbar
        className="table-without-checkbox"
        data={filteredData}
        columns={columns}
        settings={[]}
        updateSettings={() => {}}
        selectedIds={[]}
        onSelectionChange={() => {}}
        onRowClick={onRowClick}
      />
    </CRMTable>
  );
};
