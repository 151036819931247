import { UseFormProps } from 'react-hook-form';

import { User } from '@entities';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

import { CustomerSchema } from '../model';

export const DEFAULT_VALUES: UseFormProps<CustomerSchema>['defaultValues'] = {
  address: undefined,
  client_status: undefined,
  comment: undefined,
  contact_ids: undefined,
  customer_level: undefined,
  customer_type: undefined,
  full_name: undefined,
  head_responsible_id: undefined,
  higher_agency_id: undefined,
  inn: undefined,
  kpp: undefined,
  name: undefined,
  payment_detail_id: undefined,
  priority: undefined,
  responsible_id: customStorage<User>().getItem(LOCAL_STORAGE.user, null)?.id,
  unique_number: undefined,
};
