import { ArrowDownToSquare } from '@gravity-ui/icons';
import { Button, ButtonProps, Text } from '@gravity-ui/uikit';
import { ComponentRef, forwardRef } from 'react';

import { cn, formatBytes } from '@shared/lib';

interface DownloadFileButtonProps extends Omit<ButtonProps, 'view' | 'children'> {
  fileName: string;
  fileSize: number;
  fileExtension: string;
}

export const DownloadFileButton = forwardRef<ComponentRef<typeof Button>, DownloadFileButtonProps>(
  ({ fileExtension, fileName, fileSize, disabled, loading, style, className, ...props }, ref) => (
    <Button
      ref={ref}
      view="flat-info"
      disabled={disabled}
      loading={loading}
      size="xl"
      className={cn('px-4 py-2 bg-base-background', className, {
        'bg-base-background': !loading,
      })}
      style={{
        height: 'auto',
        ...(!loading && {
          '--g-color-base-simple-hover': 'var(--g-color-base-background)',
        }),
        ...style,
      }}
      {...props}
    >
      <div className="flex gap-4 items-center">
        <ArrowDownToSquare
          className={cn({
            'text-text-secondary': disabled,
            'text-text-info': !disabled,
          })}
        />
        <div className="flex flex-col">
          <Text
            variant="body-1"
            color={disabled ? 'secondary' : 'info'}
          >
            {fileName}
          </Text>
          <Text
            variant="caption-2"
            color="secondary"
          >
            {formatBytes(fileSize)}, {fileExtension}
          </Text>
        </div>
      </div>
    </Button>
  )
);
