import './index.sass';

import { Outlet } from 'react-router-dom';

import { Header, NavigationSidebar } from '@widgets/index';

export const Layout = () => {
  return (
    <NavigationSidebar>
      <Header />
      <main className="flex flex-col w-full grow overflow-hidden">
        <Outlet />
      </main>
    </NavigationSidebar>
  );
};
