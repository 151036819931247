import { Theme } from '@gravity-ui/uikit';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { LOCAL_STORAGE } from '@shared/consts';
import { atomWithWebStorage, customStorage } from '@shared/lib';

export const compactAtom = atomWithWebStorage('compact', false);
export const tokenAtom = atomWithStorage<null | string>(
  LOCAL_STORAGE.token,
  customStorage<string | null>().getItem(LOCAL_STORAGE.token, null),
  customStorage()
);
export const themeAtomLocalStorage = atomWithWebStorage<Theme>('theme', 'light');
export const changeTheme = atom(null, (get, set) => {
  set(themeAtomLocalStorage, get(themeAtomLocalStorage) === 'light' ? 'dark' : 'light');
});
