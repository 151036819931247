import './index.sass';

import { DatePicker } from '@gravity-ui/date-components';
import { dateTime, dateTimeParse } from '@gravity-ui/date-utils';
import { SquareChartColumn } from '@gravity-ui/icons';
import { Button, Divider, Icon, Popup, Text, TextInput } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useRef } from 'react';
import { Controller, FieldErrors, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { AnyObjectSchema, InferType, ObjectSchema } from 'yup';

import { yupRu } from '@shared/config';
import { DATE_FORMATS } from '@shared/consts';
import { cn } from '@shared/lib';

import {
  closePopupAtom,
  columnFilterPopupOpenAtom,
  filterDateSchema,
  filterNumSchema,
  FilterPopupDefaultProps,
  togglePopupAtom,
} from '../../model';

type ColumnFilterFormProps<
  Schema extends yupRu.ObjectSchema<yupRu.InferType<yupRu.AnyObjectSchema>>,
> = {
  schema: Schema;
  onValid: (data: yupRu.InferType<Schema>) => void;
  onInvalid?: (error: FieldErrors<yupRu.InferType<Schema>>) => void;
  children?: ReactNode;
};

function ColumnFilterForm<
  Schema extends yupRu.ObjectSchema<yupRu.InferType<yupRu.AnyObjectSchema>>,
>({ schema, onValid, onInvalid, children }: ColumnFilterFormProps<Schema>) {
  const filterForm = useForm<Schema>({
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...filterForm}>
      <div className="w-[320px] flex flex-col gap-4 px-5 pt-6 pb-4">{children}</div>
      <div className="p-2 grid grid-cols-2 gap-2 border-t border-line-generic">
        <Button
          size="l"
          view="normal"
          onClick={() => filterForm.reset()}
        >
          Сбросить
        </Button>
        <Button
          size="l"
          view="action"
          onClick={filterForm.handleSubmit(onValid, onInvalid)}
        >
          Применить
        </Button>
      </div>
    </FormProvider>
  );
}

interface FilterDateFieldsProps {
  dateFormat: string;
}

function FilterDateFields({ dateFormat = DATE_FORMATS.fullDate }: FilterDateFieldsProps) {
  const { control, watch } = useFormContext<yupRu.InferType<typeof filterDateSchema>>();

  const fromValue = watch('from');

  return (
    <>
      <Controller
        name="from"
        control={control}
        render={({ field: { value, onChange }, fieldState: { invalid } }) => (
          <DatePicker
            placeholder="От"
            value={value ? dateTime({ input: value }) : null}
            validationState={invalid ? 'invalid' : undefined}
            onUpdate={value => onChange(value?.valueOf())}
            size="l"
            view="normal"
            className="w-full"
            format={dateFormat}
            hasClear
            errorPlacement="inside"
          />
        )}
      />
      <Controller
        name="to"
        control={control}
        render={({ field: { value, onChange }, fieldState: { invalid } }) => (
          <DatePicker
            placeholder="До"
            value={value ? dateTime({ input: value }) : null}
            validationState={invalid ? 'invalid' : undefined}
            minValue={dateTimeParse(fromValue)}
            onUpdate={value => onChange(value?.valueOf())}
            size="l"
            view="normal"
            className="w-full"
            format={dateFormat}
            hasClear
            errorPlacement="inside"
          />
        )}
      />
    </>
  );
}

type CustomNumericFormatProps = Omit<
  NumericFormatProps,
  'value' | 'controlRef' | 'customInput' | 'onValueChange' | 'validationState'
>;

type FilterNumericFieldsProps = {
  fromProps?: CustomNumericFormatProps;
  toProps?: CustomNumericFormatProps;
};

function FilterNumericFields({ fromProps, toProps }: FilterNumericFieldsProps) {
  const { control } = useFormContext<yupRu.InferType<typeof filterNumSchema>>();

  return (
    <>
      <Controller
        control={control}
        name="from"
        render={({ field: { ref, value, onChange, ...field }, fieldState: { invalid } }) => (
          <NumericFormat
            {...field}
            {...fromProps}
            controlRef={ref}
            customInput={TextInput}
            label="от: "
            size="l"
            value={value === undefined ? '' : value}
            onValueChange={({ floatValue }) => onChange(floatValue || '')}
            validationState={invalid ? 'invalid' : undefined}
          />
        )}
      />
      <Controller
        control={control}
        name="to"
        render={({ field: { ref, value, onChange, ...field }, fieldState: { invalid } }) => {
          return (
            <NumericFormat
              {...field}
              {...toProps}
              controlRef={ref}
              customInput={TextInput}
              label="до: "
              size="l"
              value={value === undefined ? '' : value}
              onValueChange={({ floatValue }) => onChange(floatValue || '')}
              validationState={invalid ? 'invalid' : undefined}
            />
          );
        }}
      />
    </>
  );
}

function FilterPriorityFields() {
  const { control, watch } = useFormContext<yupRu.InferType<typeof filterNumSchema>>();

  const fromValue = watch('from');
  const checkFrom = fromValue || 0;

  return (
    <>
      <Controller
        control={control}
        name="from"
        render={({ field: { ref, value, onChange, ...field }, fieldState: { invalid } }) => (
          <NumericFormat
            {...field}
            controlRef={ref}
            customInput={TextInput}
            label="от 0: "
            size="l"
            allowNegative={false}
            decimalScale={0}
            value={value === undefined ? '' : value}
            error={invalid}
            validationState={invalid ? 'invalid' : undefined}
            onValueChange={({ floatValue, value }) => {
              if (value === '') {
                onChange(undefined); // Разрешаем стереть значение
              } else {
                onChange(floatValue);
              }
            }}
            isAllowed={values => {
              const { floatValue, value } = values;
              return (
                value === '' || (floatValue !== undefined && floatValue >= 0 && floatValue <= 5)
              );
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="to"
        render={({ field: { ref, value, onChange, ...field }, fieldState: { invalid } }) => {
          return (
            <NumericFormat
              {...field}
              controlRef={ref}
              customInput={TextInput}
              label="до 5: "
              size="l"
              validationState={invalid ? 'invalid' : undefined}
              allowNegative={false}
              decimalScale={0}
              value={value === undefined ? '' : value}
              onValueChange={({ floatValue, value }) => {
                if (value === '') {
                  onChange(undefined); // Разрешаем стереть значение
                } else {
                  onChange(floatValue);
                }
              }}
              isAllowed={values => {
                const { floatValue, value } = values;
                return (
                  value === '' ||
                  (floatValue !== undefined && floatValue >= checkFrom && floatValue <= 5)
                );
              }}
            />
          );
        }}
      />
    </>
  );
}

interface ColumnFilterPopupProps {
  cellName: string;
  children?: ReactNode;
  textAlign?: 'start' | 'end';
}

export function ColumnFilterPopup({
  cellName,
  textAlign = 'start',
  children,
}: ColumnFilterPopupProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const togglePopup = useSetAtom(togglePopupAtom);
  const closePopup = useSetAtom(closePopupAtom);
  const popupOpen = useAtomValue(columnFilterPopupOpenAtom);

  return (
    <div className="h-[40px] flex items-center">
      <Button
        ref={buttonRef}
        view="flat"
        width="max"
        className={cn('to-pay-cell-button h-[40px] absolute inset-0', {
          'justify-end': textAlign === 'end',
          'justify-start': textAlign === 'start',
        })}
        onClick={togglePopup}
      />
      <Text
        variant="subheader-1"
        color="primary"
      >
        {cellName}
      </Text>

      <Popup
        anchorRef={buttonRef}
        open={popupOpen}
        className="shadow-select min-w-[320px]"
        onClose={closePopup}
      >
        {children}
      </Popup>
    </div>
  );
}

function ColumnFilterFormWrapper<Schema extends ObjectSchema<InferType<AnyObjectSchema>>>({
  schema,
  onValid,
  onInvalid,
  children,
}: FilterPopupDefaultProps<Schema> & { children: ReactNode; schema: Schema }) {
  const closePopup = useSetAtom(closePopupAtom);

  return (
    <ColumnFilterForm
      schema={schema}
      onValid={data => {
        onValid(data);
        closePopup();
      }}
      onInvalid={onInvalid}
    >
      {children}
    </ColumnFilterForm>
  );
}

type ColumnFilterPopupDateProps = FilterPopupDefaultProps<typeof filterDateSchema> & {
  dateFormat?: 'fullDate' | 'fullDateWithTime';
};

ColumnFilterPopup.Date = ({
  dateFormat = 'fullDate',
  onValid,
  onInvalid,
}: ColumnFilterPopupDateProps) => {
  return (
    <ColumnFilterFormWrapper
      schema={filterDateSchema}
      onValid={onValid}
      onInvalid={onInvalid}
    >
      <FilterDateFields
        dateFormat={
          dateFormat === 'fullDate' ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
        }
      />
    </ColumnFilterFormWrapper>
  );
};

type ColumnFilterPopupCurrencyProps = FilterPopupDefaultProps<typeof filterNumSchema> & {
  currencyValue?: string;
};

ColumnFilterPopup.Currency = ({
  currencyValue,
  onValid,
  onInvalid,
}: ColumnFilterPopupCurrencyProps) => {
  return (
    <ColumnFilterFormWrapper
      schema={filterDateSchema}
      onValid={onValid}
      onInvalid={onInvalid}
    >
      <FilterNumericFields
        fromProps={{
          thousandSeparator: ' ',
          suffix: ' ₽',
          allowNegative: false,
        }}
        toProps={{
          thousandSeparator: ' ',
          suffix: ' ₽',
          allowNegative: false,
        }}
      />

      <div className="pt-1 relative w-full">
        <Divider className="absolute bottom-0 -inset-x-4" />
      </div>

      {/* TODO: пока скрыто, т.к. на бэке нет */}
      {currencyValue && (
        <div className="flex flex-col gap-1">
          <div className="flex items-start gap-4">
            <Icon data={SquareChartColumn} />
            <div className="flex flex-col">
              <Text
                variant="body-1"
                color="primary"
              >
                Общая сумма
              </Text>
              <Text
                variant="body-1"
                color="secondary"
              >
                {currencyValue}
              </Text>
            </div>
          </div>
        </div>
      )}
    </ColumnFilterFormWrapper>
  );
};

ColumnFilterPopup.Priority = ({
  onValid,
  onInvalid,
}: FilterPopupDefaultProps<typeof filterNumSchema>) => {
  return (
    <ColumnFilterFormWrapper
      schema={filterDateSchema}
      onValid={onValid}
      onInvalid={onInvalid}
    >
      <FilterPriorityFields />
    </ColumnFilterFormWrapper>
  );
};

ColumnFilterPopup.Margin = ({
  onValid,
  onInvalid,
}: FilterPopupDefaultProps<typeof filterNumSchema>) => {
  return (
    <ColumnFilterFormWrapper
      schema={filterDateSchema}
      onValid={onValid}
      onInvalid={onInvalid}
    >
      <FilterNumericFields
        fromProps={{
          suffix: ' %',
          allowNegative: true,
        }}
        toProps={{
          suffix: ' %',
          allowNegative: true,
        }}
      />
    </ColumnFilterFormWrapper>
  );
};

ColumnFilterPopup.Number = ({
  onValid,
  onInvalid,
}: FilterPopupDefaultProps<typeof filterNumSchema>) => {
  return (
    <ColumnFilterFormWrapper
      schema={filterDateSchema}
      onValid={onValid}
      onInvalid={onInvalid}
    >
      <FilterNumericFields />
    </ColumnFilterFormWrapper>
  );
};
