import { FieldPath } from 'react-hook-form';
import { ValidationError } from 'yup';

import {
  CommercialOfferSchema,
  OperatingParametersSchema,
  operatingParametersSchema,
} from '../model';

export function getOperatingParametersErrors(values: CommercialOfferSchema) {
  try {
    operatingParametersSchema.validateSync(values, {
      abortEarly: false,
    });
  } catch (errors) {
    if (errors instanceof ValidationError) {
      return errors.inner.map(error => ({
        path: error.path as FieldPath<OperatingParametersSchema>,
        type: error.type ?? 'validation',
        message: error.message,
      }));
    }
  }
}
