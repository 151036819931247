import { dateTime } from '@gravity-ui/date-utils';
import { Button, ButtonProps, Divider, useToaster } from '@gravity-ui/uikit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';

import {
  $customersHooks,
  $employeesHooks,
  $purchaseObjectsHooks,
  $regionsHooks,
  $suppliersHooks,
  useGetHandbookWinnersInfiniteQuery,
  useGetTendersInfiniteQuery,
  userAtom,
} from '@entities';
import { DATE_FORMATS, MAX_FILE_SIZE, NOT_ALLOWED_FILE_EXTENSIONS } from '@shared/consts';
import { maxDateTime, useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview, RightSidebar } from '@shared/ui';

import { DEFAULT_VALUES, ENUM_OPTIONS } from '../constants';
import {
  calculateMargin,
  calculateOfferPrice,
  generateTitle,
  getIsSubmissionDeadlineExpired,
  getOperatingParametersErrors,
} from '../lib';
import { commercialOfferFormAtom, commercialOfferSchema, CommercialOfferSchema } from '../model';

// type CommercialOfferFormSelectOptions = (Omit<
//   FieldWithPreviewProps['Select']['options'][number],
//   'content'
// > & {
//   content: string;
// })[];

export interface CommercialOfferFormProps {
  defaultValues?: UseFormProps<CommercialOfferSchema>['defaultValues'];
  values?: UseFormProps<CommercialOfferSchema>['values'];
  initialEditable?: boolean;
  hide?: boolean;
  disabled?: boolean;
  allowedStatuses: (
    values: CommercialOfferSchema
  ) => (
    | 'Торги опубликованы'
    | 'Торги не отработаны'
    | 'Выигран'
    | 'Проигран'
    | 'Отказ'
    | 'Отмена'
    | 'Подача'
    | 'Ожидание'
    | 'Не подано'
  )[];
  submitButtonText?: string;
  cancelButtonText?: string;
  onClickCancelButton?: () => void;
  approvalButtonProps?: Pick<ButtonProps, 'onClick' | 'disabled'>;
  hideTitleField?: boolean;
  hideBottomPanel?: boolean;
  onTileChange?: (title: string) => void;
  onValid?: SubmitHandler<CommercialOfferSchema>;
  onInvalid?: SubmitErrorHandler<CommercialOfferSchema>;
  onClickCreateCustomerHandbook: () => void;
  onClickCreateRegionsHandbook: () => void;
  onClickCreatePurchaseObjectsHandbook: () => void;
  onClickCreateLegalEntitiesHandbook: () => void;
  onClickCreateCompetitorsHandbook: () => void;
  onClickCreateTender: () => void;
}

export const CommercialOfferForm = ({
  defaultValues,
  values: externalValues,
  initialEditable = false,
  hide = false,
  disabled,
  approvalButtonProps,
  hideTitleField = false,
  hideBottomPanel = false,
  allowedStatuses: getAllowedStatuses,
  submitButtonText = 'Применить',
  cancelButtonText = 'Отмена',
  onClickCancelButton,
  onTileChange,
  onValid = () => {},
  onInvalid,
  onClickCreateCustomerHandbook,
  onClickCreateRegionsHandbook,
  onClickCreatePurchaseObjectsHandbook,
  onClickCreateCompetitorsHandbook,
  onClickCreateLegalEntitiesHandbook,
  onClickCreateTender,
}: CommercialOfferFormProps) => {
  const commercialOfferForm = useForm<CommercialOfferSchema>({
    resolver: yupResolver(commercialOfferSchema),
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
    mode: 'all',
    values: externalValues,
    resetOptions: {
      keepDefaultValues: false,
    },
  });

  const { createFieldRef, isFieldEditable, resetSelectedField, selectField, selectedFieldPath } =
    useFieldsWithPreview({
      form: commercialOfferForm,
      initialEditable,
      disabled,
    });

  const { add } = useToaster();

  const values = commercialOfferForm.watch();

  // atoms
  const currentUser = useAtomValue(userAtom);
  //

  // Queries
  const [filterEmployees, setFilterEmployees] = useState('');
  const getHandbookEmployeesInfiniteQuery = $employeesHooks.useGetAll(
    {
      query: filterEmployees,
    },
    selectedFieldPath === 'responsible_id'
  );
  const flattedEmployees = useMemo(
    () => getHandbookEmployeesInfiniteQuery.data?.pages.flat(),
    [getHandbookEmployeesInfiniteQuery.data]
  );

  const [filterCustomers, setFilterCustomers] = useState('');
  const getHandbookCustomersInfiniteQuery = $customersHooks.useGetAll(
    {
      query: filterCustomers,
    },
    selectedFieldPath === 'customer_id'
  );
  const flattedCustomers = useMemo(
    () => getHandbookCustomersInfiniteQuery.data?.pages.flat().filter(data => !!data),
    [getHandbookCustomersInfiniteQuery.data]
  );

  const [filterTenders, setFilterTenders] = useState('');
  const getTendersInfiniteQuery = useGetTendersInfiniteQuery(
    {
      search_value: filterTenders,
    },
    selectedFieldPath === 'tender_id'
  );
  const flattedTenders = useMemo(
    () => getTendersInfiniteQuery.data?.pages.flat(),
    [getTendersInfiniteQuery.data]
  );

  const [filterRegions, setFilterRegions] = useState('');
  const getHandbookRegionsInfiniteQuery = $regionsHooks.useGetAll(
    {
      query: filterRegions,
    },
    selectedFieldPath === 'delivery_region_id'
  );
  const flattedRegions = useMemo(
    () => getHandbookRegionsInfiniteQuery.data?.pages.flat(),
    [getHandbookRegionsInfiniteQuery.data]
  );

  const [filterPurchaseObjects, setFilterPurchaseObjects] = useState('');
  const getHandbookPurchaseObjectsInfiniteQuery = $purchaseObjectsHooks.useGetAll(
    {
      query: filterPurchaseObjects,
    },
    selectedFieldPath === 'purchase_object_id'
  );
  const flattedPurchaseObjects = useMemo(
    () => getHandbookPurchaseObjectsInfiniteQuery.data?.pages.flat(),
    [getHandbookPurchaseObjectsInfiniteQuery.data]
  );

  const [filterSuppliers, setFilterSuppliers] = useState('');
  const getHandbookSuppliersInfiniteQuery = $suppliersHooks.useGetAll(
    {
      query: filterSuppliers,
    },
    selectedFieldPath === 'provider_id'
  );
  const flattedSuppliers = useMemo(
    () => getHandbookSuppliersInfiniteQuery.data?.pages.flat(),
    [getHandbookSuppliersInfiniteQuery.data]
  );

  const [filterWinners, setFilterWinners] = useState('');
  const getHandbookWinnersInfiniteQuery = useGetHandbookWinnersInfiniteQuery(
    {
      query: filterWinners,
    },
    selectedFieldPath === 'winner_id'
  );
  const flattedWinners = useMemo(
    () => getHandbookWinnersInfiniteQuery.data?.pages.flat(),
    [getHandbookWinnersInfiniteQuery.data]
  );
  //

  const [filterStatus, setFilterStatus] = useState('');
  const [filterPurpose, setFilterPurpose] = useState('');
  const allowedStatuses = getAllowedStatuses(values);
  const allowedStatusOptions = ENUM_OPTIONS.status.filter(statusOption =>
    allowedStatuses.some(allowedStatus => allowedStatus === statusOption.value)
  );

  const [filterBasisPurchase, setFilterBasisPurchase] = useState('');

  const setCommercialOfferForm = useSetAtom(commercialOfferFormAtom);

  useEffect(() => {
    setCommercialOfferForm(commercialOfferForm);
  }, [commercialOfferForm]);

  useEffect(() => {
    const allowedStatuses = getAllowedStatuses(values);
    if (allowedStatuses.every(allowedStatus => allowedStatus !== values.status)) {
      commercialOfferForm.resetField('status', { defaultValue: '' });
    }
  }, [values.purpose, values.status]);

  useEffect(() => {
    if (values.customer_id && values.submission_deadline && flattedCustomers) {
      const customer = flattedCustomers.find(customer => customer.id === values.customer_id)?.name;
      const title =
        generateTitle({ customer, submissionDeadline: values.submission_deadline }) ?? '';
      commercialOfferForm.setValue('title', title);
      onTileChange?.(title);
    }
  }, [values.customer_id, values.submission_deadline]);

  const submissionDeadlineDate = values.submission_deadline
    ? dateTime({ input: values.submission_deadline })
    : null;

  const producementStartAtDate = values.producement_start_at
    ? dateTime({ input: values.producement_start_at })
    : null;

  useEffect(() => {
    const producementEndAtDate = values.producement_end_at
      ? dateTime({ input: values.producement_end_at })
      : null;

    if (
      producementEndAtDate &&
      (producementStartAtDate?.isAfter(producementEndAtDate) ||
        submissionDeadlineDate?.isAfter(producementEndAtDate))
    ) {
      commercialOfferForm.resetField('producement_end_at', {
        defaultValue: null as unknown as undefined,
      });
    }
  }, [values.submission_deadline, values.producement_start_at, values.producement_end_at]);

  useEffect(() => {
    if (values.status === 'Отказ') {
      const isSubmissionDeadlineExpired = getIsSubmissionDeadlineExpired(
        values.submission_deadline
      );

      if (isSubmissionDeadlineExpired) {
        commercialOfferForm.resetField('status');
        return add({
          name: 'commercial-offer-cannot-reject',
          theme: 'danger',
          title:
            'Невозможно отказаться после 16:00 (МСК) предшествующего дня окончания подачи заявки',
        });
      }
    }
  }, [values.status, values.submission_deadline]);

  const operatingParametersErrors = getOperatingParametersErrors(values);

  const [fileFieldsLoading, setFileFieldsLoading] = useState<Record<string, boolean>>({});

  const isFilesLoading = Object.values(fileFieldsLoading).some(
    fileFieldLoading => fileFieldLoading
  );

  if (hide) return null;

  return (
    <form
      className="flex flex-col overflow-hidden"
      onSubmit={commercialOfferForm.handleSubmit(onValid, onInvalid)}
    >
      <FieldWithPreview>
        {/* Работа с заказчиком */}
        <FieldWithPreview.Text
          control={commercialOfferForm.control}
          name="Наименование"
          show={!hideTitleField}
          path="title"
          edit={false}
          textInputProps={{ disabled }}
        />
        <FieldWithPreview.Select
          ref={createFieldRef('responsible_id')}
          control={commercialOfferForm.control}
          path="responsible_id"
          edit={isFieldEditable('responsible_id', { whenDirty: false })}
          onFieldSelect={() => selectField('responsible_id')}
          name="Ответственный"
          options={
            flattedEmployees?.map(employee => ({
              content: employee?.name_initials,
              value: employee.id,
            })) || []
          }
          defaultOptions={[
            {
              content: currentUser?.name_initials,
              value: currentUser?.id || '',
            },
          ]}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          filter={filterEmployees}
          onFilterChange={filter => setFilterEmployees(filter)}
          selectProps={{
            placeholder: 'Выбрать',
            disabled,
            loading:
              getHandbookEmployeesInfiniteQuery.isFetching ||
              getHandbookEmployeesInfiniteQuery.isFetchingNextPage ||
              getHandbookEmployeesInfiniteQuery.isLoading ||
              getHandbookEmployeesInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookEmployeesInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="customer_id"
          name="Заказчик"
          edit={isFieldEditable('customer_id')}
          ref={createFieldRef('customer_id')}
          options={
            flattedCustomers?.map(customer => ({
              content: customer.name,
              value: customer.id,
            })) || []
          }
          filter={filterCustomers}
          onFilterChange={filter => setFilterCustomers(filter)}
          onPlusClick={onClickCreateCustomerHandbook}
          onFieldSelect={() => selectField('customer_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookCustomersInfiniteQuery.isFetching ||
              getHandbookCustomersInfiniteQuery.isFetchingNextPage ||
              getHandbookCustomersInfiniteQuery.isLoading ||
              getHandbookCustomersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookCustomersInfiniteQuery.fetchNextPage();
            },
          }}
        />

        <Divider />

        {/* Начальные параметры */}
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="purpose"
          name="Цель КП"
          edit={isFieldEditable('purpose')}
          ref={createFieldRef('purpose')}
          options={ENUM_OPTIONS.purpose.filter(
            option => !filterPurpose || option.value.includes(filterPurpose)
          )}
          filter={filterPurpose}
          delayFilter={0}
          onFilterChange={filter => setFilterPurpose(filter)}
          onFieldSelect={() => selectField('purpose')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
          }}
          required
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="status"
          name="Статус КП"
          edit={isFieldEditable('status')}
          ref={createFieldRef('status')}
          options={allowedStatusOptions.filter(
            option => !filterStatus || option.value.includes(filterStatus)
          )}
          filter={filterStatus}
          delayFilter={0}
          onFilterChange={filter => setFilterStatus(filter)}
          onFieldSelect={() => selectField('status')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled || !allowedStatuses.length,
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="tender_id"
          show={values.status === 'Торги опубликованы'}
          name="Тендер"
          edit={isFieldEditable('tender_id')}
          ref={createFieldRef('tender_id')}
          options={
            flattedTenders?.map(tender => ({
              // TODO: на бэке снова id number и опциональное поле
              content: tender.title,
              value: (tender?.id || '').toString(),
            })) || []
          }
          filter={filterTenders}
          onFilterChange={filter => setFilterTenders(filter)}
          onFieldSelect={() => selectField('tender_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          onPlusClick={onClickCreateTender}
          selectProps={{
            placeholder: 'Выбрать',
            disabled: disabled,
            loading:
              getTendersInfiniteQuery.isFetching ||
              getTendersInfiniteQuery.isFetchingNextPage ||
              getTendersInfiniteQuery.isLoading ||
              getTendersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getTendersInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          show={
            externalValues?.status === 'Не подано' ||
            externalValues?.status === 'Торги не отработаны'
          }
          path="transfer_at"
          name="Дата перевода"
          edit={false}
          ref={createFieldRef('transfer_at')}
          onFieldSelect={() => selectField('transfer_at')}
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={DATE_FORMATS.fullDateWithTime}
          datePickerProps={{
            disabled: true,
            placeholder: 'Дата и время',
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="delivery_region_id"
          name="Регион поставки"
          edit={isFieldEditable('delivery_region_id')}
          ref={createFieldRef('delivery_region_id')}
          options={
            flattedRegions?.map(region => ({
              content: region.name,
              value: region.id,
            })) || []
          }
          filter={filterRegions}
          onFilterChange={filter => setFilterRegions(filter)}
          onPlusClick={onClickCreateRegionsHandbook}
          onFieldSelect={() => selectField('delivery_region_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookRegionsInfiniteQuery.isFetching ||
              getHandbookRegionsInfiniteQuery.isFetchingNextPage ||
              getHandbookRegionsInfiniteQuery.isLoading ||
              getHandbookRegionsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookRegionsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.Text
          control={commercialOfferForm.control}
          path="delivery_address"
          name="Адрес поставки"
          edit={isFieldEditable('delivery_address')}
          ref={createFieldRef('delivery_address')}
          onFieldSelect={() => selectField('delivery_address')}
          required
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Text
          control={commercialOfferForm.control}
          name="Срок поставки"
          path="delivery_deadline"
          edit={isFieldEditable('delivery_deadline')}
          ref={createFieldRef('delivery_deadline')}
          onFieldSelect={() => selectField('delivery_deadline')}
          required
          textInputProps={{
            disabled,
          }}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          show={values.purpose === 'Закупка ЕП' && values.status === 'Выигран'}
          path="basis_purchase"
          name="Основание"
          edit={isFieldEditable('basis_purchase')}
          ref={createFieldRef('basis_purchase')}
          options={ENUM_OPTIONS.basis_purchase.filter(
            option => !filterBasisPurchase || option.value.includes(filterBasisPurchase)
          )}
          filter={filterBasisPurchase}
          delayFilter={0}
          onFilterChange={filter => setFilterBasisPurchase(filter)}
          onFieldSelect={() => selectField('basis_purchase')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
          }}
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          path="submission_deadline"
          name="Окончание подачи"
          edit={isFieldEditable('submission_deadline')}
          ref={createFieldRef('submission_deadline')}
          onFieldSelect={() => selectField('submission_deadline')}
          format={DATE_FORMATS.fullDate}
          formatPreview={DATE_FORMATS.fullDate}
          datePickerProps={{
            disabled,
            placeholder: 'Дата и время',
          }}
          required
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          edit={isFieldEditable('producement_start_at')}
          name="Срок проведения закупки"
          path="producement_start_at"
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={`с ${DATE_FORMATS.fullDateWithTimeRounded}`}
          datePickerProps={{
            disabled,
            placeholder: 'Начало',
          }}
          onFieldSelect={() => selectField('producement_start_at')}
          ref={createFieldRef('producement_start_at')}
        />
        <FieldWithPreview.Date
          control={commercialOfferForm.control}
          edit={isFieldEditable('producement_end_at')}
          name="Срок проведения закупки"
          path="producement_end_at"
          format={DATE_FORMATS.fullDateWithTime}
          formatPreview={`по ${DATE_FORMATS.fullDateWithTimeRounded}`}
          datePickerProps={{
            disabled,
            minValue: producementStartAtDate
              ? submissionDeadlineDate
                ? maxDateTime(producementStartAtDate, submissionDeadlineDate)
                : producementStartAtDate
              : submissionDeadlineDate || undefined,
            placeholder: 'Конец',
          }}
          onFieldSelect={() => selectField('producement_end_at')}
          ref={createFieldRef('producement_end_at')}
        />
        <FieldWithPreview.MultiFile
          control={commercialOfferForm.control}
          path="documents"
          name="Документы запроса"
          edit={isFieldEditable('documents')}
          onFieldSelect={() => selectField('documents')}
          ref={createFieldRef('documents')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, documents: loading }))
          }
          disabled={disabled}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          path="purchase_object_id"
          name="Объект закупки"
          edit={isFieldEditable('purchase_object_id')}
          options={
            flattedPurchaseObjects?.map(object => ({
              content: object.object,
              value: object.id,
            })) || []
          }
          filter={filterPurchaseObjects}
          onFilterChange={filter => setFilterPurchaseObjects(filter)}
          onPlusClick={onClickCreatePurchaseObjectsHandbook}
          ref={createFieldRef('purchase_object_id')}
          onFieldSelect={() => selectField('purchase_object_id')}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookPurchaseObjectsInfiniteQuery.isFetching ||
              getHandbookPurchaseObjectsInfiniteQuery.isFetchingNextPage ||
              getHandbookPurchaseObjectsInfiniteQuery.isLoading ||
              getHandbookPurchaseObjectsInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookPurchaseObjectsInfiniteQuery.fetchNextPage();
            },
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          ref={createFieldRef('comment')}
          onFieldSelect={() => selectField('comment')}
          textAreaProps={{
            disabled,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />

        <Divider />

        {/* Рабочие параметры */}
        <FieldWithPreview.File
          control={commercialOfferForm.control}
          path="calculation_file"
          name="Файл расчета"
          edit={isFieldEditable('calculation_file')}
          ref={createFieldRef('calculation_file')}
          onFieldSelect={() => selectField('calculation_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          disabled={disabled}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, calculation_file: loading }))
          }
          required
        />
        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          path="cost_price"
          name="Себестоимость"
          edit={isFieldEditable('cost_price')}
          ref={createFieldRef('cost_price')}
          onFieldSelect={() => selectField('cost_price')}
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const offerPrice = commercialOfferForm.getValues('offer_price');
              const margin = calculateMargin({ costPrice: floatValue, offerPrice });
              if (typeof margin === 'number') commercialOfferForm.setValue('margin', margin);
            },
          }}
          required
        />
        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          path="offer_price"
          name="Цена предложения"
          edit={isFieldEditable('offer_price')}
          ref={createFieldRef('offer_price')}
          onFieldSelect={() => selectField('offer_price')}
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const costPrice = commercialOfferForm.getValues('cost_price');
              const margin = calculateMargin({ costPrice, offerPrice: floatValue });
              if (typeof margin === 'number') commercialOfferForm.setValue('margin', margin);
            },
          }}
          required
        />
        <FieldWithPreview.Percentage
          control={commercialOfferForm.control}
          path="margin"
          name="Маржа"
          edit={isFieldEditable('margin')}
          ref={createFieldRef('margin')}
          onFieldSelect={() => selectField('margin')}
          numberFormatProps={{
            disabled,
            onValueChange: ({ floatValue }) => {
              const costPrice = commercialOfferForm.getValues('cost_price');
              const offerPrice = calculateOfferPrice({ costPrice, margin: floatValue });
              if (typeof offerPrice === 'number')
                commercialOfferForm.setValue('offer_price', offerPrice);
            },
          }}
          required
        />
        <FieldWithPreview.File
          control={commercialOfferForm.control}
          path="offer_file"
          name="Файл КП"
          edit={isFieldEditable('offer_file')}
          ref={createFieldRef('offer_file')}
          onFieldSelect={() => selectField('offer_file')}
          maxSize={MAX_FILE_SIZE}
          notAllowedExtension={NOT_ALLOWED_FILE_EXTENSIONS}
          onLoadingChange={loading =>
            setFileFieldsLoading(prev => ({ ...prev, offer_file: loading }))
          }
          required
          disabled={disabled}
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          path="provider_id"
          name="Поставщик"
          edit={isFieldEditable('provider_id')}
          ref={createFieldRef('provider_id')}
          options={
            flattedSuppliers?.map(supplier => ({
              content: supplier.name,
              value: supplier.id,
            })) || []
          }
          filter={filterSuppliers}
          onFilterChange={filter => setFilterSuppliers(filter)}
          onPlusClick={onClickCreateLegalEntitiesHandbook}
          onFieldSelect={() => selectField('provider_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            loading:
              getHandbookSuppliersInfiniteQuery.isFetching ||
              getHandbookSuppliersInfiniteQuery.isFetchingNextPage ||
              getHandbookSuppliersInfiniteQuery.isLoading ||
              getHandbookSuppliersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookSuppliersInfiniteQuery.fetchNextPage();
            },
          }}
        />
        {(values.status === 'Подача' || values.status === 'Отказ') && (
          <FieldWithPreview.Wrapper
            name="Согласовать с руководителем"
            required
          >
            <Button
              disabled={
                disabled ||
                values.manager_approved ||
                !commercialOfferForm.formState.isValid ||
                !!operatingParametersErrors?.length
              }
              {...approvalButtonProps}
            >
              Согласовать
            </Button>
          </FieldWithPreview.Wrapper>
        )}
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          show={values.status === 'Отказ'}
          path="reject_description"
          name="Причина отказа"
          edit={isFieldEditable('reject_description')}
          ref={createFieldRef('reject_description')}
          onFieldSelect={() => selectField('reject_description')}
          required
          textAreaProps={{
            disabled,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          path="price_justification"
          name="Обоснование предложения цены"
          show={values.status === 'Подача' && values.purpose === 'Закупка ЕП'}
          edit={isFieldEditable('price_justification')}
          ref={createFieldRef('price_justification')}
          onFieldSelect={() => selectField('price_justification')}
          required
          textAreaProps={{
            disabled,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />
        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          show={values.status === 'Подача' && values.purpose === 'Закупка ЕП'}
          path="proposed_price"
          name="Предлагаемая специалистом цена"
          edit={isFieldEditable('proposed_price')}
          ref={createFieldRef('proposed_price')}
          onFieldSelect={() => selectField('proposed_price')}
          numberFormatProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.TextArea
          control={commercialOfferForm.control}
          show={values.status === 'Подача'}
          path="approval_comment"
          name="Комментарий согласования"
          edit={isFieldEditable('approval_comment')}
          ref={createFieldRef('approval_comment')}
          onFieldSelect={() => selectField('approval_comment')}
          textAreaProps={{
            disabled,
          }}
          wrapperProps={{
            textAlign: 'start',
          }}
        />
        {values.purpose === 'Закупка ЕП' &&
          (values.status === 'Выигран' || values.status === 'Проигран') && <Divider />}
        <FieldWithPreview.Currency
          control={commercialOfferForm.control}
          show={
            values.purpose === 'Закупка ЕП' &&
            (values.status === 'Выигран' || values.status === 'Проигран')
          }
          path="final_price"
          name="Итоговая цена"
          edit={isFieldEditable('final_price')}
          ref={createFieldRef('final_price')}
          onFieldSelect={() => selectField('final_price')}
          numberFormatProps={{
            disabled,
          }}
          required
        />
        <FieldWithPreview.Select
          control={commercialOfferForm.control}
          show={
            values.purpose === 'Закупка ЕП' &&
            (values.status === 'Выигран' || values.status === 'Проигран')
          }
          path="winner_id"
          name="Победитель"
          edit={isFieldEditable('winner_id')}
          ref={createFieldRef('winner_id')}
          options={[
            { value: 'add-legal-entities', content: 'Добавить в «Наши организации»' },
            { value: 'add-competitors', content: 'Добавить в «Конкуренты»' },
            ...(flattedWinners?.map(winner => ({
              content: winner.name,
              value: winner.id,
            })) || []),
          ]}
          filter={filterWinners}
          onFilterChange={filter => setFilterWinners(filter)}
          onFieldSelect={() => selectField('winner_id')}
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          required
          selectProps={{
            disabled,
            placeholder: 'Выбрать',
            onUpdate: ([winner]) => {
              if (winner === 'add-legal-entities') {
                commercialOfferForm.resetField('winner_id');
                commercialOfferForm.resetField('winner_type');
                return onClickCreateLegalEntitiesHandbook();
              }

              if (winner === 'add-competitors') {
                commercialOfferForm.resetField('winner_id');
                commercialOfferForm.resetField('winner_type');
                return onClickCreateCompetitorsHandbook();
              }

              commercialOfferForm.setValue(
                'winner_type',
                flattedWinners?.find(flattedWinner => flattedWinner.id === winner)?.type
              );
            },
            loading:
              getHandbookWinnersInfiniteQuery.isFetching ||
              getHandbookWinnersInfiniteQuery.isFetchingNextPage ||
              getHandbookWinnersInfiniteQuery.isLoading ||
              getHandbookWinnersInfiniteQuery.hasNextPage,
            onLoadMore: () => {
              getHandbookWinnersInfiniteQuery.fetchNextPage();
            },
          }}
        />
      </FieldWithPreview>
      {!hideBottomPanel && (
        <RightSidebar.BottomPanel>
          <Button
            size="xl"
            view="normal"
            width="max"
            onClick={onClickCancelButton}
            disabled={disabled || isFilesLoading}
          >
            {cancelButtonText}
          </Button>
          <Button
            size="xl"
            view="action"
            type="submit"
            width="max"
            onClick={commercialOfferForm.handleSubmit(onValid, onInvalid)}
            disabled={disabled || isFilesLoading}
          >
            {submitButtonText}
          </Button>
        </RightSidebar.BottomPanel>
      )}
    </form>
  );
};
