import { TabsProps } from '@gravity-ui/uikit';

import { DialogItemProps } from '@entities';
import avatarImageMockUrl from '@shared/assets/images/avatar-mock-image.png?url';
import botAvatarImageMockUrl from '@shared/assets/images/bot-avatar-mock-image.png?url';

import { MessageGroups } from './types';

export const USERS_MOCK = [
  {
    id: '1',
    name: 'Сергей Петров',
  },
  {
    id: '2',
    name: 'Константин Фабин',
  },
  {
    id: '3',
    name: 'Елизавета Кудрявцева',
  },
  {
    id: '4',
    name: 'Константин Ефремов',
  },
];

export const TYPES_MOCK = [
  {
    value: 'all',
    name: 'Все',
  },
  {
    value: 'text',
    name: 'Текст',
  },
  {
    value: 'images',
    name: 'Изображения',
  },
  {
    value: 'files',
    name: 'Файлы',
  },
  {
    value: 'actions',
    name: 'Действия',
  },
];

export const STATUSES_MOCK = [
  {
    value: 'all',
    name: 'Все',
    disabled: false,
  },
  {
    value: 'commercial-offers',
    name: 'Коммерческие предложения',
    disabled: false,
  },
  {
    value: 'bargaining',
    name: 'Торги',
    disabled: false,
  },
  {
    value: 'contracts',
    name: 'Контракты',
    disabled: true,
  },
];

export const MESSAGE_GROUPS_MOCK: MessageGroups[] = [
  {
    dateTimestamp: new Date('07/30/2024').getTime(),
    status: 'Контракты',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date().getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'delivered',
      },
      {
        id: '5',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        outcoming: false,
        status: 'delivered',
        file: {
          name: 'КП для Димитрова И.С.',
          size: 24000000,
          extension: 'pdf',
        },
      },
      {
        id: '6',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        outcoming: false,
        status: 'delivered',
        imageSrc: avatarImageMockUrl,
      },
      {
        id: '7',
        userId: '3',
        name: 'Bot',
        avatarName: 'Bot',
        avatarImageURL: botAvatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        outcoming: false,
        status: 'delivered',
        text: 'Текст сообщения',
        variant: 'good',
      },
      {
        id: '8',
        userId: '4',
        name: 'Bot',
        avatarName: 'Bot',
        avatarImageURL: botAvatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        outcoming: false,
        status: 'delivered',
        text: 'Текст сообщения',
        variant: 'danger',
      },
      {
        id: '9',
        userId: '5',
        name: 'Bot',
        avatarName: 'Bot',
        avatarImageURL: botAvatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        outcoming: false,
        status: 'delivered',
        text: 'Текст сообщения',
        variant: 'normal',
      },
      {
        id: '10',
        userId: '6',
        name: 'Bot',
        avatarName: 'Bot',
        avatarImageURL: botAvatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        outcoming: false,
        status: 'delivered',
        text: 'Текст сообщения',
        variant: 'alert',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/29/2024').getTime(),
    status: 'Торги',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/29/2024').getTime(),
    status: 'КП',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/28/2024').getTime(),
    status: 'КП',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
    ],
  },
];

export const MESSAGE_GROUPS_MOCK2: MessageGroups[] = [
  {
    dateTimestamp: new Date().getTime(),
    status: 'Контракты',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Пользователь',
        avatarName: 'Пользователь',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Пользователь',
        avatarName: 'Пользователь',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Пользователь',
        avatarName: 'Пользователь',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Любой текст',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Пользователь',
        avatarName: 'Пользователь',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/30/2024 22:36').getTime(),
        text: 'Любой текст',
        outcoming: false,
        status: 'delivered',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/29/2024').getTime(),
    status: 'Торги',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/29/2024').getTime(),
    status: 'КП',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/29/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
    ],
  },
  {
    dateTimestamp: new Date('07/28/2024').getTime(),
    status: 'КП',
    messages: [
      {
        id: '1',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '2',
        userId: '1',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: true,
        status: 'readed',
      },
      {
        id: '3',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
      {
        id: '4',
        userId: '2',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        sendedTimestamp: new Date('07/28/2024 22:36').getTime(),
        text: 'Испытание: Люба Зацепина – талантливый молодой кондитер, всю свою любовь, внимание и заботу отдает жениху Юрию Браницкому. Ради его карьеры она отказывается от учебы и идет работать. И вот наступает самый желанный день в жизни Любы – Юра сдает последние',
        outcoming: false,
        status: 'readed',
      },
    ],
  },
];

export const OPTIONS_MOCK = [
  {
    value: 'members',
    content: 'Участники',
  },
  {
    value: 'media',
    content: 'Медиа',
  },
  {
    value: 'files',
    content: 'Файлы',
  },
  {
    value: 'links',
    content: 'Ссылки',
  },
  {
    value: 'history',
    content: 'История',
  },
];

export const TABS_MOCK: TabsProps['items'] = [
  {
    id: 'all',
    title: 'Все диалоги',
    counter: 999,
  },
  {
    id: 'peoples',
    title: 'Люди',
    counter: 999,
  },
  {
    id: 'projects',
    title: 'Проекты',
    counter: 999,
  },
  {
    id: 'commercial-offers',
    title: 'КП',
    counter: 999,
  },
  {
    id: 'bargaining',
    title: 'Торги',
    counter: 999,
  },
  {
    id: 'contracts',
    title: 'Контракты',
    counter: 999,
  },
];

export const DIALOGS_MOCK = new Array(10)
  .fill(null)
  .reduce<
    (Omit<DialogItemProps, 'lastMessageSendedAt'> & { id: string; lastMessageTimestamp: number })[]
  >(
    (acc, _, index) => [
      ...acc,
      {
        id: `${index}-1`,
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: avatarImageMockUrl,
        lastMessageTimestamp: new Date('02/24/2024').getTime(),
        lastMessageText: 'Привет! Только что разобрался с документами',
        online: true,
      },
      {
        id: `${index}-2`,
        lastMessageTimestamp: new Date('02/24/2024').getTime(),
        lastMessageText: 'Привет! Только что разобрался с документами',
        name: 'Артем Аментес',
        avatarName: 'Артем Аментес',
        avatarImageURL: '',
      },
      {
        id: `${index}-3`,
        lastMessageTimestamp: new Date('02/24/2024').getTime(),
        lastMessageText: 'Привет! Только что разобрался с документами',
        name: 'Кристина Иванова',
        type: 'project',
        projectName: 'КП Орион — ООО Димитров Иван Сергеевич',
        avatarName: 'КП',
      },
    ],
    []
  );

export const SETTINGS_USERS_MOCK = [
  {
    name: 'Сергей Петров',
  },
  {
    name: 'Артем Аментес',
  },
  {
    name: 'Константин Фабин',
  },
  {
    name: 'Елизавета Кудрявцева',
  },
  {
    name: 'Сергей Петров',
  },
  {
    name: 'Артем Аментес',
  },
  {
    name: 'Константин Фабин',
  },
  {
    name: 'Елизавета Кудрявцева',
  },
  {
    name: 'Сергей Петров',
  },
  {
    name: 'Артем Аментес',
  },
  {
    name: 'Константин Фабин',
  },
  {
    name: 'Елизавета Кудрявцева',
  },
];

export const HISTORY_MOCK = [
  {
    variant: 'default',
    title: 'Статус',
    description: 'КП —> Торги',
    author: 'Сергей Петров',
    timestamp: new Date('03/18/24').getTime(),
  },
  {
    variant: 'default',
    title: 'Срок поставки',
    description: '24.02.24 —> 01.03.24',
    author: 'Артем Аментес',
    timestamp: new Date('01/24/24').getTime(),
  },
  {
    variant: 'danger',
    title: 'Просрочен «срок поставки»',
    description: '24.02.24',
    timestamp: new Date('01/24/24').getTime(),
  },
  {
    variant: 'default',
    title: 'Добавлен участник',
    description: 'Артем Аментес',
    author: 'Ильдар Матвеев',
    timestamp: new Date('11/12/23').getTime(),
  },
  {
    variant: 'default',
    title: 'Добавлен участник',
    description: 'Добавлен участник',
    author: 'Ильдар Матвеев',
    timestamp: new Date('11/12/23').getTime(),
  },
  {
    variant: 'default',
    title: 'Статус',
    description: 'Ирина Сергеева',
    author: 'Ильдар Матвеев',
    timestamp: new Date('11/12/23').getTime(),
  },
];
