import { ReactNode, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { CUSTOM_HANDBOOK_TO_RUSSIAN, CustomHandbookName } from '@entities';
import {
  AgenciesHandbookTable,
  CompetitorsHandbookTable,
  ContactsHandbookTable,
  ETradingPlatformsHandbookTable,
  Okpd2HandbookTable,
} from '@widgets';
import { URLS } from '@shared/consts';
import { CRMBreadCrumbs, HeroWithButton } from '@shared/ui';

export function CustomHandbookGuard() {
  const handbookName = useParams()[URLS.handbook.dynamicParams.handbookName] as
    | CustomHandbookName
    | undefined;

  if (!handbookName || !(handbookName in CUSTOM_HANDBOOK_TO_RUSSIAN))
    return (
      <Navigate
        to={URLS.handbook.default}
        replace
      />
    );

  return <CustomHandbook handbookName={handbookName} />;
}

export const CustomHandbook = ({ handbookName }: { handbookName: CustomHandbookName }) => {
  const title = CUSTOM_HANDBOOK_TO_RUSSIAN[handbookName];

  const [createCustomerHandbookOpen, setCreateCustomerHandbookOpen] = useState(false);

  const handbookByName: Partial<Record<CustomHandbookName, ReactNode>> = {
    agencies: (
      <AgenciesHandbookTable
        creationOpen={createCustomerHandbookOpen}
        onCloseCreation={() => setCreateCustomerHandbookOpen(false)}
      />
    ),
    competitors: (
      <CompetitorsHandbookTable
        creationOpen={createCustomerHandbookOpen}
        onCloseCreation={() => setCreateCustomerHandbookOpen(false)}
      />
    ),
    contacts: (
      <ContactsHandbookTable
        creationOpen={createCustomerHandbookOpen}
        onCloseCreation={() => setCreateCustomerHandbookOpen(false)}
      />
    ),
    'e-trading-platforms': (
      <ETradingPlatformsHandbookTable
        creationOpen={createCustomerHandbookOpen}
        onCloseCreation={() => setCreateCustomerHandbookOpen(false)}
      />
    ),
    okpd2s: (
      <Okpd2HandbookTable
        creationOpen={createCustomerHandbookOpen}
        onCloseCreation={() => setCreateCustomerHandbookOpen(false)}
      />
    ),
  };

  return (
    <>
      <CRMBreadCrumbs
        items={[
          {
            text: 'Справочники',
            href: URLS.handbook.default,
          },
          {
            text: title,
            href: `${URLS.handbook.custom}/${handbookName}`,
          },
        ]}
      />
      <HeroWithButton
        title={title}
        buttonOnClick={() => setCreateCustomerHandbookOpen(true)}
      />
      {handbookName in handbookByName && handbookByName[handbookName]}
    </>
  );
};
