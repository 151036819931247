import { Gear } from '@gravity-ui/icons';
import { Button, Icon, TabsItemProps, TabsProps, Text } from '@gravity-ui/uikit';
import { PrimitiveAtom, useAtom, WritableAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useRef } from 'react';

import { TabsSettingsModal, TabsSettingsModalRef } from '@features/tabs-settings/@x';

import { TabsWithScroll as Tabs } from '../../tabs';
import { CRMTableFilters, CRMTableSettings } from '../model/types';

interface TableTabsProps<T extends string[]> extends TabsProps {
  tableTabsAtom: WritableAtom<
    TabsItemProps[],
    [TabsItemProps[] | typeof RESET | ((prev: TabsItemProps[]) => TabsItemProps[] | typeof RESET)],
    void
  >;
  selectedTabAtom: PrimitiveAtom<string | null>;
  tableSettings: CRMTableSettings<T>;
  filterSettings: CRMTableFilters<T>;
  onUpdateTab?: (tab: TabsItemProps, newName: string) => Promise<void>;
  onDeleteTab?: (tab: TabsItemProps) => Promise<void>;
  onCreateTab?: (tabName: string) => Promise<void>;
}

export function TableTabs<ColumnNames extends string[]>({
  tableTabsAtom,
  selectedTabAtom,
  tableSettings,
  filterSettings,
  onSelectTab,
  onCreateTab,
  onDeleteTab,
  onUpdateTab,
  ...tabProps
}: Omit<TableTabsProps<ColumnNames>, 'activeTab' | 'items'>) {
  const tabSettingsModal = useRef<TabsSettingsModalRef>(null);

  const [displayedTabs, setDisplayedTabs] = useAtom(tableTabsAtom);
  const [activeTab, setActiveTab] = useAtom(selectedTabAtom);

  return (
    <div className="relative overflow-x-hidden border-b-[1px] border-solid border-b-base-float-announcement min-h-[36px]">
      <Tabs
        className="pr-[161px] pl-5 py-0"
        items={displayedTabs}
        activeTab={activeTab || undefined}
        onSelectTab={tabId => {
          setActiveTab(tabId);
          if (onSelectTab) onSelectTab(tabId);
        }}
        {...tabProps}
      />
      <div className="right-0 top-0 absolute flex flex-row ">
        <div className="inline-flex w-9 h-9 bg-gradient-to-l from-base-background"></div>
        <Button
          size="l"
          className="inline-flex before:bg-base-background before:hover:bg-base-background"
          onClick={() => {
            tabSettingsModal.current?.open();
            console.log(tabSettingsModal.current);
          }}
        >
          <Icon data={Gear} />
          <Text>Настроить</Text>
        </Button>
      </div>
      {
        /* модалка настройки таб */
        <TabsSettingsModal
          ref={tabSettingsModal}
          tabs={displayedTabs.slice(1)}
          tableSettings={tableSettings}
          filterSettings={filterSettings}
          onConfirmChange={newTabs => {
            setDisplayedTabs(displayedTabs[0] ? [displayedTabs[0], ...newTabs] : newTabs);
            const findIndex = newTabs.findIndex(t => t.id === activeTab);
            if (findIndex === -1 && displayedTabs[0]) {
              setActiveTab(displayedTabs[0].id);
            }
          }}
          wordBlackList={['Все']}
          onCreateTab={onCreateTab}
          onDeleteTab={onDeleteTab}
          onUpdateTab={onUpdateTab}
        />
      }
    </div>
  );
}
