import { ColumnsFilters } from '@features';

import { ColumnsCommercialOffersTable } from '../model';

export const TABLE_COLUMNS_WITH_FILTER: Record<
  ColumnsCommercialOffersTable[number],
  ColumnsFilters
> = {
  'Дата, номер': 'date',
  Наименование: 'search',
  Ответственный: 'select',
  Заказчик: 'select',
  'Цель КП': 'select',
  'Статус КП': 'select',
  'Дата перевода': 'date',
  'Регион поставки': 'select',
  'Адрес поставки': 'search',
  'Срок поставки': 'search',
  Основание: 'select',
  'Как получен запрос': 'select',
  'Ссылка в ЕИС': 'boolean',
  'Окончание подачи': 'dateWithTime',
  'Срок проведения закупки с': 'date',
  'Срок проведения закупки по': 'date',
  'Документы запроса': 'boolean',
  'Объект закупки': 'select',
  Комментарий: 'search',
  'Файл расчета': 'boolean',
  Себестоимость: 'currency',
  'Цена предложения': 'currency',
  'Маржа, %': 'margin',
  'Файл КП': 'boolean',
  'Дата КП': 'date',
  Поставщик: 'select',
  'Согласовать цену с руководителем': 'boolean',
  'Предлагаемая специалистом цена': 'currency',
  'Обоснование предложения цены': 'search',
  'Комментарий согласований': 'search',
  'Итоговая цена': 'currency',
  Победитель: 'select',
};

export const COLUMNS_NAMES: ColumnsCommercialOffersTable = [
  'Дата, номер',
  'Наименование',
  'Ответственный',
  'Заказчик',
  'Цель КП',
  'Статус КП',
  'Дата перевода',
  'Регион поставки',
  'Адрес поставки',
  'Срок поставки',
  'Основание',
  'Как получен запрос',
  'Ссылка в ЕИС',
  'Окончание подачи',
  'Срок проведения закупки с',
  'Срок проведения закупки по',
  'Документы запроса',
  'Объект закупки',
  'Комментарий',
  'Файл расчета',
  'Себестоимость',
  'Цена предложения',
  'Маржа, %',
  'Файл КП',
  'Дата КП',
  'Поставщик',
  'Согласовать цену с руководителем',
  'Предлагаемая специалистом цена',
  'Обоснование предложения цены',
  'Комментарий согласований',
  'Итоговая цена',
  'Победитель',
];
