import { DelayedTextInput } from '@gravity-ui/components';
import { Calendar, Funnel, ListTimeline, Magnifier } from '@gravity-ui/icons';
import { Button, Icon, Text } from '@gravity-ui/uikit';

interface TableSearchAndActionsPanelProps {
  searchValue: string;
  onUpdate: (value: string) => void;
  placeholder?: string;
  filtersButtonAction?: () => void;
  tableSettingButtonAction?: () => void;
  calendarButtonAction?: () => void;
  delay?: number;
}

export const TableSearchAndActionsPanel = ({
  searchValue,
  onUpdate,
  placeholder,
  filtersButtonAction,
  tableSettingButtonAction,
  calendarButtonAction,
  delay,
}: TableSearchAndActionsPanelProps) => {
  return (
    <div className="overflow-auto w-full py-4 px-5 flex justify-between">
      <div className="flex gap-4 flex-grow">
        <DelayedTextInput
          startContent={
            <Icon
              data={Magnifier}
              className="text-text-secondary"
            />
          }
          placeholder={placeholder}
          size="l"
          hasClear
          className="w-full max-w-[465px] min-w-[200px] input-padding"
          value={searchValue}
          onUpdate={onUpdate}
          delay={delay ?? 300}
        />

        {filtersButtonAction && (
          <Button
            size="l"
            view="outlined"
            onClick={filtersButtonAction}
          >
            <Icon data={Funnel} />
            <Text>Фильтры</Text>
          </Button>
        )}

        {tableSettingButtonAction && (
          <Button
            size="l"
            view="outlined"
            onClick={tableSettingButtonAction}
          >
            <Icon data={ListTimeline} />
            <Text>Таблица</Text>
          </Button>
        )}
      </div>

      {calendarButtonAction && (
        <div className="flex gap-2 ml-4">
          <Button
            size="l"
            onClick={calendarButtonAction}
          >
            <Icon data={Calendar} />
            Календарь
          </Button>
        </div>
      )}
    </div>
  );
};
