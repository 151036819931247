import { FieldWithPreviewProps } from '@shared/ui';

export const STATUS_OPTIONS: FieldWithPreviewProps['Select']['options'] = [
  {
    value: 'Ассистент менеджера',
    content: 'Ассистент менеджера',
  },
  {
    value: 'Менеджер',
    content: 'Менеджер',
  },
  {
    value: 'Бухгалтерия',
    content: 'Бухгалтерия',
  },
  {
    value: 'Доставка',
    content: 'Доставка',
  },
  {
    value: 'Иное',
    content: 'Иное',
  },
  {
    value: 'Руководитель менеджера',
    content: 'Руководитель менеджера',
  },
  {
    value: 'Склад',
    content: 'Склад',
  },
];

export const TYPE_OPTIONS: FieldWithPreviewProps['Select']['options'] = [
  {
    value: 'ГОССНАБ',
    content: 'ГОССНАБ',
  },
  {
    value: 'Заказчик',
    content: 'Заказчик',
  },
  {
    value: 'Поставщик',
    content: 'Поставщик',
  },
];
