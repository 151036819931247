import { UseFormProps } from 'react-hook-form';

import { LegalEntitiesSchema } from '../model';

export const DEFAULT_VALUES: UseFormProps<LegalEntitiesSchema>['defaultValues'] = {
  contact_ids: [],
  files: [],
  inn: undefined,
  kpp: undefined,
  name: undefined,
  payment_detail_id: undefined,
  purchase_object_id: undefined,
  region_id: undefined,
  website: undefined,
};
