import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { tenderFormAtom } from '@features';

export function useWatchUniqueNumber(uniqueNumber?: string) {
  const tenderForm = useAtomValue(tenderFormAtom);

  const [debouncedUniqueNumber] = useDebounce(uniqueNumber, 1000);

  useEffect(() => {
    if (debouncedUniqueNumber) {
      tenderForm?.setValue('Электронная торговая площадка', 'АСТ ГОЗ', { shouldDirty: true });
      tenderForm?.setValue('ФЗ', '1', { shouldDirty: true });
      tenderForm?.setValue('Закупка централизованная или совместная?', 'Нет', {
        shouldDirty: true,
      });
      tenderForm?.setValue('Заказчики', ['1', '3'], { shouldDirty: true });
      tenderForm?.setValue('Начальная цена', 15000, { shouldDirty: true });
      tenderForm?.setValue('Способ проведения', 'Конкурс', { shouldDirty: true });
      tenderForm?.setValue('Обеспечение заявки', 20000, { shouldDirty: true });
      tenderForm?.setValue(
        'Документация',
        [
          {
            id: '1',
            file: new File(['2421'], 'Документ 1', { type: 'plain/text' }),
            loading: false,
          },
          {
            id: '2',
            file: new File(['2421'], 'Документ 1', { type: 'plain/text' }),
            loading: false,
          },
        ],
        { shouldDirty: true }
      );
      tenderForm?.setValue(
        'Разъяснения',
        [
          {
            id: '1',
            file: new File(['2421'], 'Документ 1', { type: 'plain/text' }),
            loading: false,
          },
          {
            id: '2',
            file: new File(['2421'], 'Документ 1', { type: 'plain/text' }),
            loading: false,
          },
        ],
        { shouldDirty: true }
      );
      tenderForm?.setValue(
        'Протоколы',
        [
          {
            id: '1',
            file: new File(['2421'], 'Документ 1', { type: 'plain/text' }),
            loading: false,
          },
          {
            id: '2',
            file: new File(['2421'], 'Документ 1', { type: 'plain/text' }),
            loading: false,
          },
        ],
        { shouldDirty: true }
      );
      tenderForm?.setValue('Регион поставки', '1', { shouldDirty: true });
    } else {
      tenderForm?.resetField('Электронная торговая площадка');
      tenderForm?.resetField('ФЗ');
      tenderForm?.resetField('Закупка централизованная или совместная?');
      tenderForm?.resetField('Заказчики');
      tenderForm?.resetField('Начальная цена');
      tenderForm?.resetField('Способ проведения');
      tenderForm?.resetField('Обеспечение заявки');
      tenderForm?.resetField('Документация');
      tenderForm?.resetField('Разъяснения');
      tenderForm?.resetField('Протоколы');
      tenderForm?.resetField('Регион поставки');
    }
  }, [debouncedUniqueNumber]);
}
