import { dateTime } from '@gravity-ui/date-utils';
import { Label, Text } from '@gravity-ui/uikit';
import { useAtom } from 'jotai';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { MessageItem } from '@entities';
import { DownloadFileButton, jumpToDateAtom } from '@features';
import { cn } from '@shared/lib';

import { MESSAGE_GROUPS_MOCK } from '../../model/mock'; // TODO: убрать моки
import { ChatSeparator } from '../ChatSeparator';

import './index.sass';

// TODO: Должен принимать пропсы
export function ChatMessages({ messagesGroups }: { messagesGroups: typeof MESSAGE_GROUPS_MOCK }) {
  const [selectedDate] = useAtom(jumpToDateAtom);

  // TODO: временное решение
  const [closestMessageGroupIndexToSelectedDate, setClosestMessageGroupIndexToSelectedDate] =
    useState<null | number>(null);
  const closesMessageGrouptRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedDate && !!MESSAGE_GROUPS_MOCK.length) {
      let closestIndex: number | null = null;

      MESSAGE_GROUPS_MOCK.forEach(({ dateTimestamp }, index) => {
        if (typeof closestIndex !== 'number') {
          closestIndex = index;
          return;
        }

        const closestMessageGroup = MESSAGE_GROUPS_MOCK[closestIndex]!;

        const closestDiff = closestMessageGroup.dateTimestamp - selectedDate.valueOf();
        const currentDiff = dateTimestamp - selectedDate.valueOf();

        if (currentDiff >= 0 && currentDiff < closestDiff) {
          closestIndex = index;
        }
      });

      if (typeof closestIndex === 'number') {
        setClosestMessageGroupIndexToSelectedDate(closestIndex);
      }
    }
  }, [selectedDate]);

  useLayoutEffect(() => {
    closesMessageGrouptRef.current?.scrollIntoView({ block: 'start' });
  }, [closestMessageGroupIndexToSelectedDate]);

  return (
    <div className="grow flex flex-col-reverse overflow-y-auto relative hide-scrollbar chat-messages">
      {messagesGroups.map(({ dateTimestamp, messages, status }, index) => {
        const previousStatus = MESSAGE_GROUPS_MOCK[index + 1]?.status;

        const date = dateTime({ input: dateTimestamp });
        const formattedDate = date.isSame(Date.now(), 'year')
          ? date.format('D MMMM')
          : date.format('D MMMM YYYY');

        return (
          <Fragment key={`${dateTimestamp}-${status}-${index}`}>
            <div
              className="p-5"
              ref={node => {
                if (closestMessageGroupIndexToSelectedDate === index) {
                  closesMessageGrouptRef.current = node;
                }
              }}
            >
              <Label
                size="xs"
                theme="normal"
                className="sticky top-3 left-1/2 w-fit -translate-x-1/2"
              >
                {formattedDate} | {status}
              </Label>
              {messages.map(
                (
                  {
                    avatarImageURL,
                    avatarName,
                    id,
                    name,
                    outcoming,
                    sendedTimestamp,
                    status,
                    userId,
                    file,
                    imageSrc,
                    text,
                    variant,
                  },
                  index
                ) => {
                  const related = userId === messages[index - 1]?.userId;
                  const sendedAt = dateTime({ input: sendedTimestamp }).format('HH:mm');

                  return (
                    <div
                      key={`${date}-${id}-${index}`}
                      className={cn({
                        'mt-4': !related,
                      })}
                    >
                      <MessageItem
                        variant={variant}
                        avatarImageURL={avatarImageURL}
                        avatarName={avatarName}
                        name={name}
                        sendedAt={sendedAt}
                        related={related}
                        outcoming={outcoming}
                        status={status}
                      >
                        <>
                          {!!text && (
                            <Text
                              variant="body-short"
                              color="primary"
                            >
                              {text}
                            </Text>
                          )}
                          {!!file && (
                            <DownloadFileButton
                              fileExtension={file.extension}
                              fileName={file.name}
                              fileSize={file.size}
                            />
                          )}
                          {!!imageSrc && (
                            <img
                              className="max-w-[240px] object-cover rounded-[8px]"
                              src={imageSrc}
                              alt={`Изображение от ${name}`}
                              loading="lazy"
                            />
                          )}
                        </>
                      </MessageItem>
                    </div>
                  );
                }
              )}
            </div>
            {previousStatus && previousStatus !== status && (
              <ChatSeparator className="p-5">
                {previousStatus} —&gt; {status}
              </ChatSeparator>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
