import { Text } from '@gravity-ui/uikit';
import { ReactNode } from 'react';

interface GlobalSearchListGroupProps {
  name: string;
  children: ReactNode;
}

export function GlobalSearchListGroup({ name, children }: GlobalSearchListGroupProps) {
  return (
    <div className="flex flex-col gap-2">
      <Text
        variant="subheader-1"
        color="primary"
      >
        {name}
      </Text>
      {children}
    </div>
  );
}
