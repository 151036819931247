import { Grip } from '@gravity-ui/icons';
import { Button, Checkbox, Icon, TableSettingsData, Text } from '@gravity-ui/uikit';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';

import { getDnDDragEndHandler } from '@shared/lib';
import { RightSidebar } from '@shared/ui';

import { CRMTableSettings } from '../model/types';

interface ConfigTableSideBar<T extends string[]> {
  tableSettings: CRMTableSettings<T>;
  resetSettings: CRMTableSettings<T>;
  onConfirm: (newSettings: CRMTableSettings<T>) => void;
  open: boolean;
  onClose: () => void;
}

export function TableSettingsSideBar<ColumnNames extends string[]>({
  tableSettings,
  resetSettings,
  onConfirm,
  onClose,
  open,
}: ConfigTableSideBar<ColumnNames>) {
  const [dragTableSettings, setDragTableSettings] = useState<TableSettingsData>(
    tableSettings ? () => structuredClone(tableSettings).slice(1) : []
  );

  const onReset = () => {
    setDragTableSettings(structuredClone(resetSettings.slice(1)));
  };

  useEffect(() => {
    setDragTableSettings(tableSettings ? () => structuredClone(tableSettings).slice(1) : []);
  }, [tableSettings]);

  return (
    <RightSidebar
      id="table-layout-table-settings"
      className="!w-[338px]"
      open={open}
      onClose={onClose}
    >
      <RightSidebar.Header
        className="pt-[26px] pb-[10px] pl-[32px] pr-[20px] items-center"
        title="Настройка таблицы"
        titleProps={{
          variant: 'subheader-3',
        }}
        onClose={onClose}
      />

      <div className="overflow-y-auto">
        <DragDropContext onDragEnd={getDnDDragEndHandler(dragTableSettings, setDragTableSettings)}>
          <Droppable droppableId={`columnSettingsDroppable`}>
            {provided => (
              <div
                ref={provided.innerRef}
                className="draggable-container"
                {...provided.droppableProps}
              >
                {dragTableSettings.map((column, index) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}
                  >
                    {(dragProvided: DraggableProvided) => (
                      <div
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        ref={dragProvided.innerRef}
                        style={{
                          ...dragProvided.draggableProps.style,
                          left: 0,
                        }}
                        className="py-[11px] relative"
                      >
                        <Checkbox
                          onUpdate={() => (column.isSelected = !column.isSelected)}
                          defaultChecked={column.isSelected}
                          className="pl-3 pr-2 w-full h-full flex flex-row items-center"
                          key={Math.random()}
                          size="m"
                        >
                          <div className="flex flex-row items-center justify-between">
                            <Text variant="subheader-1">{column.id}</Text>
                            <Icon data={Grip} />
                          </div>
                        </Checkbox>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <RightSidebar.BottomPanel
        className="pt-[15px] px-[32px] pb-[28px] justify-end border-none"
        withoutShadow
      >
        <Button
          view="flat-secondary"
          size="l"
          onClick={onReset}
        >
          <Text>Сбросить</Text>
        </Button>
        <Button
          view="action"
          size="l"
          onClick={() =>
            onConfirm(
              tableSettings[0] ? [tableSettings[0], ...dragTableSettings] : dragTableSettings
            )
          }
        >
          <Text>Применить</Text>
        </Button>
      </RightSidebar.BottomPanel>
    </RightSidebar>
  );
}
