import { ProcessItemProps } from '@entities';
import { URLS } from '@shared/consts';

export const PROCESSES_MOCK: {
  processName: string;
  processes: ProcessItemProps[];
}[] = [
  {
    processName: 'Коммерческие предложения',
    processes: [
      {
        name: 'ВЧ №28178 (ФКУ)',
        dateAndNumber: '08.06.2024, №590',
        link: URLS.processes.commercialOffers,
      },
      {
        name: 'ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ КУЛЬТУРЫ ГОРОДА МОСКВЫ "МОСКОВСКИЙ АКАДЕМИЧЕСКИЙ ТЕАТР ИМЕНИ ВЛ. МАЯКОВСКОГО"',
        dateAndNumber: '20.07.2023, №249',
        link: URLS.processes.commercialOffers,
      },
      {
        name: 'ВЧ №28178 (ФКУ)',
        dateAndNumber: '12.03.2023, №199',
        link: URLS.processes.commercialOffers,
      },
    ],
  },
  {
    processName: 'Торги',
    processes: [
      {
        name: 'ВЧ №28178 (ФКУ)',
        dateAndNumber: '09.12.2022, №78',
        link: URLS.processes.commercialOffers, // TODO: заменять на ссылку открывающую КП
      },
      {
        name: 'ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ КУЛЬТУРЫ ГОРОДА МОСКВЫ "МОСКОВСКИЙ АКАДЕМИЧЕСКИЙ ТЕАТР ИМЕНИ ВЛ. МАЯКОВСКОГО"',
        dateAndNumber: '11.07.2021, №56',
        link: URLS.processes.commercialOffers, // TODO: заменять на ссылку открывающую КП
      },
      {
        name: 'СЗЦМТО (ФКУ) Росгвардии',
        dateAndNumber: '08.01.2020, №22',
        link: URLS.processes.commercialOffers, // TODO: заменять на ссылку открывающую КП
      },
    ],
  },
];

// export const OPTIONS_MOCK = {
//   customerOptions: [
//     { value: '1', content: 'Минобороны России' },
//     { value: '2', content: 'РДДМ Движение Первых (ООГДДиМ)' },
//   ],
//   winnerOptions: [
//     { value: '1', content: 'АВК Технологии (ООО)' },
//     { value: '2', content: 'АСКОМ (ООО)' },
//   ],
//   providerOptions: [
//     { value: '1', content: 'Битуджи Технологии (ООО)' },
//     { value: '2', content: 'Бэст (ООО)' },
//   ],
//   purchaseObjectOptions: [{ value: '1', content: 'Мебель металлическая' }],
//   deliveryRegionOptions: [
//     { value: '1', content: 'Московская обл.' },
//     { value: '2', content: 'Курская обл.' },
//   ],
// };
