import './index.sass';

import { Calculator } from '@gravity-ui/icons';
import { Button, Icon, Label, Loader, Text } from '@gravity-ui/uikit';
import { Provider, useAtom } from 'jotai';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { CommercialOfferStatuses, convertCommercialOfferStatusToLabelTheme } from '@entities';
import { $commercialOfferHooks } from '@entities/commercial-offers-entity';
import { ColumnFilterPopup, CRMTable, useTableColumnSort, useTableFilters } from '@features';
import { CRMTableColumns, CRMTableData, CRMTableSettings } from '@features/table';
import { $api } from '@shared/api';
import { objectKeysSafeToArray } from '@shared/lib';

import {
  useCommercialOfferCreateTabMutation,
  useCommercialOfferDeleteTabMutation,
  useCommercialOfferGetTabsQuery,
  useCommercialOfferUpdateTabMutation,
} from '../api';
import { TABLE_COLUMNS_WITH_FILTER } from '../consts';
import {
  parseCommercialOfferTableData,
  parseFiltersFromBackend,
  parseFiltersToBackend,
} from '../lib';
import { ColumnsCommercialOffersTable, selectedTabAtom, tableTabsAtom } from '../model';
import { frontKeyBackendValueSorting } from '../model/store';
import { UpdateCommercialOfferDialog } from './UpdateCommercialOfferDialog';

type CommercialOffersTableProps = {
  selectedOfferIndex: number | null;
  onRowClick: (item: CRMTableData<ColumnsCommercialOffersTable>[number], index: number) => void;
  calendarButtonAction: () => void;
};

export const CommercialOffersTable = ({
  selectedOfferIndex,
  onRowClick,
  calendarButtonAction,
}: CommercialOffersTableProps) => {
  const [tableData, setTableData] = useState<CRMTableData<ColumnsCommercialOffersTable>>([]);
  const [isUpdateConfig, setIsUpdateConfig] = useState(false);

  // НАЧАЛО фильтры сайдбар
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const {
    selectedFilters,
    sidebarFilters,
    setSelectedFilters,
    updateFiltersByDateColumns,
    updateFiltersByCurrencyOrMarginColumns,
    resetFilters,
    deleteFilterValueByName,
  } = useTableFilters(TABLE_COLUMNS_WITH_FILTER);

  // КОНЕЦ фильтры сайдбар

  // НАЧАЛО столбцы таблицы

  const columns: CRMTableColumns<ColumnsCommercialOffersTable> = (
    [
      {
        id: 'Дата, номер',
        template: item => <Text className="text-custom-text-warning">{item['Дата, номер']}</Text>,
      },
      {
        id: 'Наименование',
      },
      {
        id: 'Ответственный',
      },
      {
        id: 'Заказчик',
      },
      {
        id: 'Цель КП',
        template: item => <Label theme="utility">{item['Цель КП']}</Label>,
      },
      {
        id: 'Статус КП',
        template: item => (
          // TODO: Как будут enums, убрать
          <Label
            theme={convertCommercialOfferStatusToLabelTheme(
              item['Статус КП'] as CommercialOfferStatuses
            )}
          >
            {item['Статус КП']}
          </Label>
        ),
      },
      {
        id: 'Дата перевода',
      },

      {
        id: 'Регион поставки',
      },
      {
        id: 'Адрес поставки',
      },
      {
        id: 'Срок поставки',
      },
      {
        id: 'Как получен запрос',
      },
      {
        id: 'Основание',
      },
      {
        id: 'Ссылка в ЕИС',
        template: ({ 'Ссылка в ЕИС': url }) => {
          return (
            <Link
              to={url}
              target="_blank"
            >
              <Text
                variant="body-1"
                color="warning"
              >
                {url}
              </Text>
            </Link>
          );
        },
      },
      {
        id: 'Окончание подачи',
      },
      {
        id: 'Срок проведения закупки с',
      },
      {
        id: 'Срок проведения закупки по',
      },
      {
        id: 'Документы запроса',
      },
      {
        id: 'Объект закупки',
      },
      {
        id: 'Комментарий',
      },
      {
        id: 'Файл расчета',
      },
      {
        id: 'Себестоимость',
      },
      {
        id: 'Цена предложения',
      },
      {
        id: 'Маржа, %',
      },
      {
        id: 'Файл КП',
      },
      {
        id: 'Дата КП',
      },
      {
        id: 'Поставщик',
      },
      {
        id: 'Согласовать цену с руководителем',
      },
      {
        id: 'Предлагаемая специалистом цена',
      },
      {
        id: 'Обоснование предложения цены',
      },
      {
        id: 'Комментарий согласований',
      },
      {
        id: 'Итоговая цена',
      },
      {
        id: 'Победитель',
      },
    ] as CRMTableColumns<ColumnsCommercialOffersTable>
  ).map(column => ({
    ...column,
    name: () => columnWithHover(column.id),
    placeholder: '',
    meta: { filter: true, selectedByDefault: true },
  })) as unknown[] as CRMTableColumns<ColumnsCommercialOffersTable>;

  const [isTableSettingsOpen, setIsTableSettingsOpen] = useState(false);

  const initialSettings: CRMTableSettings<ColumnsCommercialOffersTable> = columns.map(col => ({
    id: col.id,
    isSelected: true,
  }));

  const [tableSettings, setTableSettings] = useState<
    CRMTableSettings<ColumnsCommercialOffersTable>
  >(structuredClone(initialSettings));

  const tableSettingsToBack = useMemo(() => {
    return tableSettings.reduce<string[]>((acc, setting) => {
      if (setting.isSelected) {
        acc = [...acc, setting.id];
      }
      return acc;
    }, []);
  }, [tableSettings]);

  const { sortOrder, sortColumn, changeSortOrder } = useTableColumnSort(tableSettings);

  const columnWithHover = (name: ColumnsCommercialOffersTable[number]) => {
    const filter = selectedFilters[name];
    let content: ReactNode | null = null;

    const withPopup = (cellName: ColumnsCommercialOffersTable[number], renderNode: ReactNode) => (
      <Provider>
        <ColumnFilterPopup cellName={cellName}>{renderNode}</ColumnFilterPopup>
      </Provider>
    );

    switch (filter?.type) {
      case 'date':
      case 'dateWithTime':
        content = withPopup(
          name,
          <ColumnFilterPopup.Date
            dateFormat={filter.type === 'date' ? 'fullDate' : 'fullDateWithTime'}
            onValid={cFilterData => {
              updateFiltersByDateColumns(
                name,
                filter.type === 'date' ? 'fullDate' : 'fullDateWithTime',
                cFilterData.from,
                cFilterData.to
              );
              setIsUpdateConfig(true);
            }}
          />
        );
        break;
      case 'currency':
        content = withPopup(
          name,
          <ColumnFilterPopup.Currency
            onValid={cFilterData => {
              updateFiltersByCurrencyOrMarginColumns(name, cFilterData.from, cFilterData.to);
              setIsUpdateConfig(true);
            }}
          />
        );
        break;
      case 'priority':
        content = withPopup(
          name,
          <ColumnFilterPopup.Priority
            onValid={cFilterData => {
              updateFiltersByCurrencyOrMarginColumns(name, cFilterData.from, cFilterData.to);
              setIsUpdateConfig(true);
            }}
          />
        );
        break;
      case 'margin':
        content = withPopup(
          name,
          <ColumnFilterPopup.Margin
            onValid={cFilterData => {
              updateFiltersByCurrencyOrMarginColumns(name, cFilterData.from, cFilterData.to);
              setIsUpdateConfig(true);
            }}
          />
        );
    }

    return (
      <CRMTable.HoverColumnWrapper
        sort={sortOrder}
        iconPosition="end"
        content={content || name}
        columnName={name}
        currentColumnName={sortColumn}
        onSortTypeChange={(newSort, colName) => {
          changeSortOrder(newSort, colName);
          setIsUpdateConfig(true);
        }}
      />
    );
  };

  // КОНЕЦ столбцы таблицы

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  // НАЧАЛО нижняя панель

  const [bottomPanelOpen, setBottomPanelOpen] = useState(false);
  const [amountDialogOpen, setAmountDialogOpen] = useState(false);
  const [updateCommercialOfferDialogOpen, setUpdateCommercialOfferDialogOpen] = useState(false);

  const [selectedRows, currencyColumnsNames] = useMemo(() => {
    return [
      tableData.filter((_, index) => !selectedIds.length || selectedIds.includes(index.toString())),
      objectKeysSafeToArray(TABLE_COLUMNS_WITH_FILTER).reduce(
        (acc, key) => {
          if (TABLE_COLUMNS_WITH_FILTER[key] === 'currency') {
            acc.push(key);
          }
          return acc;
        },
        [] as ColumnsCommercialOffersTable[number][]
      ),
    ];
  }, [selectedIds]);

  // КОНЕЦ нижняя панель

  const [searchAttributesValue, setSearchAttributesValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchAttributesValue, 500);

  function searchAttributes(value: string) {
    setSearchAttributesValue(value);
  }

  // Request tabs
  const [tabs, setTabs] = useAtom(tableTabsAtom);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

  const { data: tabsData, isPending } = useCommercialOfferGetTabsQuery();
  const deleteTabMutation = useCommercialOfferDeleteTabMutation();
  const updateTabMutation = useCommercialOfferUpdateTabMutation();
  const createTabMutation = useCommercialOfferCreateTabMutation();
  const { data: activeTabData } = $api.useQuery(
    'get',
    '/offer/tabs/{name}',
    { params: { path: { name: selectedTab || '' } } },
    { enabled: !!selectedTab }
  );
  const tableDataInfiniteQuery = $commercialOfferHooks.getAll(
    {
      filter: parseFiltersToBackend(selectedFilters) || {},
      search_value: searchAttributesValue,
      //@ts-expect-error TODO: ошибка в схеме
      sort_field: sortColumn === '' ? '' : frontKeyBackendValueSorting[sortColumn],
      sort_order: sortOrder,
    },
    true
  );

  // const OFFERS_LIMIT = 30;
  const [, setOffersOffset] = useState(0);

  useEffect(() => {
    if (tabsData && tabsData.tabs && tabsData.tabs.length) {
      setTabs(() => {
        const newTabs = tabsData.tabs.map(el => ({
          id: el.name,
          title: el.name,
          counter: el.counter,
        }));
        const tabAllIndex = newTabs.findIndex(tab => tab.id === 'Все');
        if (tabAllIndex === 0) return newTabs;
        if (tabAllIndex !== -1 && newTabs[tabAllIndex]) {
          const previousZeroTab = newTabs[0]!;
          newTabs[0] = newTabs[tabAllIndex];
          newTabs[tabAllIndex] = previousZeroTab;
        }
        return newTabs;
      });
      if (selectedTab === null) setSelectedTab(tabsData.tabs[0]?.name || null);
    }
    if (tabsData?.tabs?.length === 0) {
      createTabMutation
        .mutateAsync({
          body: {
            name: 'Все',
            columns: columns.map(column => column.id),
            filters: {},
          },
        })
        .then(tab => setTabs([{ id: 'Все', counter: tab.counter, title: 'Все' }]));
    }
  }, [tabsData]);

  useEffect(() => {
    const setupTable = async () => {
      if (activeTabData) {
        const { filters: tabFilters, columns: tabColumns } = activeTabData;
        setSelectedFilters(prev => ({
          ...prev,
          ...parseFiltersFromBackend(tabFilters),
        }));

        const newTableSettings = initialSettings.map((setting, i) => ({
          ...setting,
          isSelected: i === 0 || tabColumns.includes(setting.id),
        }));
        setTableSettings(newTableSettings);

        // const offersResponse = await tableDataInfiniteQuery.refetch({
        //   body: {
        //     filter: tabFilters || {},
        //     limit: OFFERS_LIMIT,
        //     offset: 0,
        //     search_value: '',
        //     //@ts-expect-error TODO: ошибка в схеме
        //     sort_field: sortColumn === '' ? '' : frontKeyBackendValueSorting[sortColumn],
        //     sort_order: sortOrder,
        //   },
        // });
        setOffersOffset(0);
        // if (offersResponse.offers) {
        //   setTableData(parseCommercialOfferTableData(offersResponse.offers));
        // }
      }
    };

    setupTable();
  }, [activeTabData]);

  useEffect(() => {
    if (tableDataInfiniteQuery.data?.pages) {
      setTableData(parseCommercialOfferTableData(tableDataInfiniteQuery.data.pages.flat()));
    }
  }, [
    tableDataInfiniteQuery.data,
    tableDataInfiniteQuery.data?.pages.length,
    tableDataInfiniteQuery.data?.pages[0],
  ]);

  useEffect(() => {
    if (selectedTab)
      updateTabMutation.mutateAsync({
        params: { path: { name: selectedTab } },
        body: {
          filters: parseFiltersToBackend(selectedFilters),
          columns: tableSettingsToBack,
          name: selectedTab,
        },
      });
  }, [tableSettingsToBack, selectedFilters]);

  useEffect(() => {
    const updateTabConfig = async () => {
      if (selectedTab) {
        setIsUpdateConfig(false);
        // const offersResponse = await getTableDataMutation.mutateAsync({
        //   body: {
        //     filter: parseFiltersToBackend(selectedFilters) || {},
        //     limit: OFFERS_LIMIT,
        //     offset: 0,
        //     search_value: searchAttributesValue,
        //     //@ts-expect-error TODO: ошибка в схеме
        //     sort_field: sortColumn === '' ? '' : frontKeyBackendValueSorting[sortColumn],
        //     sort_order: sortOrder,
        //   },
        // });
        setOffersOffset(0);
        // if (offersResponse.offers) {
        //   setTableData(parseCommercialOfferTableData(offersResponse.offers));
        // }
      }
    };
    if (isUpdateConfig) {
      updateTabConfig();
    }
  }, [
    isUpdateConfig,
    tableSettingsToBack,
    selectedFilters,
    selectedTab,
    tabs,
    searchAttributesValue,
  ]);

  useEffect(() => {
    if (debouncedSearchValue) {
      setIsUpdateConfig(true);
    }
  }, [debouncedSearchValue]);

  return (
    <CRMTable>
      {/* табы */}
      <CRMTable.Tabs
        tableTabsAtom={tableTabsAtom}
        selectedTabAtom={selectedTabAtom}
        tableSettings={tableSettings}
        filterSettings={selectedFilters}
        onCreateTab={async tabName => {
          await createTabMutation.mutateAsync({
            body: {
              name: tabName,
              counter: 0,
              filters: parseFiltersToBackend(selectedFilters),
              columns: tableSettingsToBack,
            },
          });
          setTabs([...tabs, { id: tabName, title: tabName }]);
        }}
        onUpdateTab={async (updatedTab, newName) => {
          await updateTabMutation.mutateAsync({
            params: { path: { name: updatedTab.id } },
            body: {
              filters: selectedFilters,
              columns: tableSettingsToBack,
              counter: updatedTab.counter,
              name: newName,
            },
          });
          setTabs(
            tabs.map(t =>
              t.id !== updatedTab.id
                ? t
                : { title: newName, id: newName, counter: updatedTab.counter }
            )
          );
        }}
        onDeleteTab={async deletedTab => {
          console.log(deletedTab);
          await deleteTabMutation.mutateAsync({ params: { path: { name: deletedTab.id } } });
          setTabs(tabs.filter(el => el.id !== deletedTab.id));
        }}
      />

      {/* поиск и кнопки фильтров, настройки таблиц */}
      <CRMTable.SearchAndActionsPanel
        searchValue={searchAttributesValue}
        onUpdate={searchAttributes}
        placeholder="Поиск по атрибутам"
        filtersButtonAction={() => setIsFiltersOpen(true)}
        tableSettingButtonAction={() => setIsTableSettingsOpen(true)}
        calendarButtonAction={calendarButtonAction}
      />

      <CRMTable.TableFiltersDisplayList
        filters={selectedFilters}
        deleteFilterHandle={filterName => {
          deleteFilterValueByName(filterName);
          setIsUpdateConfig(true);
        }}
        resetHandle={() => {
          resetFilters();
          setIsUpdateConfig(true);
        }}
      />

      <CRMTable.Table<ColumnsCommercialOffersTable>
        hideScrollbar
        className="commercial-offer-table"
        isLoading={tableDataInfiniteQuery.isFetching}
        onIntersecting={() => {
          tableDataInfiniteQuery.fetchNextPage();
        }}
        data={tableData}
        columns={columns}
        settings={tableSettings}
        updateSettings={settings => {
          setTableSettings(settings);
          return Promise.resolve();
        }}
        selectedIds={selectedIds}
        onSelectionChange={ids => {
          setSelectedIds(ids);
          setBottomPanelOpen(true);
        }}
        getRowDescriptor={(_, index) => {
          return {
            id: index.toString(),
            disabled: false,
            classNames: selectedOfferIndex === index ? ['table-select-row'] : [],
          };
        }}
        onRowClick={(item, index) => {
          onRowClick(item, index);
        }}
      />

      {isPending && (
        <div className="grow flex justify-center items-center">
          <Loader size="l" />
        </div>
      )}

      {/* сайдбар фильтров */}
      <CRMTable.FiltersSidebar
        filters={sidebarFilters}
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onConfirm={async newFiltersState => {
          setSelectedFilters(prev => ({ ...prev, ...newFiltersState }));
          setIsFiltersOpen(false);
          setIsUpdateConfig(true);
        }}
      />

      {/* сайдбар настроек таблицы */}
      <CRMTable.SettingsSideBar
        open={isTableSettingsOpen}
        tableSettings={tableSettings}
        resetSettings={initialSettings}
        onClose={() => setIsTableSettingsOpen(false)}
        onConfirm={dragTableSettings => {
          setIsTableSettingsOpen(false);
          setTableSettings(dragTableSettings);
          setIsUpdateConfig(true);
        }}
      />

      <CRMTable.BottomPanel
        open={bottomPanelOpen && !!selectedIds.length}
        selectedCount={selectedIds.length}
        onClose={() => {
          setSelectedIds([]);
          setBottomPanelOpen(false);
        }}
        onClickExport={() => {}}
        onClickUpdate={() => setUpdateCommercialOfferDialogOpen(true)}
        buttonsSlot={
          <Button
            view="normal"
            size="m"
            onClick={() => setAmountDialogOpen(true)}
          >
            <Icon data={Calculator} />
            Сумма
          </Button>
        }
      />

      <CRMTable.AmountDialog<ColumnsCommercialOffersTable>
        open={amountDialogOpen}
        rows={selectedRows}
        currencyColumnsNames={currencyColumnsNames}
        firstColumnName="Дата, номер"
        onClose={() => setAmountDialogOpen(false)}
      />

      <UpdateCommercialOfferDialog
        open={updateCommercialOfferDialogOpen}
        onClose={() => setUpdateCommercialOfferDialogOpen(false)}
        onClickUpdate={editValues => {
          console.log(editValues);
          setUpdateCommercialOfferDialogOpen(false);
        }}
      />
    </CRMTable>
  );
};
