import { yupRu } from '@shared/config';
import { MAX_FILE_SIZE } from '@shared/consts';
import { formatBytes } from '@shared/lib';
import { FieldWithPreviewMultiFileItem } from '@shared/ui';

import { ApplicationStatus, ProducementStatus } from '../types';

const optionalOperatingParameters = {
  Себестоимость: yupRu.number(),
  'Маржа начальная': yupRu.number(),
  'Файл расчета процедуры': yupRu.mixed<File>().fileSizeLessThan(MAX_FILE_SIZE),
  'Файл заявки': yupRu.mixed<File>().fileSizeLessThan(MAX_FILE_SIZE),
  'Предлагаемая специалистом минимальная цена': yupRu.number(),
  'Обоснование предложения минимальной цены': yupRu.string(),
  'Комментарий согласования': yupRu.string(),
  'Минимальная цена': yupRu.number(),
  'Маржа минимальная': yupRu.number(),
};

const requiredOperatingParameters = {
  ...optionalOperatingParameters,
  Себестоимость: optionalOperatingParameters['Себестоимость'].required(),
  'Файл расчета процедуры': optionalOperatingParameters['Файл расчета процедуры'].required(),
  'Файл заявки': optionalOperatingParameters['Файл заявки'].required(),
  'Предлагаемая специалистом минимальная цена':
    optionalOperatingParameters['Предлагаемая специалистом минимальная цена'].required(),
  'Минимальная цена': optionalOperatingParameters['Минимальная цена'].required(),
};

export const tenderSchema = yupRu.object({
  Ответственный: yupRu.string().required(),
  Наименование: yupRu.string(),
  'Статус заявки': yupRu
    .string()
    .oneOf<ApplicationStatus>([
      'Подача',
      'Ожидание',
      'Выигран',
      'Проигран',
      'Отклонение',
      'Не подано',
      'Отказ',
      'Ожидание',
    ])
    .required(),
  Отклонение: yupRu
    .string()
    .oneOf<
      'По объекту закупки' | 'По информации и документам'
    >(['По информации и документам', 'По объекту закупки']),
  'Причина отказа': yupRu
    .string()
    .oneOf<'Не успели' | 'Не рентабельно' | 'Иная причина'>([
      'Не успели',
      'Не рентабельно',
      'Иная причина',
    ])
    .when('Статус заявки', {
      is: (applicationStatus: ApplicationStatus) => applicationStatus === 'Отказ',
      then: schema => schema.required(),
    }),
  'Статус закупки': yupRu
    .string()
    .oneOf<ProducementStatus>([
      'В процессе',
      'Состоялась',
      'Не состоялась',
      'Отменена',
      'Не известно',
    ])
    .required(),
  'Причина почему не состоялась?': yupRu
    .string()
    .oneOf<'Не подано заявок' | 'Всех отклонили'>(['Всех отклонили', 'Не подано заявок']),
  'Основание и причина отклонения': yupRu.string(),
  'Номер извещения': yupRu
    .string()
    .test({
      test: value => !isNaN(Number(value)),
      message: 'Должен состоять из цифр',
    })
    .required(),
  'Ссылка на процедуру': yupRu.string().url(),
  'Электронная торговая площадка': yupRu.string(),
  'В рамках ГОЗ по 275-ФЗ': yupRu.string(),
  ФЗ: yupRu.string().required(),
  'Закупка централизованная или совместная?': yupRu.string().oneOf<'Да' | 'Нет'>(['Да', 'Нет']),
  Заказчики: yupRu.array(yupRu.string().required()),
  'Приоритет заказчика': yupRu.number().oneOf<1 | 2 | 3 | 4 | 5>([1, 2, 3, 4, 5]),
  'Объект торгов': yupRu.string().required(),
  'Начальная цена': yupRu.number().required(),
  'Участвуем от': yupRu.string().required(),
  Комментарий: yupRu.string(),
  Организатор: yupRu.string(),
  'Способ проведения': yupRu
    .string()
    .oneOf<
      'Электронный аукцион' | 'Запрос котировок' | 'Закупка ЕП' | 'Конкурс'
    >(['Электронный аукцион', 'Запрос котировок', 'Закупка ЕП', 'Конкурс'])
    .required(),
  'Ограничения, преимущества и доп требования': yupRu.string().required(),
  'Обеспечение заявки': yupRu.number().required(),
  'Окончание подачи': yupRu.string().required(),
  'Проведение процедуры': yupRu.string().required(),
  'Подведение итогов': yupRu.string().required(),
  Документация: yupRu.array(yupRu.mixed<FieldWithPreviewMultiFileItem>().required()).test({
    name: 'file-sizes',
    test: function (value) {
      const totalSize = value?.reduce((size, { file }) => size + file.size, 0);

      if (typeof totalSize === 'number' && totalSize > MAX_FILE_SIZE) {
        return this.createError({
          message: `Общий вес файлов (${formatBytes(totalSize)}) превыщает ${formatBytes(MAX_FILE_SIZE)}`,
        });
      }

      return true;
    },
  }),
  Разъяснения: yupRu.array(yupRu.mixed<FieldWithPreviewMultiFileItem>().required()).test({
    name: 'file-sizes',
    test: function (value) {
      const totalSize = value?.reduce((size, { file }) => size + file.size, 0);

      if (typeof totalSize === 'number' && totalSize > MAX_FILE_SIZE) {
        return this.createError({
          message: `Общий вес файлов (${formatBytes(totalSize)}) превыщает ${formatBytes(MAX_FILE_SIZE)}`,
        });
      }

      return true;
    },
  }),
  Протоколы: yupRu.array(yupRu.mixed<FieldWithPreviewMultiFileItem>().required()).test({
    name: 'file-sizes',
    test: function (value) {
      const totalSize = value?.reduce((size, { file }) => size + file.size, 0);

      if (typeof totalSize === 'number' && totalSize > MAX_FILE_SIZE) {
        return this.createError({
          message: `Общий вес файлов (${formatBytes(totalSize)}) превыщает ${formatBytes(MAX_FILE_SIZE)}`,
        });
      }

      return true;
    },
  }),
  'Регион поставки': yupRu.string().required(),
  'Адрес поставки': yupRu.string().required(),
  'Срок поставки': yupRu.string().required(),
  Согласовано: yupRu.boolean().required(),
  'На согласовании': yupRu.boolean().required(),

  //   Рабочие параметры
  ...optionalOperatingParameters,

  //   Итоговые параметры
  'Итоговая цена': yupRu.number().when('Статус заявки', {
    is: (applicationStatus: ApplicationStatus, procurementStatus: ProducementStatus) =>
      procurementStatus === 'Состоялась' ||
      applicationStatus === 'Выигран' ||
      applicationStatus === 'Проигран',
    then: schema => schema.required(),
  }),
  'Процент снижения': yupRu.number(),
  'Маржа итоговая': yupRu.number(),
  Победитель: yupRu.string().when(['Статус заявки'], {
    is: (applicationStatus: ApplicationStatus, procurementStatus: ProducementStatus) =>
      procurementStatus === 'Состоялась' ||
      applicationStatus === 'Выигран' ||
      applicationStatus === 'Проигран',
    then: schema => schema.required(),
  }),
  'Участники (если имеются)': yupRu.array(yupRu.string().required()),
});

export type TenderSchema = yupRu.InferType<typeof tenderSchema>;

export const requiredOperatingParametersSchema = yupRu.object(requiredOperatingParameters);

export type RequiredOperatingParametersSchema = yupRu.InferType<
  typeof requiredOperatingParametersSchema
>;
