export function generateTitle({
  customers,
  supplyObject,
  uniqueNumber,
}: {
  supplyObject?: string;
  customers?: string[];
  uniqueNumber?: string;
}) {
  if (
    Array.isArray(customers) &&
    !!customers.length &&
    typeof supplyObject === 'string' &&
    typeof uniqueNumber === 'string'
  ) {
    const customer = customers[0]!;

    return `${customer} / ${supplyObject} / ${uniqueNumber}`;
  }
}
