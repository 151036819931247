import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CalendarDrawer, CommercialOfferDrawer, CommercialOffersTable } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

export function CommercialOffers() {
  const { navigateTo } = useNavigateTo();

  const [createOfferOpen, setCreateOfferOpen] = useState(false);

  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);

  const offerId = useParams()[URLS.processes.commercialOffersDynamicParams.id];

  return (
    <>
      <HeroWithButton
        title="Коммерческие предложения"
        buttonOnClick={() => setCreateOfferOpen(true)}
      />
      <CommercialOffersTable
        selectedOfferIndex={offerId ? Number(offerId) : null}
        onRowClick={(_, index) => navigateTo(`${URLS.processes.commercialOffers}/${index}`)}
        calendarButtonAction={() => {
          setCalendarDrawerOpen(true);
        }}
      />
      {offerId ? (
        <CommercialOfferDrawer
          id={offerId}
          type="edit"
          open={!!offerId}
          onClose={() => navigateTo(URLS.processes.commercialOffers)}
        />
      ) : (
        <CommercialOfferDrawer
          open={createOfferOpen}
          onClose={() => setCreateOfferOpen(false)}
          type="create"
        />
      )}
      <CalendarDrawer
        open={calendarDrawerOpen}
        onClose={() => setCalendarDrawerOpen(false)}
        entityType="КП"
      />
    </>
  );
}
