import { Breadcrumbs, BreadcrumbsLinkItem, BreadcrumbsProps } from '@gravity-ui/uikit';

import { cn, useNavigateTo } from '../lib';

export const CRMBreadCrumbs = ({
  items,
  className,
}: Omit<
  BreadcrumbsProps<BreadcrumbsLinkItem>,
  'lastDisplayedItemsCount' | 'firstDisplayedItemsCount'
>) => {
  const { navigateTo } = useNavigateTo();

  const preparedItems: BreadcrumbsLinkItem[] = items.map(item => {
    return {
      ...item,
      action: e => {
        e.preventDefault();
        navigateTo(item.href);
      },
    };
  });

  return (
    <Breadcrumbs
      className={cn('px-5 pb-2 pt-2', className)}
      items={preparedItems}
      firstDisplayedItemsCount={1}
      lastDisplayedItemsCount={2}
    />
  );
};
