import { Text } from '@gravity-ui/uikit';

import { ProcessItem } from '@entities';

import { PROCESSES_MOCK } from '../model';

export function CommercialOfferRelations() {
  return (
    <div className="flex flex-col px-8 overflow-y-scroll hide-scrollbar">
      {PROCESSES_MOCK.map(({ processName, processes }, index) => (
        <div
          key={index}
          className="pb-8 flex flex-col"
        >
          <Text
            variant="subheader-2"
            color="primary"
            className="mb-2"
          >
            {processName}
          </Text>
          {processes.map(({ dateAndNumber, link, name }, index) => (
            <ProcessItem
              key={index}
              dateAndNumber={dateAndNumber}
              link={link}
              name={name}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
