import { useToaster } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

import { tenderFormAtom, TenderSchema } from '@features';
import { RightSidebar } from '@shared/ui';

import { useWatchUniqueNumber } from '../lib';
import { TenderLayout } from './TenderLayout';

interface CreateTenderProps {
  open: boolean;
  onClose: () => void;
  afterCreate?: (tender: TenderSchema) => void;
}

export function CreateTender({ open, onClose, afterCreate }: CreateTenderProps) {
  const { add } = useToaster();

  const [underApproval, setUnderApproval] = useState(false);
  const tenderForm = useAtomValue(tenderFormAtom);

  const [uniqueNumber, setUniqueNumber] = useState<string>();
  useWatchUniqueNumber(uniqueNumber);

  return (
    <TenderLayout
      formProps={{
        defaultValues: {
          Согласовано: false,
          'Статус заявки': 'Подача',
          'Статус закупки': 'В процессе',
        },
        editableSettings: {
          'Статус заявки': { whenDirty: false },
          'Статус закупки': { whenDirty: false },
        },
        disabled: underApproval,
        initialEditable: true,
        allowedApplicationStatuses: () => ['Подача'],
        allowedProcurementStatuses: () => ['В процессе'],
        onValid: values => {
          console.log('@values', values);
          onClose();
          afterCreate?.(values);
        },
        onInvalid: errors => console.log('@errors', errors),
        onValuesChange: values => {
          setUniqueNumber(values['Номер извещения']);
        },
        approvalButtonProps: {
          onClick: () => {
            tenderForm?.handleSubmit(
              () => {
                // TODO: делаем запрос на создание нового КП
                // после создания редиректим в чат

                setUnderApproval(true);

                add({
                  theme: 'success',
                  name: 'tender-under-approval',
                  title: 'Отправлено на согласование',
                });
              },
              e => console.log(e)
            )();
          },
        },
      }}
      renderForm={formComponent => (
        <RightSidebar
          open={open}
          onClose={onClose}
        >
          <RightSidebar.Header
            title="Создание торгов"
            onClose={onClose}
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
