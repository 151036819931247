import { formatToPrice } from '@shared/lib';

import {
  BooleanFilterValueType,
  CRMTableFilters,
  DateFilterValueType,
  NumericFilterValueType,
} from '../../model';

const convertCurrencyFiltersToDisplay = (value: NumericFilterValueType) => {
  if (!value) return '';
  const { from, to } = value;
  if (from && to) {
    if (from === to) {
      return formatToPrice(from);
    } else {
      return `${formatToPrice(from)} - ${formatToPrice(to)}`;
    }
  }
  if (from) {
    return `от ${formatToPrice(from)}`;
  }
  if (to) {
    return `до ${formatToPrice(to!)}`;
  }
  return '';
};

const convertDateFiltersToDisplay = (value: DateFilterValueType) => {
  if (!value) return '';
  const { from, to } = value;
  if (from && to) {
    if (from === to) {
      return from;
    } else {
      return `${from} - ${to}`;
    }
  }
  if (from) {
    return `от ${from}`;
  }
  if (to) {
    return `до ${to}`;
  }
  return '';
};

const convertBooleanFiltersToDisplay = (value: BooleanFilterValueType) =>
  value === 'true' ? 'Да' : 'Нет';

export const filtersToDisplayFormat = <T extends string[]>(
  filter: CRMTableFilters<T>[T[number]]
) => {
  if ((filter.type === 'select' && !filter.value?.length) || !filter.value) {
    return null;
  }

  let displayText = '';
  switch (filter.type) {
    case 'select':
      displayText = filter.value.join(', ');
      break;
    case 'date':
    case 'dateWithTime':
      displayText = convertDateFiltersToDisplay(filter.value);
      break;
    case 'currency':
    case 'priority':
    case 'margin':
    case 'number':
      displayText = convertCurrencyFiltersToDisplay(filter.value);
      break;
    case 'boolean':
      displayText = convertBooleanFiltersToDisplay(filter.value);
      break;
    case 'search':
      displayText = filter.value;
      break;
  }

  return displayText;
};
