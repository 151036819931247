import { useInfiniteQuery } from '@tanstack/react-query';

import { $mock, bodyRequestType, mockClient } from '@shared/api';

const useGetCommercialOfferQuery = (id: string) =>
  $mock.useQuery('get', '/offer/{id}', { params: { path: { id: id } } });

const updateCommercialOfferMutation = () => $mock.useMutation('put', '/offer/{id}');

const createCommercialOfferMutation = () => $mock.useMutation('post', '/offer');

function useGetAllCommericalOffersInfiniteQuery(
  body: bodyRequestType<'post', '/offer/all'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/offer/all', body],
    queryFn: async ({ pageParam }) => {
      // const { data } = await fetchClient.POST('/offer/all', {
      const { data } = await mockClient.POST('/offer/all', {
        body: { ...body, offset: pageParam },
      });

      return data?.offers || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}

export const $commercialOfferHooks = {
  getAll: useGetAllCommericalOffersInfiniteQuery,
  getById: useGetCommercialOfferQuery,
  update: updateCommercialOfferMutation,
  create: createCommercialOfferMutation,
};
