import { nanoid } from 'nanoid';

import { objectKeysSafeToArray } from '@shared/lib';

import { fetchClient } from '../client';

const getAWSCredentials = async () =>
  await fetchClient
    .GET('/user/aws-presigned')
    .catch(error => console.log(error))
    .then(data => data?.data);

export const uploadFileToS3 = async (file: File) => {
  const data = await getAWSCredentials();
  if (!data) throw new Error('Не получены credentials от AWS для s3');
  const uuid = nanoid(25);
  const formData = new FormData();
  data['key'] = data.key + '/' + uuid + '/' + file.name;
  for (const key of objectKeysSafeToArray(data).sort()) {
    if (key !== 'url') formData.append(key, data[key]!);
  }
  formData.append('file', file);
  return {
    key: data.key,
    fetch: fetch(data.url!, {
      body: formData,
      method: 'POST',
    }),
  };
};
