import { Loader } from '@gravity-ui/uikit';
import { Navigate, useParams } from 'react-router-dom';

import {
  SYSTEM_HANDBOOK_TO_RUSSIAN,
  SystemHandbookName,
  useGetSystemHandbookQuery,
} from '@entities';
import { SystemHandbookTable } from '@widgets';
import { URLS } from '@shared/consts';
import { CRMBreadCrumbs, HeroWithButton } from '@shared/ui';

export function SystemHandbookGuard() {
  const handbookName = useParams()[URLS.handbook.dynamicParams.handbookName] as
    | SystemHandbookName
    | undefined;

  if (!handbookName || !(handbookName in SYSTEM_HANDBOOK_TO_RUSSIAN)) {
    return (
      <Navigate
        to={URLS.handbook.default}
        replace
      />
    );
  }

  return <SystemHandbook handbookName={handbookName} />;
}

interface SystemHandbookProps {
  handbookName: SystemHandbookName;
}

function SystemHandbook({ handbookName }: SystemHandbookProps) {
  const title = SYSTEM_HANDBOOK_TO_RUSSIAN[handbookName];

  const { data } = useGetSystemHandbookQuery(handbookName);

  return (
    <>
      <CRMBreadCrumbs
        items={[
          {
            text: 'Справочники',
            href: URLS.handbook.default,
          },
          {
            text: title,
            href: `${URLS.handbook.system}/${handbookName}`,
          },
        ]}
      />
      <HeroWithButton title={title} />
      {data ? <SystemHandbookTable data={data} /> : <Loader size="l" />}
    </>
  );
}
