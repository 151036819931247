import { LabelProps } from '@gravity-ui/uikit';

import { ContractStatuses } from '../model/types/statuses';

export const convertContractStatusToLabelTheme = (
  status: ContractStatuses
): LabelProps['theme'] => {
  switch (status) {
    case 'Проект':
      return 'normal';
    case 'Заключен':
      return 'info';
    case 'Исполнен':
      return 'success';
    case 'Расторгнут':
      return 'danger';
    case 'Рассчитан':
      return 'utility';
  }
};
