import { User } from '@entities';
import { LOCAL_STORAGE } from '@shared/consts';
import { customStorage } from '@shared/lib';

export const OPTIONS_MOCK = {
  Ответственный: [
    { value: '1', content: 'Контент А.В.' },
    {
      value: customStorage<User>().getItem(LOCAL_STORAGE.user, null)?.id,
      content: customStorage<User>().getItem(LOCAL_STORAGE.user, null)?.name_initials,
    },
  ],
  'Статус заявки': [
    { value: 'Подача', content: 'Подача', preview: 'normal' } as const,
    { value: 'Ожидание', content: 'Ожидание', preview: 'normal' } as const,
    { value: 'Выигран', content: 'Выигран', preview: 'success' } as const,
    { value: 'Проигран', content: 'Проигран', preview: 'danger' } as const,
    { value: 'Отклонение', content: 'Отклонение', preview: 'danger' } as const,
    { value: 'Не подано', content: 'Не подано', preview: 'clear' } as const,
    { value: 'Отказ', content: 'Отказ', preview: 'danger' } as const,
  ],
  'Причина отказа': [
    { value: 'Не успели', content: 'Не успели' },
    { value: 'Не рентабельно', content: 'Не рентабельно' },
    { value: 'Иная причина', content: 'Иная причина' },
  ],
  'Статус закупки': [
    { value: 'В процессе', content: 'В процессе', preview: 'normal' } as const,
    { value: 'Состоялась', content: 'Состоялась', preview: 'success' } as const,
    { value: 'Не состоялась', content: 'Не состоялась', preview: 'danger' } as const,
    { value: 'Отменена', content: 'Отменена', preview: 'danger' } as const,
    { value: 'Не известно', content: 'Не известно', preview: 'clear' } as const,
  ],
  'Электронная торговая площадка': [{ value: 'АСТ ГОЗ', content: 'АСТ ГОЗ' }],
  'В рамках ГОЗ по 275-ФЗ': [
    { value: 'НЕГОЗ', content: 'НЕГОЗ' },
    { value: 'ГОЗ', content: 'ГОЗ' },
  ],
  ФЗ: [
    { value: '1', content: 'ФЗ 1' },
    { value: '2', content: 'ФЗ 2' },
    { value: '3', content: 'ФЗ 3' },
  ],
  'Закупка централизованная или совместная?': [
    { value: 'Да', content: 'Да' },
    { value: 'Нет', content: 'Нет' },
  ],
  Заказчики: [
    { value: '1', content: 'Заказчик 1' },
    { value: '2', content: 'Заказчик 2' },
    { value: '3', content: 'Заказчик 3' },
  ],
  'Приоритет заказчика': [
    { value: '1', content: '1' },
    { value: '2', content: '2' },
    { value: '3', content: '3' },
    { value: '4', content: '4' },
    { value: '5', content: '5' },
  ],
  'Объект торгов': [
    { value: '1', content: 'Объект торгов 1' },
    { value: '2', content: 'Объект торгов 2' },
    { value: '3', content: 'Объект торгов 3' },
    { value: '4', content: 'Объект торгов 4' },
    { value: '5', content: 'Объект торгов 5' },
  ],
  'Участвуем от': [
    { value: '1', content: 'Участвует от 1' },
    { value: '2', content: 'Участвует от 2' },
    { value: '3', content: 'Участвует от 3' },
  ],
  'Способ проведения': [
    { value: 'Электронный аукцион', content: 'Электронный аукцион' },
    { value: 'Запрос котировок', content: 'Запрос котировок' },
    { value: 'Закупка ЕП', content: 'Закупка ЕП' },
    { value: 'Конкурс', content: 'Конкурс' },
  ],
  'Ограничения, преимущества и доп требования': [
    { value: '1', content: 'Ограничение, преимущество и доп. требование 1' },
    { value: '2', content: 'Ограничение, преимущество и доп. требование 2' },
    { value: '3', content: 'Ограничение, преимущество и доп. требование 3' },
  ],
  'Регион поставки': [
    { value: '1', content: 'Регион поставки 1' },
    { value: '2', content: 'Регион поставки 2' },
    { value: '3', content: 'Регион поставки 3' },
  ],
  Победитель: [
    { value: '1', content: 'Победитель 1' },
    { value: '2', content: 'Победитель 2' },
    { value: '3', content: 'Победитель 3' },
  ],
  'Участники (если имеются)': [
    { value: '1', content: 'Участник 1' },
    { value: '2', content: 'Участник 2' },
    { value: '3', content: 'Участник 3' },
  ],
  Организатор: [
    { value: '1', content: 'Организатор 1' },
    { value: '2', content: 'Организатор 2' },
    { value: '3', content: 'Организатор 3' },
  ],
  Отклонение: [
    { value: 'По информации и документам', content: 'По информации и документам' },
    { value: 'По объекту закупки', content: 'По объекту закупки' },
  ],
  'Причина почему не состоялась?': [
    { value: 'Не подано заявок', content: 'Не подано заявок' },
    { value: 'Всех отклонили', content: 'Всех отклонили' },
  ],
};
