import { Outlet } from 'react-router-dom';

import { ChatSidebar } from '@widgets';

export function MessengerLayout() {
  return (
    <div className="flex h-[calc(100vh-53px)]">
      <ChatSidebar />
      <Outlet />
    </div>
  );
}
