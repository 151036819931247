import { useRef, useState } from 'react';
import { ControllerFieldState, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { useClickOutside } from './useClickOutside';

interface UseFormWithPreviewParams<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  initialEditable?: boolean;
  disabled?: boolean;
}

export function useFieldsWithPreview<TFieldValues extends FieldValues>({
  form,
  initialEditable = false,
  disabled = false,
}: UseFormWithPreviewParams<TFieldValues>) {
  const [selectedFieldPath, setSelectedFieldPath] = useState<Path<TFieldValues> | null>(null);

  const fieldRefs = useRef<Partial<Record<Path<TFieldValues>, Element>>>({});

  useClickOutside(
    () => (selectedFieldPath ? fieldRefs.current[selectedFieldPath] : null),
    () => {
      if (selectedFieldPath) setSelectedFieldPath(null);
    }
  );

  const createFieldRef = (path: Path<TFieldValues>) => (node: Element | null) => {
    if (node) fieldRefs.current[path] = node;
  };

  const selectField = (path: Path<TFieldValues>) => {
    const fieldState = form.getFieldState(path);
    if (selectedFieldPath && fieldState.error) return;
    setSelectedFieldPath(path);
  };

  const resetSelectedField = () => {
    setSelectedFieldPath(null);
  };

  const isFieldEditable =
    (
      path: Path<TFieldValues>,
      {
        whenDirty = initialEditable,
        whenInvalid = true,
        whenSelected = true,
      }: {
        whenDirty?: boolean;
        whenInvalid?: boolean;
        whenSelected?: boolean;
      } = {}
    ) =>
    (fieldState: ControllerFieldState) =>
      !disabled &&
      ((whenDirty && !fieldState.isDirty) ||
        (whenInvalid && fieldState.invalid) ||
        (whenSelected && selectedFieldPath === path));

  return {
    createFieldRef,
    selectedFieldPath,
    isFieldEditable,
    selectField,
    resetSelectedField,
  };
}
