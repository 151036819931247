import { DatePicker } from '@gravity-ui/date-components';
import { dateTime } from '@gravity-ui/date-utils';
import { Xmark } from '@gravity-ui/icons';
import { Button, Dialog, Icon, Select, TextInput } from '@gravity-ui/uikit';
import { ReactNode, useMemo, useState } from 'react';

import { objectKeysSafeToArray } from '@shared/lib';
import { ExpandList, MyFoldableListItem } from '@shared/ui';

import {
  EditableColumnsCommercialOffersTable,
  EditableFieldsValues,
  EditValueType,
} from '../../model';

import './index.sass';

const myFoldableListItemProps = ({
  title,
  listItem,
}: {
  title: string;
  listItem: ReactNode;
}): MyFoldableListItem => ({
  listItem: <div className="py-1.5 pl-8 pr-2 w-full">{listItem}</div>,
  title,
  arrowPosition: 'start',
  arrowSize: 16,
  buttonProps: {
    className: 'justify-start pl-2',
    size: 'xl',
    pin: 'brick-brick',
  },
  textProps: {
    className: '!ml-0',
    variant: 'subheader-1',
  },
  arrowClassname: 'text-text-primary',
  className: 'shadow-outline-bottom',
  keepMounted: true,
});

const initialState: EditableFieldsValues<EditableColumnsCommercialOffersTable> = {
  Ответственный: { type: 'select', value: [] },
  'Статус КП': { type: 'select', value: [] },
  'Регион поставки': { type: 'select', value: [] },
  'Адрес поставки': { type: 'text', value: '' },
  'Срок поставки': { type: 'text', value: '' },
  Поставщик: { type: 'select', value: [] },
};

interface UpdateCommercialOfferDialogProps {
  open: boolean;
  onClose: () => void;
  onClickUpdate: (editValues: EditableFieldsValues<EditableColumnsCommercialOffersTable>) => void;
}

export function UpdateCommercialOfferDialog({
  onClose,
  onClickUpdate,
  open,
}: UpdateCommercialOfferDialogProps) {
  const [editValues, setEditValues] = useState<
    EditableFieldsValues<EditableColumnsCommercialOffersTable>
  >(() => structuredClone(initialState));

  const updateValue = (
    name: EditableColumnsCommercialOffersTable[number],
    newValue: EditValueType['value']
  ) => {
    setEditValues(prev => {
      const updatedValues = structuredClone(prev);
      updatedValues[name].value = newValue;
      return updatedValues;
    });
  };

  const items = useMemo<MyFoldableListItem[]>(() => {
    return objectKeysSafeToArray(editValues).map(key => {
      const editValue = editValues[key];

      switch (editValue.type) {
        case 'date':
          return myFoldableListItemProps({
            title: key,
            listItem: (
              <DatePicker
                size="l"
                placeholder="ДД.ММ.ГГГГ"
                className="w-full"
                value={editValue.value ? dateTime({ input: editValue.value }) : null}
                onUpdate={value => updateValue(key, value ? value?.valueOf() : null)}
              />
            ),
          });
        case 'select':
          return myFoldableListItemProps({
            title: key,
            listItem: (
              <Select
                size="l"
                width="max"
                placeholder="Выбрать"
                options={[
                  { value: '1', content: 'Контент 1' },
                  { value: '2', content: 'Контент 2' },
                ]}
                value={editValue.value}
                onUpdate={newSelects => updateValue(key, newSelects)}
              />
            ),
          });
        case 'text':
          return myFoldableListItemProps({
            title: key,
            listItem: (
              <TextInput
                size="l"
                className="w-full"
                placeholder="Ввод"
                value={editValue.value || ''}
                onUpdate={text => updateValue(key, text)}
              />
            ),
          });
      }
    });
  }, [editValues]);

  const resetEditValues = () => {
    setEditValues(structuredClone(initialState));
  };

  const closeHandle = () => {
    onClose();
    resetEditValues();
  };

  return (
    <Dialog
      open={open}
      onClose={closeHandle}
      aria-labelledby="update-row-dialog"
      disableBodyScrollLock
      size="s"
      className="update-row-dialog max-h-[720px]"
      hasCloseButton={false}
    >
      <Dialog.Header
        caption="Изменить значения"
        className="flex items-center justify-between"
        insertAfter={
          <Button
            view="flat"
            size="l"
            onClick={closeHandle}
          >
            <Icon data={Xmark} />
          </Button>
        }
      />
      <Dialog.Body>
        <ExpandList items={items} />
      </Dialog.Body>
      <Dialog.Footer
        textButtonApply="Изменить"
        textButtonCancel="Отменить"
        onClickButtonApply={() => {
          onClickUpdate(editValues);
          resetEditValues();
        }}
        onClickButtonCancel={closeHandle}
      />
    </Dialog>
  );
}
