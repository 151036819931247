export function calculateMargin({
  costPrice,
  offerPrice,
}: {
  offerPrice?: number;
  costPrice?: number;
}) {
  if (typeof offerPrice === 'number' && typeof costPrice === 'number')
    return ((offerPrice - costPrice) * 100) / offerPrice;
}
