import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { useFieldsWithPreview } from '@shared/lib';
import { FieldWithPreview } from '@shared/ui';

import { DEFAULT_VALUES } from '../constants';
import { CustomerSchema, customerSchema, OPTIONS_MOCK } from '../model';

interface CustomerFormProps {
  onValid?: SubmitHandler<CustomerSchema>;
  onInvalid?: SubmitErrorHandler<CustomerSchema>;
  footer?: ReactNode;
}

// TODO: не все поля отображены, требуется дизайн
export function CustomerForm({ onValid = () => {}, onInvalid, footer }: CustomerFormProps) {
  const customerForm = useForm<CustomerSchema>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(customerSchema),
    mode: 'all',
  });

  const { createFieldRef, isFieldEditable, resetSelectedField, selectField, selectedFieldPath } =
    useFieldsWithPreview({ form: customerForm, initialEditable: true });

  const HAS_RIGHT_TO_CHANGE_RESPONSIBLE = false; // TODO: убрать моки

  return (
    <form onSubmit={customerForm.handleSubmit(onValid, onInvalid)}>
      <FieldWithPreview>
        <FieldWithPreview.Text
          ref={createFieldRef('name')}
          control={customerForm.control}
          path="name"
          name="Краткое наименование"
          edit={isFieldEditable('name')}
          onFieldSelect={() => selectField('name')}
        />
        <FieldWithPreview.Text
          ref={createFieldRef('full_name')}
          control={customerForm.control}
          path="full_name"
          name="Полное наименование"
          edit={isFieldEditable('full_name')}
          onFieldSelect={() => selectField('full_name')}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('inn')}
          control={customerForm.control}
          path="inn"
          name="ИНН"
          edit={isFieldEditable('inn')}
          onFieldSelect={() => selectField('inn')}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('kpp')}
          control={customerForm.control}
          path="kpp"
          name="КПП"
          edit={isFieldEditable('kpp')}
          onFieldSelect={() => selectField('kpp')}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('customer_type')}
          control={customerForm.control}
          edit={isFieldEditable('customer_type')}
          name="Тип"
          options={OPTIONS_MOCK['customer_type']}
          path="customer_type"
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('customer_type')}
          required
        />
        <FieldWithPreview.TextArea
          ref={createFieldRef('comment')}
          control={customerForm.control}
          path="comment"
          name="Комментарий"
          edit={isFieldEditable('comment')}
          onFieldSelect={() => selectField('comment')}
          wrapperProps={{ textAlign: 'start' }}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('customer_level')}
          control={customerForm.control}
          edit={isFieldEditable('customer_level')}
          name="Уровень"
          options={OPTIONS_MOCK['customer_level']}
          path="customer_level"
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('customer_level')}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('higher_agency_id')}
          control={customerForm.control}
          edit={isFieldEditable('higher_agency_id')}
          name="Вышестоящее ведомство"
          options={OPTIONS_MOCK['higher_agency_id']}
          path="higher_agency_id"
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('higher_agency_id')}
          required
        />
        <FieldWithPreview.Select
          ref={createFieldRef('responsible_id')}
          control={customerForm.control}
          edit={HAS_RIGHT_TO_CHANGE_RESPONSIBLE}
          name="Ответственный"
          options={OPTIONS_MOCK['responsible_id']}
          path="responsible_id"
          resetSelectedField={resetSelectedField}
          selectedFieldPath={selectedFieldPath}
          onFieldSelect={() => selectField('responsible_id')}
          required
        />
        <FieldWithPreview.Text
          ref={createFieldRef('unique_number')}
          control={customerForm.control}
          path="unique_number"
          name="Уникальный номер"
          edit={isFieldEditable('unique_number')}
          onFieldSelect={() => selectField('unique_number')}
          required
        />
      </FieldWithPreview>
      {footer}
    </form>
  );
}
