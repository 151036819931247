import { Dispatch, SetStateAction, useState } from 'react';

import { CRMTable } from '@features';
import {
  ColumnsFilters,
  CRMTableColumns,
  CRMTableData,
  CRMTableFilters,
  CRMTableSettings,
  FilterBooleanType,
  FilterSearchType,
  FilterSelectType,
} from '@features/table';

interface CustomerHandbookTableLayoutProps<Keys extends string[]> {
  selectedFilters: CRMTableFilters<Keys>;
  setSelectedFilters: Dispatch<SetStateAction<CRMTableFilters<Keys>>>;
  sidebarFilters: Record<Keys[number], FilterSelectType | FilterBooleanType | FilterSearchType>;
  deleteFilterValueByName: (name: Keys[number]) => void;
  resetFilters: () => void;
  setIsUpdateConfig: Dispatch<SetStateAction<boolean>>;
  tableColumnsWithFilter: Record<Keys[number], ColumnsFilters>;
  data: CRMTableData<Keys>;
  columns: CRMTableColumns<Keys>;
  searchValue: string;
  onSearchValueUpdate: (value: string) => void;
  onRowClick: ((item: Record<Keys[number], string>, index: number) => void) | undefined;
  // onClickDelete: (index: number) => void;
  // updateColumnsSortValuesBySettings: (newSettings: CRMTableSettings<Keys>) => void;
  onIntersecting?: () => void;
  isFetching: boolean;
}

export const CustomHandbookTableLayout = <Keys extends string[]>({
  sidebarFilters,
  selectedFilters,
  setSelectedFilters,
  resetFilters,
  deleteFilterValueByName,
  setIsUpdateConfig,
  columns,
  data,
  searchValue,
  onSearchValueUpdate,
  onRowClick,
  isFetching,
  // onClickDelete,
  // updateColumnsSortValuesBySettings,
  onIntersecting,
}: CustomerHandbookTableLayoutProps<Keys>) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const initialSettings: CRMTableSettings<Keys> = columns.map(col => ({
    id: col.id,
    isSelected: true,
  }));

  const [isTableSettingsOpen, setIsTableSettingsOpen] = useState(false);

  const [tableSettings, setTableSettings] = useState<CRMTableSettings<Keys>>(() =>
    structuredClone(initialSettings)
  );

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  return (
    <CRMTable>
      <CRMTable.SearchAndActionsPanel
        searchValue={searchValue}
        onUpdate={onSearchValueUpdate}
        placeholder="Поиск по атрибутам"
        filtersButtonAction={() => setIsFiltersOpen(true)}
        tableSettingButtonAction={() => setIsTableSettingsOpen(true)}
      />

      <CRMTable.TableFiltersDisplayList
        filters={selectedFilters}
        deleteFilterHandle={filterName => {
          deleteFilterValueByName(filterName);
          setIsUpdateConfig(true);
        }}
        resetHandle={() => {
          resetFilters();
          setIsUpdateConfig(true);
        }}
      />

      <CRMTable.Table<Keys>
        className={`table-without-checkbox`}
        hideScrollbar
        data={data}
        onIntersecting={onIntersecting}
        isLoading={isFetching}
        columns={columns}
        settings={tableSettings}
        updateSettings={settings => {
          setTableSettings(settings);
          return Promise.resolve();
        }}
        selectedIds={selectedIds}
        onSelectionChange={setSelectedIds}
        getRowDescriptor={(_, index) => {
          return {
            disabled: false,
            classNames: selectedRowIndex === index ? ['bg-custom-base-selection'] : [],
          };
        }}
        onRowClick={(item, index) => {
          setSelectedRowIndex(index);
          if (onRowClick) onRowClick(item, index);
        }}
      />

      <CRMTable.FiltersSidebar
        filters={sidebarFilters}
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onConfirm={async newFiltersState => {
          setSelectedFilters(prev => ({ ...prev, ...newFiltersState }));
          setIsFiltersOpen(false);
          setIsUpdateConfig(true);
        }}
      />

      {/* сайдбар настроек таблицы */}
      <CRMTable.SettingsSideBar
        open={isTableSettingsOpen}
        tableSettings={tableSettings}
        resetSettings={initialSettings}
        onClose={() => setIsTableSettingsOpen(false)}
        onConfirm={dragTableSettings => {
          setIsTableSettingsOpen(false);
          setTableSettings(dragTableSettings);
          setIsUpdateConfig(true);
        }}
      />

      {/* <CRMTable.BottomPanel
        open={!!selectedIds.length}
        selectedCount={selectedIds.length}
        onClose={() => setSelectedIds([])}
        onClickExport={() => {}}
        onClickUpdate={() => {}}
        buttonsSlot={
          <>
            {selectedIds.length === 1 && (
              <Button
                size="m"
                view="flat-danger"
                onClick={() => {
                  const index = Number(selectedIds[0]);

                  if (!isNaN(index)) {
                    onClickDelete(index);
                    setSelectedIds([]);
                  }
                }}
              >
                <Icon data={TrashBin} />
                Удалить
              </Button>
            )}
          </>
        }
      /> */}
    </CRMTable>
  );
};
