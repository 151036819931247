import { BreadcrumbsLinkItem, RadioButton, RadioButtonOption, Text } from '@gravity-ui/uikit';
import { useState } from 'react';

import { CalendarCardProps } from '@entities/calendar-card';
import { InfiniteCalendar } from '@features';
import { URLS } from '@shared/consts';
import { CRMBreadCrumbs } from '@shared/ui';

export function CRMCalendar() {
  const breadCrumbsItems: BreadcrumbsLinkItem[] = [
    {
      href: '',
      text: 'Личный кабинет',
    },
    {
      href: '',
      text: 'Профиль',
    },
    {
      href: URLS.calendar, // TODO: возможно исправится путь календаря
      text: 'Календарь',
    },
  ];
  const radioButtonOptions: RadioButtonOption<CalendarCardProps['entityType']>[] = [
    { value: '', content: 'Все' },
    { value: 'КП', content: 'КП' },
    { value: 'Торги', content: 'Торги' },
    { value: 'Контракты', content: 'Контракты' },
  ];

  const [entityType, setEntityType] = useState<CalendarCardProps['entityType']>('');

  return (
    <>
      <CRMBreadCrumbs items={breadCrumbsItems} />
      <div className="flex justify-between pt-8 px-5 pb-6">
        <Text variant="header-1">Календарь</Text>
        <RadioButton
          size="l"
          options={radioButtonOptions}
          onUpdate={option => {
            setEntityType(option);
          }}
        />
      </div>
      <InfiniteCalendar
        entityType={entityType}
        selectedStatus={undefined}
      />
    </>
  );
}
