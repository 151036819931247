import { useToaster } from '@gravity-ui/uikit';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

import { getIsSubmissionDeadlineExpired, tenderFormAtom, TenderSchema } from '@features';
import { RightSidebar } from '@shared/ui';

import { useWatchUniqueNumber } from '../lib';
import { TenderLayout } from './TenderLayout';

interface EditTenderProps {
  id?: number;
  open: boolean;
  onClose: () => void;
}

const tender_MOCK: TenderSchema = {
  Согласовано: false,
  'На согласовании': false,
  'Срок поставки': '14 дней',
  'Адрес поставки': 'г. Саратов',
  'Регион поставки': '1',
  Протоколы: [],
  Разъяснения: [],
  Документация: [],
  'Подведение итогов': '2024-09-09T00:00:00.000Z',
  'Проведение процедуры': '2024-09-09T00:00:00.000Z',
  'Окончание подачи': '2024-09-09T00:00:00.000Z',
  'Обеспечение заявки': 15000,
  'Ограничения, преимущества и доп требования': '1',
  'Способ проведения': 'Конкурс',
  'Участвуем от': '1',
  'Начальная цена': 2000,
  'Объект торгов': '1',
  Заказчики: [],
  ФЗ: '1',
  'Номер извещения': '4',
  'Статус закупки': 'В процессе',
  'Статус заявки': 'Подача',
  Наименование: '',
  Ответственный: '2',
};

export function EditTender({ id, open, onClose }: EditTenderProps) {
  console.log('tender-id', id);

  const { add } = useToaster();

  const [tender, setTender] = useState<TenderSchema>(tender_MOCK);
  const tenderForm = useAtomValue(tenderFormAtom);

  const [uniqueNumber, setUniqueNumber] = useState<string>();
  useWatchUniqueNumber(uniqueNumber);

  return (
    <TenderLayout
      formProps={{
        values: tender,
        defaultValues: tender,
        disabled:
          tender['На согласовании'] ||
          tender['Статус заявки'] === 'Не подано' ||
          (tender['Статус заявки'] === 'Отклонение' &&
            (tender['Статус закупки'] === 'Состоялась' ||
              tender['Статус закупки'] === 'Не состоялась')) ||
          (tender['Статус заявки'] === 'Отказ' && tender['Согласовано']) ||
          tender['Статус заявки'] === 'Выигран' ||
          tender['Статус заявки'] === 'Проигран' ||
          tender['Статус закупки'] === 'Отменена' ||
          tender['Статус закупки'] === 'Не известно',
        initialEditable: false,
        onValuesChange: values => {
          setUniqueNumber(values['Номер извещения']);

          if (values['Статус заявки'] === 'Выигран' || values['Статус заявки'] === 'Проигран') {
            tenderForm?.setValue('Статус закупки', 'Состоялась');
          }
        },
        allowedApplicationStatuses: values => {
          if (tender['Статус заявки'] === 'Подача') {
            if (!tender['Согласовано']) {
              return ['Подача'];
            }

            return ['Подача', 'Ожидание'];
          }

          if (getIsSubmissionDeadlineExpired(values['Окончание подачи'])) {
            return ['Ожидание', 'Выигран', 'Проигран', 'Отклонение', 'Не подано'];
          }

          return ['Ожидание', 'Выигран', 'Проигран', 'Отклонение', 'Отказ'];
        },
        allowedProcurementStatuses: values => {
          switch (values['Статус заявки']) {
            case 'Подача': {
              return ['В процессе', 'Отменена', 'Не известно'];
            }
            case 'Ожидание': {
              return ['В процессе', 'Отменена', 'Не известно'];
            }
            case 'Не подано': {
              return ['В процессе', 'Состоялась', 'Не состоялась', 'Отменена', 'Не известно'];
            }
            case 'Отказ': {
              return ['В процессе', 'Состоялась', 'Не состоялась', 'Отменена', 'Не известно'];
            }
            case 'Выигран': {
              return ['Состоялась'];
            }
            case 'Отклонение': {
              return ['Состоялась', 'Не состоялась', 'Отменена', 'Не известно'];
            }
            case 'Проигран': {
              return ['Состоялась'];
            }
          }
        },
        onValid: values => {
          console.log('@values', values);

          if (values['Статус заявки'] === 'Отклонение') {
            if (
              values['Статус закупки'] === 'Не состоялась' ||
              values['Статус закупки'] === 'Состоялась'
            ) {
              add({
                name: 'tender-reject',
                theme: 'success',
                title: 'Отправлено уведомление руководителю',
              });
            }
          }

          if (values['Статус закупки'] === 'Отменена') {
            add({
              name: 'tender-cancel',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
          }

          if (values['Статус заявки'] === 'Выигран') {
            add({
              name: 'tender-win',
              theme: 'success',
              title: 'Отправлено уведомление руководителю, создана карточка Контрактов',
            });
          }

          if (values['Статус заявки'] === 'Проигран') {
            add({
              name: 'tender-lose',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
          }

          if (values['Статус заявки'] === 'Не подано') {
            add({
              name: 'tender-lose',
              theme: 'success',
              title: 'Отправлено уведомление руководителю',
            });
          }

          if (values['Статус заявки'] === 'Ожидание') {
            return setTender({ ...values, Согласовано: false });
          }

          setTender(values);
        },
        onInvalid: errors => console.log('@errors', errors),
        approvalButtonProps: {
          onClick: () =>
            tenderForm?.handleSubmit(values => {
              setTender({ ...values, 'На согласовании': true });
              add({
                theme: 'success',
                name: 'tender-under-approval',
                title: 'Отправлено на согласование',
              });

              setTimeout(() => {
                // делаем вид что с бэка пришел новый tender
                setTender({
                  ...values,
                  'На согласовании': false,
                  Согласовано: true,
                });
                add({
                  theme: 'success',
                  name: 'tender-approved',
                  title: 'Согласовано',
                });
              }, 3000);
            })(),
        },
      }}
      renderForm={formComponent => (
        <RightSidebar
          open={open}
          onClose={onClose}
        >
          <RightSidebar.Header
            title="Редактирование торгов"
            onClose={onClose}
          />
          {formComponent}
        </RightSidebar>
      )}
    />
  );
}
