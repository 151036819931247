import { Paperclip } from '@gravity-ui/icons';
import { ButtonProps, Icon, TextInput, TextInputProps } from '@gravity-ui/uikit';

import { AttachFileButton, SendMessageButton } from '@features';

// TODO: Пропсы
export function ChatBottomPanel({
  sendButtonProps,
  textInputProps,
}: {
  sendButtonProps: ButtonProps;
  textInputProps: TextInputProps;
}) {
  return (
    <div className="bg-base-background border-t border-line-generic shadow-select p-2">
      <TextInput
        {...textInputProps}
        placeholder="Сообщение..."
        size="xl"
        endContent={
          <div className="flex gap-1">
            <AttachFileButton>
              <Icon data={Paperclip} />
            </AttachFileButton>
            <SendMessageButton {...sendButtonProps}>Отправить</SendMessageButton>
          </div>
        }
      />
    </div>
  );
}
