import { LabelProps } from '@gravity-ui/uikit';

import { CommercialOfferStatuses } from '../model/types/statuses';

export const convertCommercialOfferStatusToLabelTheme = (
  status: CommercialOfferStatuses
): LabelProps['theme'] => {
  switch (status) {
    case 'Подача':
    case 'Ожидание':
      return 'normal';
    case 'Торги опубликованы':
      return 'info';
    case 'Выигран':
      return 'success';
    case 'Проигран':
    case 'Отказ':
      return 'danger';
    case 'Отмена':
      return 'utility';
    case 'Не подано':
    case 'Торги не отработаны':
      return 'clear';
  }
};
