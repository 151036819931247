import { Button } from '@gravity-ui/uikit';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { TenderDrawer } from '@widgets';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';
import { HeroWithButton } from '@shared/ui';

export function Tenders() {
  const { navigateTo } = useNavigateTo();

  const [createTenderOpen, setCreateTenderOpen] = useState(false);

  const tenderId = useParams()[URLS.processes.tendersDynamicParams.id];
  const editTenderOpen = !!tenderId && !isNaN(Number(tenderId));

  if (tenderId && isNaN(Number(tenderId))) {
    return <Navigate to={URLS.processes.tenders} />;
  }

  return (
    <>
      <HeroWithButton
        title="Торги"
        buttonOnClick={() => setCreateTenderOpen(true)}
      />
      <Button onClick={() => navigateTo(`${URLS.processes.tenders}/0`)}>
        Открыть карточку Торгов
      </Button>
      <TenderDrawer
        type="create"
        open={createTenderOpen}
        onClose={() => setCreateTenderOpen(false)}
      />
      <TenderDrawer
        id={tenderId ? Number(tenderId) : undefined}
        type="edit"
        open={editTenderOpen}
        onClose={() => navigateTo(URLS.processes.tenders)}
      />
    </>
  );
}
