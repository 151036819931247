/* eslint-disable @typescript-eslint/no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  $mock,
  bodyRequestType,
  fetchClient,
  mockClient,
  ParametersPathType,
  ParametersQueryType,
} from '@shared/api';

function useGetHandbookPurchaseObjectsInfiniteQuery(
  query: ParametersQueryType<'get', '/handbook/purchase-objects'>,
  enabled: boolean
) {
  return useInfiniteQuery({
    queryKey: ['/handbook/purchase-objects', query],
    queryFn: async ({ pageParam }) => {
      // const { data } = await fetchClient.GET('/handbook/purchase-objects', {
      const { data } = await mockClient.GET('/handbook/purchase-objects', {
        params: { query: { ...query, page: pageParam } },
      });

      return data?.list || [];
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled,
  });
}

const createHandbookPurchaseObjectsMutation = () =>
  $mock.useMutation('post', '/handbook/purchase-objects');
// const createHandbookPurchaseObjects = (
//   body: bodyRequestType<'post', '/handbook/purchase-objects'>
// ) => createHandbookPurchaseObjectsMutation.mutateAsync({ body: body });

const deleteByIdHandbookPurchaseObjects = (
  id: ParametersPathType<'delete', '/handbook/purchase-objects/{id}'>['id']
) =>
  $mock
    .useMutation('delete', '/handbook/purchase-objects/{id}')
    .mutateAsync({ params: { path: { id: id } } });

const useGetByIdHandbookPurchaseObjects = (
  id: ParametersPathType<'get', '/handbook/purchase-objects/{id}'>['id']
) => $mock.useQuery('get', '/handbook/purchase-objects/{id}', { params: { path: { id: id } } });

const updateHandbookPurchaseObjectsMutation = () =>
  $mock.useMutation(
    'put',
    //TODO: ошибка в схеме бэка
    '/handbook/purchase-objects/{id}'
  );
// const updateHandbookPurchaseObjects = (
//   id: ParametersPathType<'put', '/handbook/purchase-objects/{id}'>['id'],
//   body: bodyRequestType<'put', '/handbook/purchase-objects/{id}'>
// ) =>
//   updateHandbookPurchaseObjectsMutation.mutateAsync({ body: body, params: { path: { id: id } } });

export const $purchaseObjectsHooks = {
  useGetAll: useGetHandbookPurchaseObjectsInfiniteQuery,
  useGetById: useGetByIdHandbookPurchaseObjects,
  createMutation: createHandbookPurchaseObjectsMutation,
  updateByIdMutation: updateHandbookPurchaseObjectsMutation,
  deleteById: deleteByIdHandbookPurchaseObjects,
};
