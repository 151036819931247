import { atom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';

export const hasAuthPinCode = atomWithStorage('hasPinCode', false, undefined, { getOnInit: true });
export const emailAuthAtom = atomWithStorage('emailAuth', '', undefined, { getOnInit: true });
export const pinCodeAuthAtom = atomWithStorage<string>('pinCodeAuth', '', undefined, {
  getOnInit: true,
});

export const resetAuthCredentials = atom(null, (_, set) => {
  set(hasAuthPinCode, RESET);
  set(emailAuthAtom, RESET);
  set(pinCodeAuthAtom, RESET);
});
