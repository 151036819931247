import { dateTime } from '@gravity-ui/date-utils';

import { CRMTableData } from '@features/table';
import { bodyResponseType } from '@shared/api';
import { DATE_FORMATS } from '@shared/consts';
import { formatToPrice } from '@shared/lib';

import { ColumnsCommercialOffersTable } from '../model';

const parseDate = (date: string, withTime?: boolean) =>
  dateTime({ input: date }).format(
    !withTime ? DATE_FORMATS.fullDate : DATE_FORMATS.fullDateWithTimeRounded
  );

export const parseCommercialOfferTableData = (
  tableData: bodyResponseType<'post', '/offer/all'>['offers']
): CRMTableData<ColumnsCommercialOffersTable> => {
  return tableData.map(offer => {
    return {
      'Дата, номер': offer.created_at ? `${parseDate(offer.created_at)} №${offer.id}` : '',
      Наименование: offer.title || '',
      Ответственный: offer.responsible_value || '',
      Заказчик: offer.customer_value || '',
      'Цель КП': offer.purpose || '',
      'Статус КП': offer.status || '',
      'Дата перевода': offer.transfer_at ? parseDate(offer.transfer_at) : '',
      'Регион поставки': offer.delivery_region_value || '',
      'Адрес поставки': offer.delivery_address || '',
      'Срок поставки': offer.delivery_deadline || '',
      Основание: offer.basis_purchase || '',
      'Как получен запрос': offer.request_source || '',
      'Ссылка в ЕИС': offer.eis_link || '',
      'Окончание подачи': offer.submission_deadline
        ? parseDate(offer.submission_deadline, true)
        : '',
      'Срок проведения закупки с': offer.producement_start_at
        ? parseDate(offer.producement_start_at)
        : '',
      'Срок проведения закупки по': offer.producement_end_at
        ? parseDate(offer.producement_end_at)
        : '',
      'Документы запроса': offer.documents?.join(', ') || '',
      'Объект закупки': offer.purchase_object_value || '',
      Комментарий: offer.comment || '',
      'Файл расчета': offer.calculation_file || '',
      Себестоимость: offer.cost_price ? formatToPrice(offer.cost_price, true) : '',
      'Цена предложения': offer.offer_price ? formatToPrice(offer.offer_price, true) : '',
      'Маржа, %': offer.margin ? `${offer.margin} %` : '',
      'Файл КП': offer.offer_file || '',
      'Дата КП': offer.document_date ? parseDate(offer.document_date) : '',
      Поставщик: offer.provider_value || '',
      'Согласовать цену с руководителем': offer.manager_approved ? 'СОГЛАСОВАТЬ' : 'НЕ СОГЛАСОВАТЬ',
      'Предлагаемая специалистом цена': offer.proposed_price
        ? formatToPrice(offer.proposed_price, true)
        : '',
      'Обоснование предложения цены': offer.price_justification || '',
      'Комментарий согласований': offer.approval_comment || '',
      'Итоговая цена': offer.final_price ? formatToPrice(offer.final_price, true) : '',
      Победитель: offer.winner_value || '',
    };
  });
};
