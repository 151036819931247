import { useToaster } from '@gravity-ui/uikit';
import { useAtom, useAtomValue } from 'jotai';

import { AuthForm, AuthFormProps } from '@features/auth-form';
import { URLS } from '@shared/consts';
import { useNavigateTo } from '@shared/lib';

import { usePostAuthSendResetMutation, usePostAuthValidationCodeMutation } from '../api';
import { confirmPincodeSchema, emailAuthAtom, hasAuthPinCode, pinCodeAuthAtom } from '../model';

export const ConfirmPincode = () => {
  const { navigateTo } = useNavigateTo();
  const emailValue = useAtomValue(emailAuthAtom);
  const [, setPinCode] = useAtom(pinCodeAuthAtom);
  const [, setHasPincode] = useAtom(hasAuthPinCode);

  const postAuthValidationCodeMutation = usePostAuthValidationCodeMutation();
  const postAuthSendResetMutation = usePostAuthSendResetMutation();
  const { add } = useToaster();

  const onSubmit = async (code: string) => {
    try {
      await postAuthValidationCodeMutation.mutateAsync({ body: { code, email: emailValue } });
      setHasPincode(true);
      setPinCode(code);
      navigateTo(URLS.auth.newPassword);
    } catch {
      add({
        name: 'invalid-pincode',
        title: 'Некорректный пин-код',
        theme: 'danger',
      });
    }
  };

  const confirmPincodeProps: AuthFormProps<typeof confirmPincodeSchema> = {
    schema: confirmPincodeSchema,
    defaultValues: {
      pin: [],
    },
    onValid({ pin: pinArray }) {
      const code = pinArray.join('');
      onSubmit(code);
    },
    title: `Введите пин-код из письма, отправленный на почту ${emailValue}`,
    inputs: {
      pin: {
        type: 'pinInput',
        props: {
          placeholder: '0',
          onUpdateComplete(pinArray) {
            const code = pinArray.join('');
            onSubmit(code);
          },
          disabled: postAuthValidationCodeMutation.isPending,
        },
      },
    },
    buttons: {
      wrapperClassName: 'flex flex-col-reverse',
      primaryButtonProps: {
        children: 'Сбросить пароль',
        loading: postAuthValidationCodeMutation.isPending,
      },
      secondButtonProps: {
        children: 'Отправить повторно',
        view: 'normal',
        onClick: () => {
          postAuthSendResetMutation.mutate({ body: { email: emailValue } });
        },
        loading: postAuthSendResetMutation.isPending,
      },
    },
  };

  return <AuthForm {...confirmPincodeProps} />;
};
