import { yupRu } from '@shared/config';
import { emailRegex, passwordRegex } from '@shared/lib';

export const loginSchema = yupRu.object({
  email: yupRu
    .string()
    .matches(emailRegex, {
      message: 'Некорректный e-mail',
      excludeEmptyString: true,
    })
    .required('Введите e-mail'),
  password: yupRu
    .string()
    .matches(passwordRegex, {
      message:
        'Не менее 6 символов, использование заглавных и строчных букв латинского алфавита, символов и чисел',
      excludeEmptyString: true,
    })
    .required('Введите пароль'),
});
